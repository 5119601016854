var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "login_page" } }, [
    _c("div", { staticClass: "d-flex justify-content-center" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.login_page_get_pin,
              expression: "!login_page_get_pin"
            }
          ]
        },
        [
          _c("img", {
            attrs: {
              id: "splash_screen_logo",
              src: "assets/img/sidebar_logo.png",
              width: "316.31px",
              height: "70px"
            },
            on: {
              click: function($event) {
                return _vm.$root.showToast(
                  _vm.$root.device_information.name,
                  5000
                )
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { id: "login_box" } },
            _vm._l(_vm.$root.getUserListForLogin(), function(user) {
              return _c(
                "div",
                {
                  staticClass: "user_box",
                  on: {
                    click: function($event) {
                      return _vm.show_login_pin_screen(user.id)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "user_box_icon" }, [
                    _vm._v(_vm._s(user.name[0]))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "user_box_name" }, [
                    _vm._v(_vm._s(user.name))
                  ])
                ]
              )
            }),
            0
          )
        ]
      ),
      _vm._v(" "),
      _vm.login_page_get_pin
        ? _c("div", [
            _vm.login_loading
              ? _c(
                  "div",
                  [
                    _c("center", [
                      _c("br"),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "spinner-border text-light",
                          staticStyle: { width: "3rem", height: "3rem" },
                          attrs: { role: "status" }
                        },
                        [
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Loading...")
                          ])
                        ]
                      )
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.current_pincode_sections.length < 4
              ? _c("div", [
                  _c(
                    "div",
                    { attrs: { id: "pincode_position_box" } },
                    _vm._l([0, 1, 2, 3], function(number) {
                      return _c("div", {
                        staticClass: "pincode_position_item",
                        class: {
                          active:
                            _vm.current_pincode_sections[number] !== undefined
                        }
                      })
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "login_pincode_box",
                      attrs: { id: "login_pinbox" }
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { width: "270px" } },
                        [
                          _vm._l([1, 2, 3, 4, 5, 6, 7, 8, 9, 0], function(
                            number
                          ) {
                            return _c(
                              "div",
                              {
                                staticClass: "pincode_item",
                                on: {
                                  click: function($event) {
                                    return _vm.addToPin(number)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(number) +
                                    "\n                        "
                                )
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "pincode_item remove_pin_btn",
                              on: {
                                click: function($event) {
                                  return _vm.current_pincode_sections.pop()
                                }
                              }
                            },
                            [_vm._v("<")]
                          )
                        ],
                        2
                      )
                    ]
                  )
                ])
              : _vm._e()
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }