

<template>
    
</template>

<script>
import { required, maxLength, numeric, minValue } from "vuelidate/lib/validators";

export default {
    props: ['description', 'export_order'],
    watch: {
        description: function(newVal, oldVal) {
            this.$v.description.$touch()
            this.update()
        },
        export_order: function(newVal, oldVal) {
            this.$v.export_order.$touch()
            this.update()
        }
    },
    validations: {
        description: {
            required,
            maxLength: maxLength(255)
        },
        export_order: {
            required,
            numeric,
            minValue: minValue(1)
        },
    },
    mounted: function () {
        this.$v.description.$touch()
        this.$v.export_order.$touch()
        this.update()
    },
    methods: {
        update() {
            this.$emit('update', this.$v)
        }
    },

}
</script>
