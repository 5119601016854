var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.$root.last_pce_save_successful
      ? _c("div", { staticClass: "pce_card" }, [
          _c("div", { staticClass: "pce_card_content" }, [
            Object.keys(_vm.format_pce_merge_errors).length >= 1
              ? _c(
                  "div",
                  [
                    _vm._l(_vm.format_pce_merge_errors, function(
                      merge_issue_details,
                      attribute_with_merge_issue
                    ) {
                      return _c(
                        "div",
                        {
                          key: attribute_with_merge_issue,
                          staticClass: "sign_off_confirmation"
                        },
                        [
                          _vm._v(
                            "\n\n                    " +
                              _vm._s(
                                _vm.setMergeChoice(
                                  attribute_with_merge_issue,
                                  merge_issue_details["proposed_new_value"]
                                )
                              ) +
                              "\n\n                "
                          )
                        ]
                      )
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.save_merge_issues()) +
                        "\n            "
                    )
                  ],
                  2
                )
              : _c(
                  "div",
                  [
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c("center", [
                      _c(
                        "div",
                        {
                          staticClass: "btn btn-primary",
                          class: {
                            disabled: !_vm.is_sign_off_confirmation_form_valid
                          },
                          staticStyle: { width: "200px" },
                          on: {
                            click: function($event) {
                              return _vm.sign_off()
                            }
                          }
                        },
                        [_vm._v("SIGN OFF PCE")]
                      )
                    ])
                  ],
                  1
                )
          ])
        ])
      : _c(
          "div",
          [
            _c("center", [
              _c(
                "div",
                {
                  staticClass: "btn btn-primary",
                  class: { disabled: !_vm.is_sign_off_confirmation_form_valid },
                  staticStyle: { width: "200px" },
                  on: {
                    click: function($event) {
                      return _vm.sign_off()
                    }
                  }
                },
                [_vm._v("SIGN OFF PCE")]
              )
            ])
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }