var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pce_card" },
    [
      _c("div", { staticClass: "pce_card_content" }, [
        _c("div", { staticClass: "section_heading" }, [
          _vm._v("Assignment details")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [
            _c("label", { staticClass: "data_label" }, [
              _vm._v("SC Ref Number")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "data" }, [
              _vm._v("SC-" + _vm._s(_vm.value.id))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-4" }, [
            _c("label", { staticClass: "data_label" }, [_vm._v("Hub")]),
            _vm._v(" "),
            _c("div", { staticClass: "data" }, [_vm._v(_vm._s(_vm.value.hub))])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-4" }, [
            _c("label", { staticClass: "data_label" }, [
              _vm._v("Transfer Type")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "data" }, [_vm._v(_vm._s(_vm.value.type))])
          ])
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [
            _c("label", { staticClass: "data_label" }, [
              _vm._v("Vehicle reg assigned")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "data" },
              _vm._l(_vm.$root.currentCVA.vehicles, function(
                vehicle,
                vehicle_array_index
              ) {
                return _c("span", [
                  _vm._v(
                    "\n                        " + _vm._s(vehicle.name) + " "
                  ),
                  vehicle_array_index !==
                  _vm.$root.currentCVA.vehicles.length - 1
                    ? _c("span", [_vm._v(", ")])
                    : _vm._e()
                ])
              }),
              0
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-4" }, [
            _c("label", { staticClass: "data_label" }, [
              _vm._v("Crew assigned")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "data" },
              _vm._l(_vm.$root.currentCVA.crew_members, function(
                crew_member,
                crew_member_id,
                crew_members_array_index
              ) {
                return _c("span", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        crew_member
                          .split(" ")
                          .map(function(x) {
                            return x.charAt(0)
                          })
                          .join(".")
                          .toUpperCase()
                      ) +
                      " "
                  ),
                  crew_members_array_index !==
                  Object.keys(_vm.$root.currentCVA.crew_members).length - 1
                    ? _c("span", [_vm._v(", ")])
                    : _vm._e()
                ])
              }),
              0
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-4" }, [
            _c("label", { staticClass: "data_label" }, [
              _vm._v("RMN assigned")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "data" },
              _vm._l(_vm.value.rmns, function(rmn_name, rmn_name_array_index) {
                return _c("span", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        rmn_name
                          .split(" ")
                          .map(function(x) {
                            return x.charAt(0)
                          })
                          .join(".")
                          .toUpperCase()
                      ) +
                      " "
                  ),
                  rmn_name_array_index !== _vm.value.rmns.length - 1
                    ? _c("span", [_vm._v(", ")])
                    : _vm._e()
                ])
              }),
              0
            )
          ])
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [
            _c("label", { staticClass: "data_label" }, [
              _vm._v("Shadow assigned")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "data" },
              _vm._l(_vm.value.shadows, function(
                shadow_name,
                shadow_name_array_index
              ) {
                return _c("span", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        shadow_name
                          .split(" ")
                          .map(function(x) {
                            return x.charAt(0)
                          })
                          .join(".")
                          .toUpperCase()
                      ) +
                      " "
                  ),
                  shadow_name_array_index !== _vm.value.shadows.length - 1
                    ? _c("span", [_vm._v(", ")])
                    : _vm._e()
                ])
              }),
              0
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "hl" }),
      _vm._v(" "),
      _c("div", { staticClass: "pce_card_content" }, [
        _c("div", { staticClass: "section_heading" }, [
          _vm._v("Booking Details")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [
            _c("label", { staticClass: "data_label" }, [_vm._v("Booker Name")]),
            _vm._v(" "),
            _c("div", { staticClass: "data" }, [
              _vm._v(_vm._s(_vm.value.booker_information.booker_name))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-4" }, [
            _c("label", { staticClass: "data_label" }, [
              _vm._v("Booker Contact Number")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "data" }, [
              _vm._v(
                _vm._s(_vm.value.booker_information.booker_contact_number) +
                  " " +
                  _vm._s(
                    _vm.value.booker_information.booker_contact_number_notes
                  )
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-4" }, [
            _c("label", { staticClass: "data_label" }, [
              _vm._v("Approver name")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "data" }, [
              _vm._v(_vm._s(_vm.value.booker_information.approver_name))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [
            _c("label", { staticClass: "data_label" }, [_vm._v("Trust")]),
            _vm._v(" "),
            _c("div", { staticClass: "data" }, [
              _vm._v(_vm._s(_vm.value.booker_information.trust))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-4" }, [
            _c("label", { staticClass: "data_label" }, [
              _vm._v("Area funding type")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "data" }, [
              _vm._v(_vm._s(_vm.value.booker_information.area_funding))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-4" }, [
            _c("label", { staticClass: "data_label" }, [
              _vm._v("Area funding")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "data" }, [
              _vm._v(
                _vm._s(_vm.value.booker_information.area_funding_type_name)
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [
            _c("label", { staticClass: "data_label" }, [
              _vm._v("Budget Code for invoicing")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "data" }, [
              _vm._v(
                _vm._s(_vm.value.booker_information.budget_code_for_invoicing)
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-4" }, [
            _c("label", { staticClass: "data_label" }, [
              _vm._v("Purchase order number")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "data" }, [
              _vm._v(_vm._s(_vm.value.booker_information.purchase_order_number))
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "hl" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pce_card_content" },
        [
          _c("div", { staticClass: "section_heading" }, [
            _vm._v("Job Details")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4" }, [
              _c("label", { staticClass: "data_label" }, [
                _vm._v("Scheduled date of transfer")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "data" }, [
                _vm._v(_vm._s(_vm.value.collection_timestamp.split(" ")[0]))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-4" }, [
              _c("label", { staticClass: "data_label" }, [
                _vm._v("Collection time")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "data" }, [
                _vm._v(_vm._s(_vm.value.collection_timestamp.split(" ")[1]))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-4" }, [
              _c("label", { staticClass: "data_label" }, [
                _vm._v("Vehicle requirement")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "data" }, [
                _vm._v(_vm._s(_vm.value.vehicle_requirement))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4" }, [
              _c("label", { staticClass: "data_label" }, [
                _vm._v("Driver Plus")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "data" }, [
                _vm._v("D+" + _vm._s(_vm.value.driver_plus))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _vm._l(_vm.journey_steps, function(journey_step) {
            return _c("div", [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4" }, [
                  _c("label", { staticClass: "data_label" }, [
                    _vm._v(_vm._s(journey_step["description"]) + " Address")
                  ]),
                  _vm._v(" "),
                  journey_step["address"] !== null &&
                  journey_step["address"] !== undefined
                    ? _c("div", { staticClass: "data" }, [
                        journey_step["address"]["ward"] !== null
                          ? _c("span", [
                              _vm._v(
                                _vm._s(journey_step["address"]["ward"]) + ","
                              ),
                              _c("br")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        journey_step["address"]["hospital_name"] !== null
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  journey_step["address"]["hospital_name"]
                                ) + ","
                              ),
                              _c("br")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        journey_step["address"]["address_line_1"] !== null
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  journey_step["address"]["address_line_1"]
                                ) + ","
                              ),
                              _c("br")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        journey_step["address"]["address_line_2"] !== null
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  journey_step["address"]["address_line_2"]
                                ) + ","
                              ),
                              _c("br")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        journey_step["address"]["address_line_3"] !== null
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  journey_step["address"]["address_line_3"]
                                ) + ","
                              ),
                              _c("br")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        journey_step["address"]["address_line_4"] !== null
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  journey_step["address"]["address_line_4"]
                                ) + ","
                              ),
                              _c("br")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        journey_step["address"]["city"] !== null
                          ? _c("span", [
                              _vm._v(
                                _vm._s(journey_step["address"]["city"]) + ","
                              ),
                              _c("br")
                            ])
                          : _vm._e()
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-4" }, [
                  _c("label", { staticClass: "data_label" }, [
                    _vm._v(_vm._s(journey_step["description"]) + " Postcode")
                  ]),
                  _vm._v(" "),
                  journey_step["address"] !== null &&
                  journey_step["address"] !== undefined
                    ? _c("div", { staticClass: "data" }, [
                        _vm._v(_vm._s(journey_step["address"]["postcode"]))
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-4" }, [
                  _c("label", { staticClass: "data_label" }, [
                    _vm._v(
                      _vm._s(journey_step["description"]) + " Contact Number"
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "data" }, [
                    _vm._v(_vm._s(journey_step["contact_number"]) + " "),
                    journey_step["contact_number_notes"] !== null
                      ? _c("span", [
                          _vm._v(
                            "- (" +
                              _vm._s(journey_step["contact_number_notes"]) +
                              ")"
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("br")
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "hl" }),
      _vm._v(" "),
      _c("div", { staticClass: "pce_card_content" }, [
        _c("div", { staticClass: "section_heading" }, [
          _vm._v("Patient Details")
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [
            _c("label", { staticClass: "data_label" }, [
              _vm._v("Patient Name")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "data" }, [
              _vm._v(_vm._s(_vm.value.patient_name))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-4" }, [
            _c("label", { staticClass: "data_label" }, [
              _vm._v("Male / female")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "data" }, [
              _vm._v(_vm._s(_vm.value.patient_gender))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-4" }, [
            _c("label", { staticClass: "data_label" }, [_vm._v("Pronoun")]),
            _vm._v(" "),
            _c("div", { staticClass: "data" }, [
              _vm._v(_vm._s(_vm.value.patient_pronouns))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [
            _c("label", { staticClass: "data_label" }, [
              _vm._v("Age on collection")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "data" }, [
              _vm._v(_vm._s(_vm.$root.patient_age_on_collection_date))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-4" }, [
            _c("label", { staticClass: "data_label" }, [_vm._v("GP Code")]),
            _vm._v(" "),
            _c("div", { staticClass: "data" }, [
              _vm._v(_vm._s(_vm.value.patient_gp_code))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-4" }, [
            _c("label", { staticClass: "data_label" }, [
              _vm._v("NHS No. or X No.")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "data" }, [
              _vm._v(_vm._s(_vm.value.patient_nhs_no))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [
            _c("label", { staticClass: "data_label" }, [_vm._v("Nationality")]),
            _vm._v(" "),
            _c("div", { staticClass: "data" }, [
              _vm._v(_vm._s(_vm.value.patient_nationality))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-4" }, [
            _c("label", { staticClass: "data_label" }, [
              _vm._v("Religion / Ethnicity")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "data" }, [
              _vm._v(_vm._s(_vm.value.patient_religion_ethnicity))
            ])
          ])
        ]),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("label", { staticClass: "data_label" }, [
              _vm._v("Additional Comments")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "data" }, [
              _vm._v(_vm._s(_vm.value.patient_additional_comments))
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.value.cancelled
        ? _c("div", [
            _c("div", { staticClass: "hl" }),
            _vm._v(" "),
            _c("div", { staticClass: "pce_card_content" }, [
              _c("div", { staticClass: "section_heading" }, [
                _vm._v("Cancellation Details")
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4" }, [
                  _c("label", { staticClass: "data_label" }, [
                    _vm._v("Cancellation type")
                  ]),
                  _vm._v(" "),
                  _vm.value.cancellation_type === 1
                    ? _c("div", { staticClass: "data" }, [_vm._v("Pre day")])
                    : _vm.value.cancellation_type === 2
                    ? _c("div", { staticClass: "data" }, [_vm._v("Same day")])
                    : _vm.value.cancellation_type === 3
                    ? _c("div", { staticClass: "data" }, [_vm._v("Deployed")])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-4" }, [
                  _c("label", { staticClass: "data_label" }, [
                    _vm._v("Cancellation reason")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "data" }, [
                    _vm._v(_vm._s(_vm.value.cancellation_reason))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-4" }, [
                  _c("label", { staticClass: "data_label" }, [
                    _vm._v("Cancelled by whom")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "data" }, [
                    _vm._v(_vm._s(_vm.value.cancelled_by))
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4" }, [
                  _c("label", { staticClass: "data_label" }, [
                    _vm._v("Cancellation notes (comments)")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "data" }, [
                    _vm._v(_vm._s(_vm.value.cancellation_notes))
                  ])
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _c("br"),
      _vm._v(" "),
      _c("center", [
        _vm.value.cancelled === 1
          ? _c(
              "div",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function($event) {
                    _vm.value.crew_acknowledged_cancellation_at = _vm.$root.date_to_time_string(
                      new Date()
                    )
                    _vm.$root.save_and_close_pce()
                  }
                }
              },
              [_vm._v("Accept Cancellation")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.value.crew_accepted_transfer_at === null &&
        _vm.value.cancelled !== 1
          ? _c(
              "div",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function($event) {
                    _vm.value.crew_accepted_transfer_at = _vm.$root.date_to_time_string(
                      new Date()
                    )
                    _vm.$root.queue_save_current_pce()
                  }
                }
              },
              [_vm._v("Accept Patient Care Episode")]
            )
          : _vm.value.started_at === null
          ? _c(
              "div",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function($event) {
                    return _vm.startPceJourney()
                  }
                }
              },
              [_vm._v("Start Patient Care Episode")]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("br"),
      _c("br")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }