

<template>
    <div>
        <div @click="openAddressSearch">
            <input type="text" :value="selected_address_text" disabled class="form-control address_search_input" :class="{'address_search_input_active':address_search_active}">
            <input type="hidden" :value="value" v-if="name!==null" :name="name">
        </div>

        <div class="address_search_popup_container" v-if="address_search_active">
            <div class="address_search_popup_close" @click="address_search_active = false"></div>
            <div class="address_search_popup">
                <div class="address_search_popup_title_container">
                    <h5 class="address_search_popup_title" v-if="address_search_page!=='address_edit'">Address Search</h5>
                    <h5 class="address_search_popup_title" v-if="address_search_page=='address_edit'">Add New Address</h5>
                    </div>
                <div class="btn btn-link" @click="back_to_search()" v-if="address_search_page==='address_edit'">Back to search</div>
                <br>
                <div class="address_search_popup_body">


                    <div class="row d-flex align-items-center" v-if="address_search_page!=='address_edit'">
                        <div class="col-2">
                            Postcode
                        </div>
                        <div class="col-6">
                            <input type="search" autocomplete="off" class="form-control" v-model="address_search_postcode" @focus="address_search_page='search'" @keydown.enter="searchAddresses()" >
                        </div>
                        <div class="col-4">
                            <div class="btn btn-primary" @click="searchAddresses()">SEARCH</div>
                        </div>
                    </div>

                    <div v-if="address_search_page==='search_results'">
                        <br>
                        <div v-if="!search_results_loading">
                            <div class="row d-flex align-items-center" v-if="address_search_results.length>=1">
                                <div class="col-2">
                                    Filter
                                </div>
                                <div class="col-6">
                                    <input type="search" autocomplete="off" class="form-control" v-model="address_search_filter">
                                </div>
                            </div>
                            <br>
                            <div v-if="address_search_results.length===0">No results found</div>
                            <div class="row address_search_result_row d-flex align-items-center" v-for="(address_result, address_result_index) in address_search_results" v-if="address_filter(address_result.full_address)">
                                <div class="col-10 address_search_result_text">
                                    {{address_result.full_address}}
                                </div>
                                <div class="col-2">
                                    <div class="btn btn-primary btn-sm d-flex align-items-center justify-content-center" @click="select_serp_address(address_result_index)">SELECT</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="address_search_page!=='address_edit'">
                        <br>
                        <center><div class="btn btn-link" @click="add_address()" >Add address</div></center>
                        <br>
                    </div>
                    <div v-if="address_search_page==='address_edit'">
                        <div class="row d-flex align-items-center">
                            <div class="col-4">
                                Hospital or Facility Name
                            </div>
                            <div class="col-8">
                                <input @blur="edit_address_validate()" type="search" autocomplete="off" class="form-control" v-model="edit_address_details.hospital_name">
                            </div>
                        </div><br>
                        <div class="row d-flex align-items-center">
                            <div class="col-4">
                                Ward, Wing, Suite, or Clinic
                            </div>
                            <div class="col-8">
                                <input type="search" autocomplete="off" class="form-control" v-model="edit_address_details.ward">
                            </div>
                        </div><br>
                        <div class="row d-flex align-items-center">
                            <div class="col-4">
                                Address line 1
                            </div>
                            <div class="col-8">
                                <input @blur="edit_address_validate()" type="search" autocomplete="off" class="form-control" v-model="edit_address_details.address_line_1">
                            </div>
                        </div><br>
                        <div class="row d-flex align-items-center">
                            <div class="col-4">
                                Address line 2
                            </div>
                            <div class="col-8">
                                <input @blur="edit_address_validate()" type="search" autocomplete="off" class="form-control" v-model="edit_address_details.address_line_2">
                            </div>
                        </div><br>
                        <div class="row d-flex align-items-center">
                            <div class="col-4">
                                Address line 3
                            </div>
                            <div class="col-8">
                                <input @blur="edit_address_validate()" type="search" autocomplete="off" class="form-control" v-model="edit_address_details.address_line_3">
                            </div>
                        </div><br>
                        <div class="row d-flex align-items-center">
                            <div class="col-4">
                                Address line 4
                            </div>
                            <div class="col-8">
                                <input @blur="edit_address_validate()" type="search" autocomplete="off" class="form-control" v-model="edit_address_details.address_line_4">
                            </div>
                        </div><br>
                        <div class="row d-flex align-items-center">
                            <div class="col-4">
                                Town or City
                            </div>
                            <div class="col-8">
                                <input @blur="edit_address_validate()" type="search" autocomplete="off" class="form-control" v-model="edit_address_details.city">
                            </div>
                        </div><br>
                        <div class="row d-flex align-items-center">
                            <div class="col-4">
                                Postcode
                            </div>
                            <div class="col-8">
                                <input @blur="edit_address_validate()" type="search" autocomplete="off" class="form-control" v-model="edit_address_details.postcode">
                            </div>
                        </div>
                        <div class="row d-flex align-items-center" v-if="address_edit_postcode_error">
                            <div class="col-4"></div>
                            <div class="col-8">
                                <small class="text-danger">Please enter a valid postcode</small>
                            </div>
                        </div>
                        <br>
                        <br>
                        <div class="btn btn-primary" v-if="current_address_id==='new'" @click="set_address()">SAVE NEW ADDRESS</div>
                        <div class="btn btn-primary" v-if="current_address_id!=='new'" style="width: 100px;" @click="set_address()">OK</div>
                    </div>


                </div>


            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        'value': {
            type: Number,
            default: null,
        },
        name: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            address_search_page: 'search',
            address_search_postcode: '',
            address_search_filter: 'trust',
            address_search_active: false,
            address_search_results: [],
            search_results_loading: true,
            current_address_id: null,
            selected_address_text: 'Loading...', // The address displayed in the mock input shown to the user when address_search_active = false
            address_edit_postcode_error: false,
            address_details: {
                "id": 12,
                "full_address":"Kent Trust, Lindsay Ward, Studio 66, Flat 65x\nDean Neck, Valley, Ghana, West Martinchester",
                "hospital_name":"Kent Trust",
                "ward":"Lindsay Ward",
                "address_line_1":"Studio 66",
                "address_line_2":"Flat 65x\nDean Neck",
                "address_line_3":"Valley",
                "address_line_4":"Ghana",
                "city":"West Martinchester",
                "postcode":"AB314DX"
            },
            edit_address_details: {
                "id": 12,
                "full_address":"Kent Trust, Lindsay Ward, Studio 66, Flat 65x\nDean Neck, Valley, Ghana, West Martinchester",
                "hospital_name":"Kent Trust",
                "ward":"Lindsay Ward",
                "address_line_1":"Studio 66",
                "address_line_2":"Flat 65x\nDean Neck",
                "address_line_3":"Valley",
                "address_line_4":"Ghana",
                "city":"West Martinchester",
                "postcode":"AB314DX"
            },
        }
    },
    mounted: function () {
        if (this.value==null) {
            this.selected_address_text = '';
        } else {
            this.load_address()
        }
    },
    methods: {
        load_address: function () {
            console.log('Getting address...')
            Vue.axios.get('/api/address/'+this.value).then((response) => {
                this.edit_address_details = response.data.result
                this.address_details = JSON.parse(JSON.stringify(response.data.result)); // JSON encoded and decoded to make sure these are two different objects
                this.current_address_id = response.data.result['id']
                this.address_search_page = 'address_edit';
                this.selected_address_text =  response.data.result['full_address'];
            })
        },
        select_serp_address: function (serp_index) {
            this.edit_address_details = this.address_search_results[serp_index]
            this.address_details = JSON.parse(JSON.stringify(this.address_search_results[serp_index])); // JSON encoded and decoded to make sure these are two different objects
            this.current_address_id = this.address_search_results[serp_index]['id']
            this.address_search_page = 'address_edit';
        },
        add_address: function () {
            this.edit_address_details = {
                "id": 'new',
                "full_address":"",
                "hospital_name":"",
                "ward":"",
                "address_line_1":"",
                "address_line_2":"",
                "address_line_3":"",
                "address_line_4":"",
                "city":"",
                "postcode":this.address_search_postcode
            }
            this.address_details = {
                "id": 'new_addr', // This is a different ID to edit_address_details, otherwise the system will not foruce the address to get saved
                "full_address":"",
                "hospital_name":"",
                "ward":"",
                "address_line_1":"",
                "address_line_2":"",
                "address_line_3":"",
                "address_line_4":"",
                "city":"",
                "postcode":""
            }
            this.current_address_id = 'new'
            this.address_search_page = 'address_edit'
        },
        back_to_search: function () {
            if (this.address_search_results.length>=1) {
                this.address_search_page = 'search_results';
            } else {
                this.address_search_page = 'search';
            }
        },
        set_address: function () {
            if (this.edit_address_validate(true)) {
                if (this.current_address_id === 'new') {
                    //We need to save this address
                    this.address_search_page = 'loading';
                    Vue.axios.post('/api/address', this.edit_address_details).then((response) => {
                        console.log(response.data)
                        if (response.data['success']) {
                            this.current_address_id = response.data['created_id'];
                            this.selected_address_text = response.data['address_string'];
                            this.edit_address_details['id'] = response.data['created_id'];
                            this.edit_address_details['full_address'] = response.data['address_string'];
                            this.address_details = JSON.parse(JSON.stringify(this.edit_address_details)); // JSON encoded and decoded to make sure these are two different objects
                            this.address_search_page = 'address_edit';
                            this.address_search_active = false;
                            this.$emit('input', response.data['created_id']); // Set the value
                        } else {
                            alert('Something went wrong when trying to save the address')
                        }
                        // this.address_search_results = response.data.results;
                        // this.search_results_loading = false;
                    })
                } else {
                    // Existing address selected...
                    this.selected_address_text = this.address_details['full_address'];
                    this.address_search_active = false;
                    this.$emit('input', this.current_address_id); // Set the value given to the parent and returned
                }
            }
        },
        edit_address_validate: function (show_errors = false) {
            // Check to see if there are any differences between the current address and the new one...
            if (JSON.stringify(this.address_details)===JSON.stringify(this.edit_address_details)) {
                this.current_address_id = this.address_details['id'];
            } else {
                this.current_address_id = 'new';
            }
            if (this.edit_address_details['postcode']===''||this.edit_address_details['postcode']===null) {
                if (show_errors) {
                    this.address_edit_postcode_error = true
                    return false;
                }
            } else {
                this.address_edit_postcode_error = false
            }
            return true;
        },
        openAddressSearch: function () {
            this.address_search_active = true
            if (this.value!==null) {
                this.address_search_page = 'address_edit';
            }
        },
        address_filter: function (full_address_str) {
            var lc_full_address_str = full_address_str.toLowerCase();
            var filter_by_array = this.address_search_filter.toLowerCase().split(" "); // An array of words to filter by
            var show_address = true;
            filter_by_array.forEach(function(filter_by_text) {
                if (filter_by_text!=='') {
                    if (!lc_full_address_str.includes(filter_by_text)) {
                        show_address = false
                        return false;
                    }
                }
            })
            return show_address;
        },
        searchAddresses: function () {
            this.address_search_filter = '';
            this.search_results_loading = true;
            this.address_search_results = [];
            this.address_search_page = 'search_results';
            Vue.axios.get('/api/address_search/'+this.address_search_postcode).then((response) => {
                this.address_search_results = response.data.results;
                this.search_results_loading = false;
            })
        }
    },
}
</script>
