var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.take_signature,
            expression: "take_signature"
          }
        ],
        staticClass: "take_signature_page"
      },
      [
        _c("canvas", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showExportCanvas,
              expression: "showExportCanvas"
            }
          ],
          attrs: {
            id: "sig_exportCanvas" + _vm.sig_id,
            width: "300",
            height: "183"
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            on: {
              mousemove: _vm.mouseMove,
              touchmove: _vm.mouseMove,
              mousedown: _vm.startDrawing,
              touchstart: _vm.startDrawing,
              touchleave: _vm.stopDrawing,
              mouseleave: _vm.stopDrawing,
              touchend: _vm.stopDrawing,
              mouseup: _vm.stopDrawing
            }
          },
          [
            _c("canvas", {
              staticClass: "canvas",
              attrs: {
                id: "sig_canvas" + _vm.sig_id,
                width: "window_width",
                height: _vm.window_width / 1.63934426 + "px",
                width: _vm.window_width + "px"
              }
            })
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "take_signature_page_bottom_bar" }, [
          _c(
            "div",
            {
              staticClass: "sig_page_bottom_bar_btn border-right",
              on: {
                click: function($event) {
                  _vm.reset_take_signature_box()
                  _vm.take_signature = false
                }
              }
            },
            [
              _c("div", { staticClass: "px-3" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "7.67",
                      height: "15.8",
                      viewBox: "0 0 7.67 15.8"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        id: "Path_16",
                        "data-name": "Path 16",
                        d:
                          "M13.51,3.512,12.34,2.1,5.84,10l6.507,7.9,1.163-1.412L8.167,10Z",
                        transform: "translate(-5.84 -2.1)",
                        fill: "#fff"
                      }
                    })
                  ]
                )
              ]),
              _vm._v("\n                BACK\n            ")
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "sig_page_bottom_bar_btn",
              on: {
                click: function($event) {
                  return _vm.reset_take_signature_box()
                }
              }
            },
            [
              _c("div", { staticClass: "px-3" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "15",
                      height: "15",
                      viewBox: "0 0 11.99 12"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        id: "Path_37",
                        "data-name": "Path 37",
                        d:
                          "M14.238,5.762A6,6,0,1,0,15.8,11.5h-1.56A4.5,4.5,0,1,1,10,5.5a4.435,4.435,0,0,1,3.164,1.335L10.751,9.25H16V4Z",
                        transform: "translate(-4.01 -4)",
                        fill: "#fff"
                      }
                    })
                  ]
                )
              ]),
              _vm._v("\n                RESTART\n            ")
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "sig_page_bottom_bar_btn sign",
              class: { active: _vm.started },
              on: {
                click: function($event) {
                  return _vm.save_signature()
                }
              }
            },
            [_vm._v("\n                SIGN\n            ")]
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c("label", { staticClass: "input_label" }, [_vm._v(_vm._s(_vm.label))]),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.internal_signature.name,
          expression: "internal_signature.name"
        }
      ],
      staticClass: "form-control",
      attrs: {
        type: "text",
        disabled:
          _vm.internal_signature.date_signed !== "" &&
          _vm.internal_signature.date_signed !== null
      },
      domProps: { value: _vm.internal_signature.name },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.$set(_vm.internal_signature, "name", $event.target.value)
        }
      }
    }),
    _vm._v(" "),
    _vm.no_name_error
      ? _c("small", { staticClass: "text-danger" }, [
          _vm._v("Please enter a name")
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("label", { staticClass: "input_label" }, [_vm._v("Signature")]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "signature_preview_box",
        class: { "d-flex": !_vm.show_signature },
        style: {
          width: _vm.window_width - 17 + "px",
          height: (_vm.window_width - 17) / 1.63934426 + "px"
        },
        on: {
          click: function($event) {
            return _vm.goto_take_signature_page()
          }
        }
      },
      [
        _vm.internal_signature.date_signed == null
          ? _c("div", [_vm._v("\n            TAP TO SIGN\n        ")])
          : _vm.show_signature
          ? _c("div", { staticStyle: { width: "100%", height: "100%" } }, [
              _vm.show_signature
                ? _c(
                    "div",
                    {
                      staticClass: "view_signature",
                      style: {
                        "background-image":
                          "url('" + _vm.internal_signature.image_url + "')"
                      }
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "Signed at " +
                            _vm._s(
                              _vm.$root
                                .time_string_to_date(
                                  _vm.internal_signature.date_signed
                                )
                                .toLocaleString()
                            )
                        )
                      ])
                    ]
                  )
                : _vm._e()
            ])
          : _c("div", [
              _c(
                "div",
                {
                  staticClass: "btn-primary btn-sm btn",
                  on: {
                    click: function($event) {
                      _vm.show_signature = true
                    }
                  }
                },
                [_vm._v("Load Signature")]
              )
            ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }