var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "pce_card" }, [
      _c("div", { staticClass: "pce_card_content" }, [
        _c("div", { staticClass: "section_heading pb-4" }, [
          _vm._v("Physical Medical History")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v("Does the patient have Diabetes?")
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.does_the_patient_have_diabetes.value === 0
              ? _c("div", [_vm._v("No")])
              : _c("div", [_vm._v("Yes")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.does_the_patient_have_diabetes
              .comments === null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment.does_the_patient_have_diabetes
                        .comments
                    )
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v("Does the patient have a heart condition?")
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.does_the_patient_have_a_heart_condition
              .value === 0
              ? _c("div", [_vm._v("No")])
              : _c("div", [_vm._v("Yes")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.does_the_patient_have_a_heart_condition
              .comments === null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment
                        .does_the_patient_have_a_heart_condition.comments
                    )
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v("Does the patient have respiratory conditions?")
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment
              .does_the_patient_have_respiratory_conditions.value === 0
              ? _c("div", [_vm._v("No")])
              : _c("div", [_vm._v("Yes")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment
              .does_the_patient_have_respiratory_conditions.comments === null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment
                        .does_the_patient_have_respiratory_conditions.comments
                    )
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v("Does the patient have any blood borne viruses?")
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment
              .does_the_patient_have_any_blood_borne_viruses.value === 0
              ? _c("div", [_vm._v("No")])
              : _c("div", [_vm._v("Yes")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment
              .does_the_patient_have_any_blood_borne_viruses.comments === null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment
                        .does_the_patient_have_any_blood_borne_viruses.comments
                    )
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v("Does the patient have a DNAR in place?")
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.does_the_patient_have_a_dnar_in_place
              .value === 0
              ? _c("div", [_vm._v("No")])
              : _c("div", [_vm._v("Yes")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.does_the_patient_have_a_dnar_in_place
              .comments === null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment
                        .does_the_patient_have_a_dnar_in_place.comments
                    )
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v(
                "Does the patient have any physical disabilities, such as mobility issues?"
              )
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.any_mobility_issues.value === 0
              ? _c("div", [_vm._v("No")])
              : _c("div", [_vm._v("Yes")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.any_mobility_issues.comments === null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment.any_mobility_issues.comments
                    )
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v(
                "Does the patient have any other medical needs we need to be aware of (such as catheter, stoma, or epilepsy)?"
              )
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.other_medical_needs.value === 0
              ? _c("div", [_vm._v("No")])
              : _c("div", [_vm._v("Yes")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.other_medical_needs.comments === null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment.other_medical_needs.comments
                    )
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v("Does the patient have fragile skin?")
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.does_the_patient_have_fragile_skin
              .value === 0
              ? _c("div", [_vm._v("No")])
              : _c("div", [_vm._v("Yes")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.does_the_patient_have_fragile_skin
              .comments === null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment
                        .does_the_patient_have_fragile_skin.comments
                    )
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v(
                "Will the patient have received medication prior to the journey that may make them drowsy?"
              )
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.will_the_patient_receive_drowsy_meds
              .value === 0
              ? _c("div", [_vm._v("No")])
              : _c("div", [_vm._v("Yes")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.will_the_patient_receive_drowsy_meds
              .comments === null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment
                        .will_the_patient_receive_drowsy_meds.comments
                    )
                  )
                ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "hl" }),
      _vm._v(" "),
      _c("div", { staticClass: "pce_card_content" }, [
        _c("div", { staticClass: "section_heading pb-4" }, [
          _vm._v("Psychological History")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v(
                "Does the patient have a diagnosis of a mental health condition?"
              )
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.diagnosis_of_a_mental_health_condition
              .value === 0
              ? _c("div", [_vm._v("No")])
              : _c("div", [_vm._v("Yes")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.diagnosis_of_a_mental_health_condition
              .comments === null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment
                        .diagnosis_of_a_mental_health_condition.comments
                    )
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v("Does the patient have any learning disabilities?")
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.learning_disabilities.value === 0
              ? _c("div", [_vm._v("No")])
              : _c("div", [_vm._v("Yes")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.learning_disabilities.comments === null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment.learning_disabilities.comments
                    )
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v(
                "Does the patient have any other disabilities that we need to be aware of?"
              )
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.other_disabilities.value === 0
              ? _c("div", [_vm._v("No")])
              : _c("div", [_vm._v("Yes")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.other_disabilities.comments === null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment.other_disabilities.comments
                    )
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v(
                "Does the patient have a care plan? If Yes can we have a copy so that we can facilitate their needs?"
              )
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.care_plan.value === 0
              ? _c("div", [_vm._v("No")])
              : _c("div", [_vm._v("Yes")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.care_plan.comments === null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(_vm._s(_vm.value.risk_assessment.care_plan.comments))
                ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "hl" }),
      _vm._v(" "),
      _c("div", { staticClass: "pce_card_content" }, [
        _c("div", { staticClass: "section_heading pb-4" }, [
          _vm._v("Mental Capacity")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v("Does the patient have capacity?")
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.does_the_patient_have_capacity.value === 0
              ? _c("div", [_vm._v("No")])
              : _c("div", [_vm._v("Yes")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.does_the_patient_have_capacity
              .comments === null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment.does_the_patient_have_capacity
                        .comments
                    )
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v(
                "Does the patient have DoL's in place or any other court order?"
              )
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.patient_has_other_court_order.value === 0
              ? _c("div", [_vm._v("No")])
              : _c("div", [_vm._v("Yes")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.patient_has_other_court_order.comments ===
            null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment.patient_has_other_court_order
                        .comments
                    )
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v(
                "Is the patient subject to a safeguarding plan or currently involved in any safeguarding proceedings?"
              )
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.subject_safeguarding_plan.value === 0
              ? _c("div", [_vm._v("No")])
              : _c("div", [_vm._v("Yes")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.subject_safeguarding_plan.comments ===
            null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment.subject_safeguarding_plan
                        .comments
                    )
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v(
                "Does the patient have any known triggers and de-escalation techniques?"
              )
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.any_known_triggers.value === 0
              ? _c("div", [_vm._v("No")])
              : _c("div", [_vm._v("Yes")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.any_known_triggers.comments === null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment.any_known_triggers.comments
                    )
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v("Is the patient aware of the transfer?")
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.is_patient_aware_of_transfer.value === 0
              ? _c("div", [_vm._v("No")])
              : _c("div", [_vm._v("Yes")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.is_patient_aware_of_transfer.comments ===
            null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment.is_patient_aware_of_transfer
                        .comments
                    )
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v(
                "Are there any other significant psychological conditions or events that we need to be aware of?"
              )
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.other_psychological_conditions.value === 0
              ? _c("div", [_vm._v("No")])
              : _c("div", [_vm._v("Yes")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.other_psychological_conditions
              .comments === null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment.other_psychological_conditions
                        .comments
                    )
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-9" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v("What is the section status of the patient?")
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.section_status.comments === null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(_vm.value.risk_assessment.section_status.comments)
                  )
                ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.$root.patient_age_on_collection_date < 18
        ? _c("div", [
            _c("div", { staticClass: "hl" }),
            _vm._v(" "),
            _c("div", { staticClass: "pce_card_content" }, [
              _c("div", { staticClass: "section_heading pb-4" }, [
                _vm._v("Safeguarding Questions")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row pb-3" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "text-muted" }, [
                    _vm._v("Has the referrer sought assistance from CAMHS?")
                  ]),
                  _vm._v(" "),
                  _vm.value.risk_assessment
                    .referrer_sought_assistance_from_camhs.value === 0
                    ? _c("div", [_vm._v("No")])
                    : _c("div", [_vm._v("Yes")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3" }, [
                  _c("div", { staticClass: "text-muted" }, [
                    _vm._v("Comments")
                  ]),
                  _vm._v(" "),
                  _vm.value.risk_assessment
                    .referrer_sought_assistance_from_camhs.comments === null
                    ? _c("div", [_vm._v("None")])
                    : _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm.value.risk_assessment
                              .referrer_sought_assistance_from_camhs.comments
                          )
                        )
                      ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row pb-3" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "text-muted" }, [
                    _vm._v(
                      "Is the Child a looked after child, within the meaning of the Childrens Act 1989?"
                    )
                  ]),
                  _vm._v(" "),
                  _vm.value.risk_assessment.looked_after_child.value === 0
                    ? _c("div", [_vm._v("No")])
                    : _c("div", [_vm._v("Yes")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3" }, [
                  _c("div", { staticClass: "text-muted" }, [
                    _vm._v("Comments")
                  ]),
                  _vm._v(" "),
                  _vm.value.risk_assessment.looked_after_child.comments === null
                    ? _c("div", [_vm._v("None")])
                    : _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm.value.risk_assessment.looked_after_child
                              .comments
                          )
                        )
                      ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row pb-3" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "text-muted" }, [
                    _vm._v(
                      "Has the MASH been notified of the admission/Conveyance?"
                    )
                  ]),
                  _vm._v(" "),
                  _vm.value.risk_assessment.mash_been_notified.value === 0
                    ? _c("div", [_vm._v("No")])
                    : _c("div", [_vm._v("Yes")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3" }, [
                  _c("div", { staticClass: "text-muted" }, [
                    _vm._v("Comments")
                  ]),
                  _vm._v(" "),
                  _vm.value.risk_assessment.mash_been_notified.comments === null
                    ? _c("div", [_vm._v("None")])
                    : _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm.value.risk_assessment.mash_been_notified
                              .comments
                          )
                        )
                      ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row pb-3" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "text-muted" }, [
                    _vm._v("Name of local authority contacted")
                  ]),
                  _vm._v(" "),
                  _vm.value.risk_assessment.local_authority_contacted.value ===
                  0
                    ? _c("div", [_vm._v("No")])
                    : _c("div", [_vm._v("Yes")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3" }, [
                  _c("div", { staticClass: "text-muted" }, [
                    _vm._v("Comments")
                  ]),
                  _vm._v(" "),
                  _vm.value.risk_assessment.local_authority_contacted
                    .comments === null
                    ? _c("div", [_vm._v("None")])
                    : _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm.value.risk_assessment.local_authority_contacted
                              .comments
                          )
                        )
                      ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row pb-3" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "text-muted" }, [
                    _vm._v(
                      "What was the name of local authority member spoken to?"
                    )
                  ]),
                  _vm._v(" "),
                  _vm.value.risk_assessment.local_authority_member_name
                    .value === 0
                    ? _c("div", [_vm._v("No")])
                    : _c("div", [_vm._v("Yes")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3" }, [
                  _c("div", { staticClass: "text-muted" }, [
                    _vm._v("Comments")
                  ]),
                  _vm._v(" "),
                  _vm.value.risk_assessment.local_authority_member_name
                    .comments === null
                    ? _c("div", [_vm._v("None")])
                    : _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm.value.risk_assessment
                              .local_authority_member_name.comments
                          )
                        )
                      ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row pb-3" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "text-muted" }, [
                    _vm._v("Is there a safeguarding care plan. Can we see it?")
                  ]),
                  _vm._v(" "),
                  _vm.value.risk_assessment.safeguarding_care_plan.value === 0
                    ? _c("div", [_vm._v("No")])
                    : _c("div", [_vm._v("Yes")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3" }, [
                  _c("div", { staticClass: "text-muted" }, [
                    _vm._v("Comments")
                  ]),
                  _vm._v(" "),
                  _vm.value.risk_assessment.safeguarding_care_plan.comments ===
                  null
                    ? _c("div", [_vm._v("None")])
                    : _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm.value.risk_assessment.safeguarding_care_plan
                              .comments
                          )
                        )
                      ])
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "hl" }),
      _vm._v(" "),
      _c("div", { staticClass: "pce_card_content" }, [
        _c("div", { staticClass: "section_heading pb-4" }, [
          _vm._v("Risk Score")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v("The risk of violence?")
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.the_risk_of_violence.value === 0
              ? _c("div", [_vm._v("Low")])
              : _vm.value.risk_assessment.the_risk_of_violence.value === 1
              ? _c("div", [_vm._v("Moderate")])
              : _c("div", [_vm._v("High")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.the_risk_of_violence.comments === null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment.the_risk_of_violence.comments
                    )
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v("The risk of self-harm?")
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.the_risk_of_self_harm.value === 0
              ? _c("div", [_vm._v("Low")])
              : _vm.value.risk_assessment.the_risk_of_self_harm.value === 1
              ? _c("div", [_vm._v("Moderate")])
              : _c("div", [_vm._v("High")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.the_risk_of_self_harm.comments === null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment.the_risk_of_self_harm.comments
                    )
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v("The risk of suicide?")
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.the_risk_of_suicide.value === 0
              ? _c("div", [_vm._v("Low")])
              : _vm.value.risk_assessment.the_risk_of_suicide.value === 1
              ? _c("div", [_vm._v("Moderate")])
              : _c("div", [_vm._v("High")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.the_risk_of_suicide.comments === null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment.the_risk_of_suicide.comments
                    )
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v("The risk of weapon use?")
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.the_risk_of_weapon_use.value === 0
              ? _c("div", [_vm._v("Low")])
              : _vm.value.risk_assessment.the_risk_of_weapon_use.value === 1
              ? _c("div", [_vm._v("Moderate")])
              : _c("div", [_vm._v("High")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.the_risk_of_weapon_use.comments === null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment.the_risk_of_weapon_use.comments
                    )
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v("The risk of absconding?")
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.the_risk_of_absconding.value === 0
              ? _c("div", [_vm._v("Low")])
              : _vm.value.risk_assessment.the_risk_of_absconding.value === 1
              ? _c("div", [_vm._v("Moderate")])
              : _c("div", [_vm._v("High")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.the_risk_of_absconding.comments === null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment.the_risk_of_absconding.comments
                    )
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v("The risk of sexually inappropriate behaviour?")
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.inappropriate_behaviour_risk.value === 0
              ? _c("div", [_vm._v("Low")])
              : _vm.value.risk_assessment.inappropriate_behaviour_risk.value ===
                1
              ? _c("div", [_vm._v("Moderate")])
              : _c("div", [_vm._v("High")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.inappropriate_behaviour_risk.comments ===
            null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment.inappropriate_behaviour_risk
                        .comments
                    )
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row pb-3" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "text-muted" }, [
              _vm._v("Any implications for the mix on crew gender?")
            ]),
            _vm._v(" "),
            _vm.value.risk_assessment.crew_gender_implications.value === 0
              ? _c("div", [_vm._v("No")])
              : _c("div", [_vm._v("Yes")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "text-muted" }, [_vm._v("Comments")]),
            _vm._v(" "),
            _vm.value.risk_assessment.crew_gender_implications.comments === null
              ? _c("div", [_vm._v("None")])
              : _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.value.risk_assessment.crew_gender_implications
                        .comments
                    )
                  )
                ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }