var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { on: { click: _vm.openAddressSearch } }, [
      _c("input", {
        staticClass: "form-control address_search_input",
        class: { address_search_input_active: _vm.address_search_active },
        attrs: { type: "text", disabled: "" },
        domProps: { value: _vm.selected_address_text }
      }),
      _vm._v(" "),
      _vm.name !== null
        ? _c("input", {
            attrs: { type: "hidden", name: _vm.name },
            domProps: { value: _vm.value }
          })
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.address_search_active
      ? _c("div", { staticClass: "address_search_popup_container" }, [
          _c("div", {
            staticClass: "address_search_popup_close",
            on: {
              click: function($event) {
                _vm.address_search_active = false
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "address_search_popup" }, [
            _c("div", { staticClass: "address_search_popup_title_container" }, [
              _vm.address_search_page !== "address_edit"
                ? _c("h5", { staticClass: "address_search_popup_title" }, [
                    _vm._v("Address Search")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.address_search_page == "address_edit"
                ? _c("h5", { staticClass: "address_search_popup_title" }, [
                    _vm._v("Add New Address")
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.address_search_page === "address_edit"
              ? _c(
                  "div",
                  {
                    staticClass: "btn btn-link",
                    on: {
                      click: function($event) {
                        return _vm.back_to_search()
                      }
                    }
                  },
                  [_vm._v("Back to search")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "address_search_popup_body" }, [
              _vm.address_search_page !== "address_edit"
                ? _c("div", { staticClass: "row d-flex align-items-center" }, [
                    _c("div", { staticClass: "col-2" }, [
                      _vm._v(
                        "\n                        Postcode\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.address_search_postcode,
                            expression: "address_search_postcode"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "search", autocomplete: "off" },
                        domProps: { value: _vm.address_search_postcode },
                        on: {
                          focus: function($event) {
                            _vm.address_search_page = "search"
                          },
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.searchAddresses()
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.address_search_postcode = $event.target.value
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4" }, [
                      _c(
                        "div",
                        {
                          staticClass: "btn btn-primary",
                          on: {
                            click: function($event) {
                              return _vm.searchAddresses()
                            }
                          }
                        },
                        [_vm._v("SEARCH")]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.address_search_page === "search_results"
                ? _c("div", [
                    _c("br"),
                    _vm._v(" "),
                    !_vm.search_results_loading
                      ? _c(
                          "div",
                          [
                            _vm.address_search_results.length >= 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "row d-flex align-items-center"
                                  },
                                  [
                                    _c("div", { staticClass: "col-2" }, [
                                      _vm._v(
                                        "\n                                Filter\n                            "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-6" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.address_search_filter,
                                            expression: "address_search_filter"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "search",
                                          autocomplete: "off"
                                        },
                                        domProps: {
                                          value: _vm.address_search_filter
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.address_search_filter =
                                              $event.target.value
                                          }
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _vm.address_search_results.length === 0
                              ? _c("div", [_vm._v("No results found")])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.address_search_results, function(
                              address_result,
                              address_result_index
                            ) {
                              return _vm.address_filter(
                                address_result.full_address
                              )
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row address_search_result_row d-flex align-items-center"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-10 address_search_result_text"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                address_result.full_address
                                              ) +
                                              "\n                            "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "col-2" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "btn btn-primary btn-sm d-flex align-items-center justify-content-center",
                                            on: {
                                              click: function($event) {
                                                return _vm.select_serp_address(
                                                  address_result_index
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("SELECT")]
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e()
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.address_search_page !== "address_edit"
                ? _c(
                    "div",
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("center", [
                        _c(
                          "div",
                          {
                            staticClass: "btn btn-link",
                            on: {
                              click: function($event) {
                                return _vm.add_address()
                              }
                            }
                          },
                          [_vm._v("Add address")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("br")
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.address_search_page === "address_edit"
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "row d-flex align-items-center" },
                      [
                        _c("div", { staticClass: "col-4" }, [
                          _vm._v(
                            "\n                            Hospital or Facility Name\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-8" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.edit_address_details.hospital_name,
                                expression: "edit_address_details.hospital_name"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "search", autocomplete: "off" },
                            domProps: {
                              value: _vm.edit_address_details.hospital_name
                            },
                            on: {
                              blur: function($event) {
                                return _vm.edit_address_validate()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.edit_address_details,
                                  "hospital_name",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row d-flex align-items-center" },
                      [
                        _c("div", { staticClass: "col-4" }, [
                          _vm._v(
                            "\n                            Ward, Wing, Suite, or Clinic\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-8" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.edit_address_details.ward,
                                expression: "edit_address_details.ward"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "search", autocomplete: "off" },
                            domProps: { value: _vm.edit_address_details.ward },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.edit_address_details,
                                  "ward",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row d-flex align-items-center" },
                      [
                        _c("div", { staticClass: "col-4" }, [
                          _vm._v(
                            "\n                            Address line 1\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-8" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.edit_address_details.address_line_1,
                                expression:
                                  "edit_address_details.address_line_1"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "search", autocomplete: "off" },
                            domProps: {
                              value: _vm.edit_address_details.address_line_1
                            },
                            on: {
                              blur: function($event) {
                                return _vm.edit_address_validate()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.edit_address_details,
                                  "address_line_1",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row d-flex align-items-center" },
                      [
                        _c("div", { staticClass: "col-4" }, [
                          _vm._v(
                            "\n                            Address line 2\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-8" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.edit_address_details.address_line_2,
                                expression:
                                  "edit_address_details.address_line_2"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "search", autocomplete: "off" },
                            domProps: {
                              value: _vm.edit_address_details.address_line_2
                            },
                            on: {
                              blur: function($event) {
                                return _vm.edit_address_validate()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.edit_address_details,
                                  "address_line_2",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row d-flex align-items-center" },
                      [
                        _c("div", { staticClass: "col-4" }, [
                          _vm._v(
                            "\n                            Address line 3\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-8" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.edit_address_details.address_line_3,
                                expression:
                                  "edit_address_details.address_line_3"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "search", autocomplete: "off" },
                            domProps: {
                              value: _vm.edit_address_details.address_line_3
                            },
                            on: {
                              blur: function($event) {
                                return _vm.edit_address_validate()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.edit_address_details,
                                  "address_line_3",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row d-flex align-items-center" },
                      [
                        _c("div", { staticClass: "col-4" }, [
                          _vm._v(
                            "\n                            Address line 4\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-8" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.edit_address_details.address_line_4,
                                expression:
                                  "edit_address_details.address_line_4"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "search", autocomplete: "off" },
                            domProps: {
                              value: _vm.edit_address_details.address_line_4
                            },
                            on: {
                              blur: function($event) {
                                return _vm.edit_address_validate()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.edit_address_details,
                                  "address_line_4",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row d-flex align-items-center" },
                      [
                        _c("div", { staticClass: "col-4" }, [
                          _vm._v(
                            "\n                            Town or City\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-8" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.edit_address_details.city,
                                expression: "edit_address_details.city"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "search", autocomplete: "off" },
                            domProps: { value: _vm.edit_address_details.city },
                            on: {
                              blur: function($event) {
                                return _vm.edit_address_validate()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.edit_address_details,
                                  "city",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row d-flex align-items-center" },
                      [
                        _c("div", { staticClass: "col-4" }, [
                          _vm._v(
                            "\n                            Postcode\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-8" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.edit_address_details.postcode,
                                expression: "edit_address_details.postcode"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "search", autocomplete: "off" },
                            domProps: {
                              value: _vm.edit_address_details.postcode
                            },
                            on: {
                              blur: function($event) {
                                return _vm.edit_address_validate()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.edit_address_details,
                                  "postcode",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm.address_edit_postcode_error
                      ? _c(
                          "div",
                          { staticClass: "row d-flex align-items-center" },
                          [
                            _c("div", { staticClass: "col-4" }),
                            _vm._v(" "),
                            _vm._m(0)
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _vm.current_address_id === "new"
                      ? _c(
                          "div",
                          {
                            staticClass: "btn btn-primary",
                            on: {
                              click: function($event) {
                                return _vm.set_address()
                              }
                            }
                          },
                          [_vm._v("SAVE NEW ADDRESS")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.current_address_id !== "new"
                      ? _c(
                          "div",
                          {
                            staticClass: "btn btn-primary",
                            staticStyle: { width: "100px" },
                            on: {
                              click: function($event) {
                                return _vm.set_address()
                              }
                            }
                          },
                          [_vm._v("OK")]
                        )
                      : _vm._e()
                  ])
                : _vm._e()
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-8" }, [
      _c("small", { staticClass: "text-danger" }, [
        _vm._v("Please enter a valid postcode")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }