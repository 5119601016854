var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "image_map_box_container" }, [
    _c("div", { attrs: { id: "image_map_debug" } }),
    _vm._v(" "),
    _vm.show_mark_edit_bar
      ? _c(
          "div",
          {
            staticClass: "image_map_edit_topbar",
            style: { top: _vm.$root.topbar_height_in_px + "px" }
          },
          [
            _c("div", { staticClass: "image_map_edit_contents" }, [
              _c("div", { staticClass: "float-left" }, [
                _c("div", { staticClass: "image_map_edit_mark_name" }, [
                  _vm._v(
                    _vm._s(
                      _vm.image_map_box_markup_positions[
                        _vm.mark_edit_bar_current_mark_id
                      ]["title"]
                    )
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "image_map_edit_mark_notes" }, [
                  _vm._v(
                    _vm._s(
                      _vm.image_map_box_markup_positions[
                        _vm.mark_edit_bar_current_mark_id
                      ]["notes"]
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "image_map_edit_mark_open_edit_popup",
                  on: {
                    click: function($event) {
                      return _vm.open_edit_popup()
                    }
                  }
                },
                [_vm._v("EDIT")]
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.show_mark_edit_bar
      ? _c("div", {
          staticClass: "image_map_edit_topbar_close_overlay",
          on: {
            click: function($event) {
              return _vm.hideMarkEditBar()
            }
          }
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.show_user_input_screen
      ? _c("div", { staticClass: "image_map_user_input_popup" }, [
          _c("div", { staticClass: "image_map_user_input_popup_contents" }, [
            _c("div", { staticClass: "image_map_user_input_popup_title" }, [
              _vm.user_input_type != "editing"
                ? _c("span", [
                    _vm._v("Add " + _vm._s(_vm.user_input_mark_name))
                  ])
                : _vm._e(),
              _vm.user_input_type == "editing"
                ? _c("span", [
                    _vm._v("Edit " + _vm._s(_vm.user_input_mark_name))
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.user_input_errors !== ""
              ? _c("div", { staticClass: "text-danger text-center mt-4" }, [
                  _vm._v(_vm._s(_vm.user_input_errors))
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "image_map_user_input_popup_body" }, [
              _c("div", { staticClass: "form-group pb-2" }, [
                _c("label", [_vm._v(_vm._s(_vm.user_input_mark_name))]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user_input_name,
                      expression: "user_input_name"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "search", autocomplete: "off" },
                  domProps: { value: _vm.user_input_name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.user_input_name = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group pb-2" }, [
                _c("label", [
                  _vm._v(_vm._s(_vm.user_input_mark_name) + " Notes")
                ]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user_input_notes,
                      expression: "user_input_notes"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "search", autocomplete: "off", rows: "4" },
                  domProps: { value: _vm.user_input_notes },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.user_input_notes = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "btn btn-primary btn-block font-weight-bold",
                  on: {
                    click: function($event) {
                      return _vm.sendUserInput()
                    }
                  }
                },
                [
                  _vm.user_input_type != "editing"
                    ? _c("span", [_vm._v("ADD")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user_input_type == "editing"
                    ? _c("span", [_vm._v("SAVE")])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _vm.user_input_type == "editing"
                ? _c("div", [
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "btn btn-danger btn-block font-weight-bold",
                        on: {
                          click: function($event) {
                            return _vm.userInputRemoveMark()
                          }
                        }
                      },
                      [_vm._v("Delete")]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "btn light btn-block",
                  on: {
                    click: function($event) {
                      return _vm.exitUserInputScreen()
                    }
                  }
                },
                [_vm._v("Cancel")]
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "no_edit_image_map" }, [
      _c(
        "div",
        {
          ref: _vm.component_ref + "InsideComponent",
          staticClass: "image_map_box",
          style: {
            "background-image": 'url("' + _vm.background_img_url + '")'
          },
          attrs: { id: "image_map_box" + _vm.component_ref },
          on: {
            mouseleave: function($event) {
              return _vm.stopDragging()
            },
            touchleave: function($event) {
              return _vm.stopDragging()
            },
            touchmove: _vm.onMouseMove,
            mousemove: _vm.onMouseMove,
            click: function($event) {
              return _vm.makeNewMark($event)
            }
          }
        },
        [
          _c("div", {
            staticClass: "image_map_box_markup",
            attrs: { id: "image_map_box_markup" + _vm.component_ref }
          })
        ]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm.block_edit
        ? _c(
            "div",
            [
              _c("div", { staticClass: "hl" }),
              _vm._v(" "),
              _vm._l(_vm.image_map_box_markup_positions, function(mark) {
                return _c("div", [
                  _c(
                    "p",
                    {
                      staticClass: "bold mb-0",
                      staticStyle: {
                        "font-size": "18px",
                        "font-weight": "bold"
                      }
                    },
                    [_vm._v(_vm._s(mark.title))]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(mark.notes))
                  ])
                ])
              })
            ],
            2
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }