var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "cva_index" } }, [
    _c("div", { attrs: { id: "appHomeBanner" } }, [
      _c("div", { attrs: { id: "appBannerOverlay" } }, [
        _c("div", { attrs: { id: "home_image_overlay" } }),
        _vm._v(" "),
        _c("div", { attrs: { id: "home_color_overlay" } }, [
          _vm.$root.currentUser !== null
            ? _c("h1", { attrs: { id: "homepage_title" } }, [
                _vm._v(
                  "Hello " + _vm._s(_vm.$root.currentUser.name.split(" ")[0])
                )
              ])
            : _vm._e()
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { attrs: { id: "pce_list" } }, [
      _vm.$root.currentCVA !== null &&
      _vm.$root.listPcesForUsersCVAs[_vm.$root.currentCVA.id] !== null &&
      _vm.$root.listPcesForUsersCVAs[_vm.$root.currentCVA.id] !== undefined
        ? _c("h2", [_vm._v("Here are your Patient Care Episodes")])
        : _c("h2", [_vm._v("You have no Patient Care Episodes yet")]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-start align-items-center",
          attrs: { id: "inspection_forms" }
        },
        [
          !_vm.forms.pre
            ? _c(
                "button",
                {
                  staticClass: "btn btn-info",
                  on: {
                    click: function($event) {
                      _vm.$root.page = "pre_cva"
                    }
                  }
                },
                [_vm._v("Pre CVA inspection form")]
              )
            : _c("div", { staticClass: "text-success font-italic" }, [
                _vm._v("Pre CVA inspection form complete")
              ]),
          _vm._v(" "),
          !_vm.forms.post & _vm.forms.pre
            ? _c(
                "button",
                {
                  staticClass: "btn btn-info",
                  on: {
                    click: function($event) {
                      _vm.$root.page = "post_cva"
                    }
                  }
                },
                [_vm._v("Post CVA inspection form")]
              )
            : _vm.forms.post & _vm.forms.pre
            ? _c("div", { staticClass: "text-success font-italic" }, [
                _vm._v("Post CVA inspection form complete")
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.$root.currentCVA !== null &&
      _vm.$root.listPcesForUsersCVAs[_vm.$root.currentCVA.id] !== null &&
      _vm.$root.listPcesForUsersCVAs[_vm.$root.currentCVA.id] !== undefined
        ? _c(
            "div",
            _vm._l(
              _vm.$root.listPcesForUsersCVAs[_vm.$root.currentCVA.id],
              function(pce) {
                return _c(
                  "div",
                  {
                    staticClass: "pce_list_item",
                    on: {
                      click: function($event) {
                        return _vm.loadPCE(pce.id, _vm.forms.pre)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "pce_list_item_body" }, [
                      _c("div", { staticClass: "pce_list_item_title" }, [
                        _vm._v(_vm._s(pce.deployed_name))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pce_list_item_tag" }, [
                        _vm._v("PCE")
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "m-0" }, [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "13.333",
                              height: "13.333",
                              viewBox: "0 0 13.333 13.333"
                            }
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  id: "Group_1826",
                                  "data-name": "Group 1826",
                                  transform: "translate(-95.333 -536.333)"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    id: "Path_862",
                                    "data-name": "Path 862",
                                    d: "M12,7H11v4l3.5,2.1.5-.82L12,10.5Z",
                                    transform: "translate(90.333 532.667)",
                                    fill: "#9d9d9d"
                                  }
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    id: "Path_863",
                                    "data-name": "Path 863",
                                    d:
                                      "M8.66,2a6.667,6.667,0,1,0,6.673,6.667A6.663,6.663,0,0,0,8.66,2Zm.007,12A5.333,5.333,0,1,1,14,8.667,5.332,5.332,0,0,1,8.667,14Z",
                                    transform: "translate(93.333 534.333)",
                                    fill: "#9d9d9d"
                                  }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(
                          "\n\n                        " +
                            _vm._s(pce.collection_time) +
                            "\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "m-0 pt-2" }, [
                        _vm._v(_vm._s(pce.type))
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "m-0 pt-2" }, [
                        _vm._v("Ref: SC-" + _vm._s(pce.id))
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "m-0 pt-2" }, [
                        _vm._v("CVA ID: " + _vm._s(pce.cva_id))
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "pce_list_item_status justify-content-end"
                      },
                      [
                        _vm.forms.pre
                          ? _c("button", { staticClass: "btn btn-primary" }, [
                              _vm._v("Start PCE")
                            ])
                          : _c(
                              "button",
                              {
                                staticClass: "btn btn-warning",
                                attrs: { disabled: "" }
                              },
                              [
                                _vm._v(
                                  "Please Complete Pre CVA Inspection form"
                                )
                              ]
                            )
                      ]
                    )
                  ]
                )
              }
            ),
            0
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex justify-content-center" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          on: {
            click: function($event) {
              _vm.$root.page = "home"
              _vm.$root.currentCVA = null
            }
          }
        },
        [_vm._v("EXIT CREW VEHICLE ASSIGNMENT")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }