var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.in_dr_view
      ? _c(
          "div",
          { attrs: { id: "in_dr_view_overlay" } },
          [
            _c("app-pce-hero-banner", {
              attrs: { title: "Client entered - Patient Details" },
              on: {
                close: function($event) {
                  _vm.in_dr_view = false
                }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "p-4" },
              [
                _c("label", { staticClass: "input_label" }, [
                  _vm._v("Does the patient have any visible injuries?")
                ]),
                _vm._v(" "),
                _c("option-toggle", {
                  attrs: {
                    toggle_options: ["No", "Yes"],
                    editable: _vm.dr_details_editable
                  },
                  on: {
                    change: function($event) {
                      return _vm.$root.queue_save_current_pce()
                    }
                  },
                  model: {
                    value: _vm.value.any_visible_injuries_start,
                    callback: function($$v) {
                      _vm.$set(_vm.value, "any_visible_injuries_start", $$v)
                    },
                    expression: "value.any_visible_injuries_start"
                  }
                }),
                _vm._v(" "),
                _vm.value.any_visible_injuries_start
                  ? _c(
                      "div",
                      [
                        _c("app-pce-image-map", {
                          ref: "patient_injuries_start",
                          attrs: {
                            block_edit: !_vm.dr_details_editable,
                            image: "assets/img/patient_image_map.png",
                            component_name: "patient_injuries_start",
                            set_mark_name: "Injury"
                          },
                          model: {
                            value: _vm.value.into_care_image_map,
                            callback: function($$v) {
                              _vm.$set(_vm.value, "into_care_image_map", $$v)
                            },
                            expression: "value.into_care_image_map"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("br"),
                _c("br"),
                _vm._v(" "),
                _c("label", { staticClass: "input_label" }, [
                  _vm._v("Patient property handed over to SecureCare")
                ]),
                _vm._v(" "),
                _c("app-freetext-list", {
                  attrs: { editable: _vm.dr_details_editable },
                  on: {
                    change: function($event) {
                      return _vm.$root.queue_save_current_pce()
                    }
                  },
                  model: {
                    value: _vm.value.patient_property,
                    callback: function($$v) {
                      _vm.$set(_vm.value, "patient_property", $$v)
                    },
                    expression: "value.patient_property"
                  }
                }),
                _vm._v(" "),
                _c("label", { staticClass: "input_label" }, [
                  _vm._v("Patient medication handed over to SecureCare")
                ]),
                _vm._v(" "),
                _c("app-freetext-list", {
                  attrs: { editable: _vm.dr_details_editable },
                  on: {
                    change: function($event) {
                      return _vm.$root.queue_save_current_pce()
                    }
                  },
                  model: {
                    value: _vm.value.patient_meds,
                    callback: function($$v) {
                      _vm.$set(_vm.value, "patient_meds", $$v)
                    },
                    expression: "value.patient_meds"
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _c("label", { staticClass: "input_label" }, [
                      _vm._v("Initial patient presentation")
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.value.initial_presentation,
                            expression: "value.initial_presentation"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { disabled: !_vm.dr_details_editable },
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.value,
                                "initial_presentation",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function($event) {
                              return _vm.$root.queue_save_current_pce()
                            }
                          ]
                        }
                      },
                      [
                        _c("option", { attrs: { disabled: "", value: "0" } }, [
                          _vm._v("Select an initial patient presentation")
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.$root.presentation_options, function(
                          restraint_level_label,
                          restraint_level
                        ) {
                          return _c(
                            "option",
                            { domProps: { value: restraint_level } },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(restraint_level_label)
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6" }, [
                    _c("label", { staticClass: "input_label" }, [
                      _vm._v("Initial level of restraint")
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.value.initial_restraint,
                            expression: "value.initial_restraint"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { disabled: !_vm.dr_details_editable },
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.value,
                                "initial_restraint",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function($event) {
                              return _vm.$root.queue_save_current_pce()
                            }
                          ]
                        }
                      },
                      [
                        _c(
                          "option",
                          { attrs: { disabled: "", selected: "", value: "0" } },
                          [_vm._v("Select a restraint label")]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.$root.restraint_level_options, function(
                          restraint_level_label,
                          restraint_level
                        ) {
                          return _c(
                            "option",
                            { domProps: { value: restraint_level } },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(restraint_level_label)
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ])
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "hl" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "p-4" },
              [
                _c("app-pce-signature", {
                  attrs: { label: "Patient handed over to Secure Care by:" },
                  on: {
                    change: function($event) {
                      return _vm.$root.queue_save_current_pce()
                    }
                  },
                  model: {
                    value: _vm.value.into_care_dr_signature,
                    callback: function($$v) {
                      _vm.$set(_vm.value, "into_care_dr_signature", $$v)
                    },
                    expression: "value.into_care_dr_signature"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "font-weight-bold text-center" }, [
              _c("p", [
                _vm._v(
                  "When you're ready, press done and hand the device back to your Secure Care crew"
                )
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm.dr_details_editable
                ? _c(
                    "div",
                    {
                      staticClass: "btn btn-primary",
                      class: { disabled: !_vm.dr_hand_into_care_valid },
                      staticStyle: { width: "250px" },
                      on: { click: _vm.dr_done }
                    },
                    [_vm._v("Done")]
                  )
                : _c(
                    "div",
                    {
                      staticClass: "btn btn-primary",
                      staticStyle: { width: "250px" },
                      on: {
                        click: function($event) {
                          _vm.in_dr_view = false
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
            ]),
            _vm._v(" "),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _c("br"),
            _c("br")
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "pce_card" }, [
      _c(
        "div",
        {
          staticClass:
            "pce_card_content d-flex align-items-center justify-content-center"
        },
        [
          _c(
            "div",
            {
              staticClass: "btn btn-primary text-uppercase",
              staticStyle: { width: "600px" },
              on: {
                click: function($event) {
                  return _vm.get_details_from_dr()
                }
              }
            },
            [_vm._v("Get Patient Details from Nurse / Doctor")]
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "hl" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pce_card_content" },
        [
          _c("div", { staticClass: "section_heading pb-4" }, [
            _vm._v("Current Risk score")
          ]),
          _vm._v(" "),
          _c("div", { attrs: { id: "risk_score_number" } }, [
            _vm._v(
              "Score " + _vm._s(_vm.value.risk_assessment.risk_score_result)
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-2 px-3" }, [
            _c(
              "div",
              {
                staticClass: "row border-dark border border-bottom-0 p-1",
                class: {
                  ra_table_row_active:
                    _vm.value.risk_assessment.risk_score_result < 9
                }
              },
              [
                _c("div", { staticClass: "col-4" }, [
                  _vm._v(
                    "\n                            6-8 Low Risk\n                        "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-8" }, [
                  _vm._v(
                    "\n                            Driver Plus 1 Escort\n                        "
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "row border-dark border border-bottom-0 p-1",
                class: {
                  ra_table_row_active:
                    _vm.value.risk_assessment.risk_score_result > 8 &&
                    _vm.value.risk_assessment.risk_score_result < 13
                }
              },
              [
                _c("div", { staticClass: "col-4" }, [
                  _vm._v(
                    "\n                            9-12 Medium Risk\n                        "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-8" }, [
                  _vm._v(
                    "\n                            Driver Plus 2 Escorts and Cell vehicle or Driver Plus 2\n                        "
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "row border-dark border border-bottom-0 p-1",
                class: {
                  ra_table_row_active:
                    _vm.value.risk_assessment.risk_score_result > 12 &&
                    _vm.value.risk_assessment.risk_score_result < 15
                }
              },
              [
                _c("div", { staticClass: "col-4" }, [
                  _vm._v(
                    "\n                            13-14 High Risk\n                        "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-8" }, [
                  _vm._v(
                    "\n                            Driver Plus 2/3 and Cell Vehicle or Driver Plus 3\n                        "
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "row border-dark border p-1",
                class: {
                  ra_table_row_active:
                    _vm.value.risk_assessment.risk_score_result > 14
                }
              },
              [
                _c("div", { staticClass: "col-4" }, [
                  _vm._v(
                    "\n                            15 or over Very High Risk\n                        "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-8" }, [
                  _vm._v(
                    "\n                            Driver plus 3 and Cell Vehicle\n                        "
                  )
                ])
              ]
            )
          ]),
          _c("br"),
          _vm._v(" "),
          _c("label", { staticClass: "input_label" }, [
            _vm._v(
              "Based on the risk scoring shown above (provided when the booking was made), can you immediately observe any significant differences in the presentation of the patient being taken into your care and therefore the appropriateness of the crew and vehicle assigned? "
            )
          ]),
          _vm._v(" "),
          _c("option-toggle", {
            attrs: { toggle_options: ["No", "Yes"] },
            on: {
              change: function($event) {
                return _vm.$root.queue_save_current_pce()
              }
            },
            model: {
              value: _vm.value.ra_differences,
              callback: function($$v) {
                _vm.$set(_vm.value, "ra_differences", $$v)
              },
              expression: "value.ra_differences"
            }
          }),
          _vm._v(" "),
          _vm.value.ra_differences
            ? _c("div", { staticClass: "mt-3" }, [
                _c("label", { staticClass: "input_label" }, [
                  _vm._v("Please detail")
                ]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.value.ra_differences_details,
                      expression: "value.ra_differences_details"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { rows: "4" },
                  domProps: { value: _vm.value.ra_differences_details },
                  on: {
                    blur: function($event) {
                      return _vm.$root.queue_save_current_pce()
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.value,
                        "ra_differences_details",
                        $event.target.value
                      )
                    }
                  }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "hl" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "p-4" },
            [
              _c("app-pce-signature", {
                attrs: {
                  label:
                    "Patient Into Care. Received by Secure Care crew member:"
                },
                on: {
                  change: function($event) {
                    return _vm.$root.queue_save_current_pce()
                  }
                },
                model: {
                  value: _vm.value.into_care_crew_signature,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "into_care_crew_signature", $$v)
                  },
                  expression: "value.into_care_crew_signature"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }