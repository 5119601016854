<template>

    <div>
        <!--  Take signature popup-->
        <div v-show="take_signature" class="take_signature_page">
            <canvas v-show="showExportCanvas" :id="'sig_exportCanvas'+sig_id" width="300" height="183"></canvas>

            <div @mousemove="mouseMove" @touchmove="mouseMove" @mousedown="startDrawing" @touchstart="startDrawing" @touchleave="stopDrawing" @mouseleave="stopDrawing"  @touchend="stopDrawing" @mouseup="stopDrawing">
                <canvas :id="'sig_canvas'+sig_id" class="canvas" width="window_width" :height="(window_width/1.63934426)+'px'" :width="window_width+'px'"></canvas>
            </div>
            <div class="take_signature_page_bottom_bar">
                <div class="sig_page_bottom_bar_btn border-right"  @click="reset_take_signature_box(); take_signature=false">
                    <div class="px-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="7.67" height="15.8" viewBox="0 0 7.67 15.8" >
                            <path id="Path_16" data-name="Path 16" d="M13.51,3.512,12.34,2.1,5.84,10l6.507,7.9,1.163-1.412L8.167,10Z" transform="translate(-5.84 -2.1)" fill="#fff"/>
                        </svg>
                    </div>
                    BACK
                </div>
                <div class="sig_page_bottom_bar_btn" @click="reset_take_signature_box()">
                    <div class="px-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 11.99 12">
                            <path id="Path_37" data-name="Path 37" d="M14.238,5.762A6,6,0,1,0,15.8,11.5h-1.56A4.5,4.5,0,1,1,10,5.5a4.435,4.435,0,0,1,3.164,1.335L10.751,9.25H16V4Z" transform="translate(-4.01 -4)" fill="#fff"/>
                        </svg>
                    </div>
                    RESTART
                </div>

                <div class="sig_page_bottom_bar_btn sign" :class="{'active':started}" @click="save_signature()">
                    SIGN
                </div>
            </div>
        </div>

        <!--  Inline signature section -->
        <label class="input_label">{{label}}</label>
        <input type="text" class="form-control" :disabled="internal_signature.date_signed!==''&&internal_signature.date_signed!==null" v-model="internal_signature.name">
        <small class="text-danger" v-if="no_name_error">Please enter a name</small>

        <label class="input_label">Signature</label>
        <div class="signature_preview_box" :class="{'d-flex':!show_signature}" :style="{'width':(window_width-17)+'px','height':((window_width-17)/1.63934426)+'px'}"  @click="goto_take_signature_page()">
            <div v-if="internal_signature.date_signed==null">
                TAP TO SIGN
            </div>
            <div v-else-if="show_signature"  style="width: 100%; height: 100%;" >
                <div v-if="show_signature" class="view_signature" :style="{'background-image':'url(\''+internal_signature.image_url+'\')'}">
                    <div>Signed at {{$root.time_string_to_date(internal_signature.date_signed).toLocaleString()}}</div>
                </div>
            </div>
            <div v-else>
                <div class="btn-primary btn-sm btn" @click="show_signature=true">Load Signature</div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    props: {
        value: {
            type: [String],
            required: false,
            default: null,
        },
        label: {
          type: String,
          default: 'Name',
        },
    },
    data: function() {
        return {
            scroll_top_before_sign_page: 0,
            show_signature: false,
            showExportCanvas: false,
            window_width: 0,
            take_signature: false,
            last_mouse_location: [0,0],
            signature_point_groups:[],
            current_point_group: [],
            drawing: false,
            canvas_rect: null,
            canvas: null,
            ctx: null,
            started: false,
            no_name_error: false,
            sig_id: Math.floor(Math.random()*(99999999-100000+1)+100000),
            internal_signature: {
                date_signed: null,
                image_url: null,
                name: null,
            }
        }
    },
    watch: {
        internal_signature: {
            deep: true,
            handler(value) {
                this.$emit('input', JSON.stringify(this.internal_signature));
            }
        },
        value: function () {
            this.internal_signature = JSON.parse(this.value)
        }
    },
    mounted: function () {
        if (this.value!==null) {
            this.internal_signature = JSON.parse(this.value)
        }
        this.window_width = window.innerWidth - 48;
    },
    beforeDestroy() {
        document.body.style.overflow = "auto"; // Allows the page to scroll again, incase we stopped it in goto_take_signature_page
        window.scrollTo(0, this.scroll_top_before_sign_page);

    },
    methods: {
        goto_take_signature_page: function() {
            if (this.internal_signature.name==null||this.internal_signature.name==='') {
                this.no_name_error = true;
                return false;
            } else {
                this.no_name_error = false;
            }
            if (this.internal_signature.date_signed==null) {
                this.take_signature = true;
                this.scroll_top_before_sign_page = (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0);
                window.scrollTo(0, 0);
                document.body.style.overflow = "hidden"; // Stops the page from scrolling while you take a signature

                this.canvas = document.getElementById('sig_canvas'+this.sig_id);
                this.ctx = this.canvas.getContext("2d");
                this.canvas_rect = this.canvas.getBoundingClientRect();
            }
        },
        save_signature: function () {
            document.body.style.overflow = "auto"; // Allows the page to scroll again, after we stopped it in goto_take_signature_page
            window.scrollTo(0, this.scroll_top_before_sign_page);

            this.showExportCanvas = true;
            var resizedCanvas = document.getElementById('sig_exportCanvas'+this.sig_id);
            var resizedContext = resizedCanvas.getContext("2d");
            resizedContext.drawImage(this.canvas, 0, 0, 300, 183);
            this.internal_signature.image_url = resizedCanvas.toDataURL();
            this.internal_signature.date_signed = this.$root.date_to_time_string(new Date());
            this.showExportCanvas = false;
            this.reset_take_signature_box();
            this.take_signature=false
            this.$emit('change');
        },
        reset_take_signature_box: function () {
            document.body.style.overflow = "auto"; // Allows the page to scroll again, incase we stopped it in goto_take_signature_page
            window.scrollTo(0, this.scroll_top_before_sign_page);

            this.drawing = false;
            this.started = false;
            this.current_point_group = [];
            this.signature_point_groups = [];
            this.render(false)
        },
        startDrawing: function (e) {
            console.log('startDrawing',e)

            this.started = true;
            this.canvas_rect = this.canvas.getBoundingClientRect();
            this.current_point_group = []
            var x,y;
            if(e.type == 'touchstart' || e.type == 'touchmove' || e.type == 'touchend' || e.type == 'touchcancel') {
                var touch = e.touches[0] || e.changedTouches[0];
                x = touch.pageX - this.canvas_rect.left;
                y = touch.pageY - this.canvas_rect.top;
            } else if (e.type == 'mousedown' || e.type == 'mouseup' || e.type == 'mousemove' || e.type == 'mouseover'|| e.type=='mouseout' || e.type=='mouseenter' || e.type=='mouseleave') {
                x = e.clientX - this.canvas_rect.left;
                y = e.clientY - this.canvas_rect.top;
            }
            this.current_point_group.push([x, y])
            this.drawing = true;
        },
        stopDrawing: function () {
            console.log('stopDrawing')
            this.drawing = false;
            this.signature_point_groups.push(this.current_point_group);
            this.current_point_group = []
        },
        render: function(only_render_current_group = false) {
            console.log('Render')
            if (only_render_current_group) {
                var last_point = this.current_point_group[0]
                this.current_point_group.forEach((point) => {
                    this.ctx.beginPath();
                    this.ctx.strokeStyle = 'black';
                    this.ctx.lineWidth = 5;
                    this.ctx.lineJoin = "round";
                    this.ctx.moveTo(last_point[0], last_point[1]);
                    this.ctx.lineTo(point[0], point[1]);
                    this.ctx.closePath();
                    this.ctx.stroke();
                    last_point = point;
                })
            } else {
                console.log('C Render')
                this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
                this.signature_point_groups.forEach((signature_points)=> {
                    var last_point = signature_points[0]
                    signature_points.forEach((point) => {
                        this.ctx.beginPath();
                        this.ctx.strokeStyle = 'black';
                        this.ctx.lineWidth = 5;
                        this.ctx.lineJoin = "round";
                        this.ctx.moveTo(last_point[0], last_point[1]);
                        this.ctx.lineTo(point[0], point[1]);
                        this.ctx.closePath();
                        this.ctx.stroke();
                        last_point = point;
                    })
                })
            }
            // ctx.stroke();
        },
        mouseMove(e) {
            console.log('mouseMove',e)
            var x,y;
            if(e.type == 'touchstart' || e.type == 'touchmove' || e.type == 'touchend' || e.type == 'touchcancel') {
                var touch = e.touches[0] || e.changedTouches[0];
                x = touch.pageX - this.canvas_rect.left;
                y = touch.pageY - this.canvas_rect.top;
            } else if (e.type == 'mousedown' || e.type == 'mouseup' || e.type == 'mousemove' || e.type == 'mouseover'|| e.type=='mouseout' || e.type=='mouseenter' || e.type=='mouseleave') {
                x = e.clientX- this.canvas_rect.left;
                y = e.clientY - this.canvas_rect.top;
            }

            if (this.drawing) {
                console.log('Move')

                var x_change = Math.abs(this.last_mouse_location[0]-x);
                var y_change = Math.abs(this.last_mouse_location[1]-y);

                console.log([x, y])

                if ((y_change+x_change) > 1) {
                    this.current_point_group.push([x, y])
                    this.last_mouse_location = [x, y];
                }
                this.render(true);
            } else {
                this.last_mouse_location = [x, y];
            }
        }
    }
}
</script>

<style scoped>
.signature_preview_box {
    max-width: 100%;
    font-size: 30px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    color: #cccccc;
    border: 2px solid black !important;
    user-select: none;
    cursor: pointer
}
.canvas {
    background-color: white;
    box-shadow: 0 0 4px #797979;
}

.take_signature_page {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #E2E2E2;
    z-index: 1003;
    user-select: none;
}

.take_signature_page_bottom_bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #5a5a5a;
    padding: 7px 0;
}

.sig_page_bottom_bar_btn {
    font-size: 14px;
    font-weight: bold;
    color: white;
    display: inline-flex;
    height: 100%;
    align-items: center;
    cursor: pointer;
    padding-right: 14px;
    border-width: 1px !important;
    border-color: #a7a7a7 !important;
}
.sig_page_bottom_bar_btn.sign {
    border: 2px solid #a7a7a7 !important;
    border-radius: 4px;
    padding: 10px 20px;
    float: right;
    margin-right: 7px;
    color: #a7a7a7;
}
.sig_page_bottom_bar_btn.sign.active {
    border: 2px solid white !important;
    color: white;
}
.view_signature {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: normal;
    color: #6d6d6d;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

</style>
