var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "splash_screen" } }, [
      _c("div", [
        _c("img", {
          attrs: {
            id: "splash_screen_logo",
            src: "assets/img/sidebar_logo.png",
            width: "316.31px",
            height: "70px"
          }
        }),
        _vm._v(" "),
        _c("div", { attrs: { id: "splash_screen_loading_box" } }, [
          _c(
            "div",
            {
              staticClass: "spinner-border text-light",
              staticStyle: { width: "60px", height: "60px" },
              attrs: { role: "status" }
            },
            [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }