var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.value.steps, function(step, index) {
      return _c(
        "div",
        {
          staticClass: "node_container",
          class: {
            disabled: step.disabled,
            done: step.completed && _vm.value.active_step !== step.label,
            active: _vm.value.active_step === step.label
          }
        },
        [
          _c(
            "div",
            {
              on: {
                click: function($event) {
                  return _vm.changeStep(index)
                }
              }
            },
            [
              _vm._m(0, true),
              _vm._v(" "),
              _c("div", { staticClass: "node_step_label" }, [
                _vm._v(
                  "\r\n                " +
                    _vm._s(step.label) +
                    "\r\n            "
                )
              ])
            ]
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "node_dot_container node_dot" }, [
      _c("div", { staticClass: "node_white_ring node_dot" }, [
        _c("div", { staticClass: "node_color_ring node_dot" }, [
          _c("div", { staticClass: "node_center node_dot" })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }