var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "progress-box-container" },
      _vm._l(_vm.value.steps, function(step, index) {
        return _c("div", [
          _c(
            "div",
            {
              staticClass: "progress-box",
              class: {
                prog_active: step.label === _vm.value.active_step,
                prog_done: step.completed,
                "prog-end-l": index === 0,
                "prog-end-r": index === _vm.value.steps.length - 1,
                prog_arrow: index !== _vm.value.steps.length - 1
              },
              on: {
                click: function($event) {
                  return _vm.changeStep(step.label)
                }
              }
            },
            [
              _c("div", [
                _c("div", { staticClass: "progress-box-title text-primary" }, [
                  _vm._v(_vm._s(step.label))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "progress-box-step" }, [
                  _vm._v(_vm._s(step.sub_title))
                ])
              ])
            ]
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }