var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c(
        "ais-instant-search",
        { attrs: { "search-client": _vm.searchClient, "index-name": "pce" } },
        [
          _c("ais-search-box", {
            ref: "ais-search-box",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var currentRefinement = ref.currentRefinement
                  var isSearchStalled = ref.isSearchStalled
                  var refine = ref.refine
                  return _c("div", {}, [
                    _c("div", { staticClass: "topbarSearchInput" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "text-muted material-icons align-self-center pl-3",
                          attrs: { for: "topNavSearchBarInput" }
                        },
                        [_vm._v("search")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: currentRefinement,
                            expression: "currentRefinement"
                          }
                        ],
                        staticClass: "align-self-center pl-3 d-inline-block",
                        staticStyle: {
                          "font-size": "20px",
                          border: "none",
                          outline: "none !important",
                          width: "calc(100% - 60px)"
                        },
                        attrs: {
                          autocomplete: "off",
                          type: "search",
                          id: "topNavSearchBarInput",
                          placeholder: "Search"
                        },
                        domProps: { value: currentRefinement },
                        on: {
                          blur: _vm.$root.searchBlur,
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              currentRefinement = $event.target.value
                            },
                            function($event) {
                              refine($event.currentTarget.value)
                              _vm.$root.updateSearchQ(
                                $event.currentTarget.value
                              )
                            }
                          ]
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("span", { attrs: { hidden: !isSearchStalled } }, [
                      _vm._v("Loading...")
                    ])
                  ])
                }
              }
            ])
          }),
          _vm._v(" "),
          !_vm.$root.hideResults
            ? _c("div", { staticClass: "topbarSearchResults" }, [
                _c(
                  "div",
                  { staticClass: "topbarSearchResultsList" },
                  [
                    _c(
                      "ais-index",
                      { attrs: { "index-name": "pce" } },
                      [
                        _c("ais-hits", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var items = ref.items
                                  return _c(
                                    "div",
                                    {},
                                    _vm._l(items, function(item) {
                                      return _c(
                                        "a",
                                        {
                                          key: item.objectID,
                                          staticClass: "topbarSearchResultItem",
                                          attrs: { href: "/pces/" + item.id }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "material-icons topbarSearchResultItem_icon"
                                            },
                                            [_vm._v("view_list")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "topbarSearchResultItem_text"
                                            },
                                            [
                                              _c("b", [
                                                _vm._v(_vm._s(item.sc_number))
                                              ]),
                                              _vm._v(" "),
                                              _vm._l(
                                                item._highlightResult,
                                                function(
                                                  highlight,
                                                  highlight_attr_name
                                                ) {
                                                  return _c("div", [
                                                    highlight.matchLevel ==
                                                    "full"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "topbarSearchResultItem_highlight"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(
                                                                  _vm
                                                                    .nice_attr_names[
                                                                    "pce"
                                                                  ][
                                                                    highlight_attr_name
                                                                  ]
                                                                ) +
                                                                ":"
                                                            ),
                                                            _c("span", {
                                                              staticClass:
                                                                "pl-1",
                                                              domProps: {
                                                                innerHTML: _vm._s(
                                                                  highlight.value
                                                                )
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ])
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                }
                              }
                            ],
                            null,
                            false,
                            4061599435
                          )
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "ais-index",
                      { attrs: { "index-name": "users" } },
                      [
                        _c("ais-hits", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var items = ref.items
                                  return _c(
                                    "div",
                                    {},
                                    _vm._l(items, function(item) {
                                      return _c(
                                        "a",
                                        {
                                          key: item.objectID,
                                          staticClass: "topbarSearchResultItem",
                                          attrs: { href: "/user/" + item.id }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "material-icons topbarSearchResultItem_icon"
                                            },
                                            [_vm._v("person")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "topbarSearchResultItem_text"
                                            },
                                            [
                                              _c("b", [
                                                _vm._v(_vm._s(item.name))
                                              ]),
                                              _vm._v(" "),
                                              _vm._l(
                                                item._highlightResult,
                                                function(
                                                  highlight,
                                                  highlight_attr_name
                                                ) {
                                                  return _c("div", [
                                                    highlight.matchLevel ==
                                                    "full"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "topbarSearchResultItem_highlight"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(
                                                                  _vm
                                                                    .nice_attr_names[
                                                                    "user"
                                                                  ][
                                                                    highlight_attr_name
                                                                  ]
                                                                ) +
                                                                ":"
                                                            ),
                                                            _c("span", {
                                                              staticClass:
                                                                "pl-1",
                                                              domProps: {
                                                                innerHTML: _vm._s(
                                                                  highlight.value
                                                                )
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ])
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                }
                              }
                            ],
                            null,
                            false,
                            3746860534
                          )
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "ais-index",
                      { attrs: { "index-name": "device" } },
                      [
                        _c("ais-hits", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var items = ref.items
                                  return _c(
                                    "div",
                                    {},
                                    _vm._l(items, function(item) {
                                      return _c(
                                        "a",
                                        {
                                          key: item.objectID,
                                          staticClass: "topbarSearchResultItem",
                                          attrs: { href: "/devices/" + item.id }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "material-icons topbarSearchResultItem_icon"
                                            },
                                            [_vm._v("smartphone")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "topbarSearchResultItem_text"
                                            },
                                            [
                                              _c("b", [
                                                _vm._v(_vm._s(item.name))
                                              ]),
                                              _vm._v(" "),
                                              _vm._l(
                                                item._highlightResult,
                                                function(
                                                  highlight,
                                                  highlight_attr_name
                                                ) {
                                                  return _c("div", [
                                                    highlight.matchLevel ==
                                                    "full"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "topbarSearchResultItem_highlight"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(
                                                                  _vm
                                                                    .nice_attr_names[
                                                                    "device"
                                                                  ][
                                                                    highlight_attr_name
                                                                  ]
                                                                ) +
                                                                ":"
                                                            ),
                                                            _c("span", {
                                                              staticClass:
                                                                "pl-1",
                                                              domProps: {
                                                                innerHTML: _vm._s(
                                                                  highlight.value
                                                                )
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ])
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                }
                              }
                            ],
                            null,
                            false,
                            3203674549
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }