var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "option_toggle_container",
      attrs: { tabindex: "0" },
      on: { keyup: _vm.keyMonitor }
    },
    _vm._l(_vm.toggle_options, function(option_item, option_index) {
      return _c(
        "div",
        {
          staticClass: "option_toggle_item",
          class: {
            option_toggle_active: option_index === _vm.value,
            option_toggle_item_left: option_index === 0,
            option_toggle_item_right:
              option_index === _vm.toggle_options.length - 1
          },
          on: {
            click: function($event) {
              return _vm.setValue(option_index)
            }
          }
        },
        [_vm._v(_vm._s(option_item))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }