<template>
<div>
    <div class="popup_container" v-if="value.dr_agree_with_injuries===0">
        <div class="close_popup"></div>
        <div class="popup_content_container">
            <div class="text-center pt-4">
                <div class="popup_title">Please get Secure Care to update the recorded injuries </div>
            </div>
            <div class="hl my-4"></div>
            <div class="px-4 pb-4">
                <div class="btn btn-block btn-primary" @click="value.dr_agree_with_injuries=null; in_dr_view=false">Device handed back to Secure Care</div>
                <div class="btn btn-block btn-outline-dark" @click="value.dr_agree_with_injuries=null">Cancel</div>
            </div>
        </div>
    </div>
    <div v-if="in_dr_view" id="in_dr_view_overlay">
        <app-pce-hero-banner title="Client entered - Handover Details" v-on:close="in_dr_view=false"></app-pce-hero-banner>
        <div class="p-4">
            <!-- Visible Injuries at into care -->
            <label class="input_label text-muted">Visible injuries recorded by doctor or nurse at collection</label>
            <app-pce-image-map :block_edit="true" image="assets/img/patient_image_map.png" ref="patient_injuries_start_handover_dr" component_name="patient_injuries_start_handover_dr" set_mark_name="Injury" v-model="value.into_care_image_map"></app-pce-image-map>
            <br><br>

            <!-- Visible Injuries at handover -->
            <label class="input_label text-muted">Additional visible injuries observable now at patient handover</label>
            <app-pce-image-map :block_edit="true" image="assets/img/patient_image_map.png" ref="patient_injuries_handover_dr" component_name="patient_injuries_handover_dr" set_mark_name="Injury" :value="value.patient_injuries_handover" ></app-pce-image-map>
            <br><br>

            <!-- Does DR agree with injuries -->
            <label class="input_label">1. Do you agree with the handover visible injuries description above</label>
            <option-toggle v-model="value.dr_agree_with_injuries" :toggle_options="['No','Yes']" :editable="dr_details_editable"></option-toggle>

        </div>
        <div class="hl"></div>
        <div class="p-4">
            <!-- Patient property -->
            <label class="input_label text-muted">Patient property in Secure Care possession</label>
            <app-freetext-list v-model="value.patient_property" :editable="false"></app-freetext-list>

            <!-- Does DR agree patient property -->
            <label class="input_label">2. Has the patient property been returned at Handover</label>
            <option-toggle v-model="value.dr_agree_with_returned_property" :toggle_options="['No','Yes']" :editable="dr_details_editable"></option-toggle>

            <!-- Details if DR doesn't agree with the patient property -->
            <div v-if="value.dr_agree_with_returned_property===0">
                <br>
                <label class="input_label">Please detail</label>
                <textarea rows="4" v-model="value.dr_returned_property_notes"  class="form-control" :disabled="!dr_details_editable"></textarea>

            </div>

        </div>
        <div class="hl"></div>
        <div class="p-4">
            <!-- Patient property -->
            <label class="input_label text-muted">Patient medication in Secure Care possession</label>
            <app-freetext-list v-model="value.patient_meds" :editable="false" ></app-freetext-list>

            <!-- Does DR agree patient property -->
            <label class="input_label">3. Has the patient medication been returned at Handover</label>
            <option-toggle v-model="value.dr_agree_with_returned_meds" :toggle_options="['No','Yes']" :editable="dr_details_editable"></option-toggle>

            <!-- Details if DR doesn't agree with the patient property -->
            <div v-if="value.dr_agree_with_returned_meds===0">
                <br>
                <label class="input_label">Please detail</label>
                <textarea rows="4" v-model="value.dr_returned_meds_notes" class="form-control" :disabled="!dr_details_editable"></textarea>
            </div>

        </div>
        <div class="hl"></div>
        <div class="p-4">
            <div class="row">
                <div class="col-6">

                    <!-- Patient presentation -->
                    <label class="input_label">Patient presentation</label>
                    <select class="form-control" v-model="value.handover_presentation" :disabled="!dr_details_editable">
                        <option disabled value="0">Select a patient presentation</option>
                        <option v-for="(restraint_level_label, restraint_level) in $root.presentation_options" :value="restraint_level">
                            {{ restraint_level_label }}</option>
                    </select>

                </div>
                <div class="col-6">

                    <!-- Patient presentation -->
                    <label class="input_label">Level of restraint</label>
                    <select class="form-control" v-model="value.handover_restraint" :disabled="!dr_details_editable">
                        <option disabled selected value="0">Select a restraint label</option>
                        <option v-for="(restraint_level_label, restraint_level) in $root.restraint_level_options" :value="restraint_level">
                            {{ restraint_level_label }}</option>
                    </select>

                </div>
            </div>
            <br><br>

            <!-- DR handover signature -->
            <app-pce-signature v-model="value.handover_dr_signature" label="Patient handed over and received by:" ></app-pce-signature>

        </div>
        <div class="hl"></div>
        <div class="p-4">
            <!-- DR Feedback section -->
            <div v-if="dr_details_editable">
                <label class="input_label">How would you rate the service provided by Secure Care</label>
                <br><br>
                <div class="row justify-content-md-center" :key="value.feedback_score">
                    <div class="col-2">
                        <div class="feedback_face_container" :class="{'active':value.feedback_score===1}" @click="value.feedback_score=1">
                            <img src="assets/img/very-bad-face.png" class="feedback_face_icon">
                            <div class="feedback_face_description">Very bad</div>
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="feedback_face_container" :class="{'active':value.feedback_score===2}" @click="value.feedback_score=2">
                            <img src="assets/img/bad-face.png" class="feedback_face_icon">
                            <div class="feedback_face_description">Bad</div>
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="feedback_face_container" :class="{'active':value.feedback_score===3}" @click="value.feedback_score=3">
                            <img src="assets/img/ok-face.png" class="feedback_face_icon">
                            <div class="feedback_face_description">Ok</div>
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="feedback_face_container" :class="{'active':value.feedback_score===4}" @click="value.feedback_score=4">
                            <img src="assets/img/good-face.png" class="feedback_face_icon">
                            <div class="feedback_face_description">Good</div>
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="feedback_face_container" :class="{'active':value.feedback_score===5}" @click="value.feedback_score=5">
                            <img src="assets/img/excellent-face.png" class="feedback_face_icon">
                            <div class="feedback_face_description">Excellent</div>
                        </div>
                    </div>
                </div>
                <label class="input_label">Please provide any feedback on the quality of service provided by Secure Care</label>
                <textarea rows="4" class="form-control" v-model="value.qualitative_feedback" ></textarea>
                <br><br>
                <label class="input_label">Name of Feedback Provider</label>
                <input class="form-control" v-model="value.feedback_provider">
            </div>


            <!-- DR Sign off -->
            <br><br>
            <div class="font-weight-bold text-center">
                <p v-if="dr_details_editable">When you're ready, press done and hand the device back to your Secure Care crew</p>
                <br>
                <div class="btn btn-primary" style="width: 250px;" :class="{'disabled':!dr_handover_valid}" @click="dr_done" v-if="dr_details_editable">Done</div>
                <div class="btn btn-primary" style="width: 250px;" @click="in_dr_view=false" v-else>Close</div>
            </div>
            <br><br>
            <br><br>
        </div>
    </div>
    <div class="pce_card">
        <div class="pce_card_content">
            <label class="input_label">Visible injuries recorded by doctor or nurse at collection</label>
            <app-pce-image-map :block_edit="true" image="assets/img/patient_image_map.png" ref="patient_injuries_start_handover" component_name="patient_injuries_start_handover" set_mark_name="Injury" v-model="value.into_care_image_map"></app-pce-image-map>
            <br><br>
            <label class="input_label">Does the patient have new visible injuries as compared to pickup (shown above)?</label>
            <option-toggle v-model="value.any_new_visible_injuries" :toggle_options="['No','Yes']" @change="$root.queue_save_current_pce()" ></option-toggle>
            <app-pce-image-map  :block_edit="!dr_details_editable" class="mt-2" image="assets/img/patient_image_map.png" v-if="value.any_new_visible_injuries===1" ref="patient_injuries_handover" component_name="patient_injuries_handover" set_mark_name="Injury" v-model="value.patient_injuries_handover"></app-pce-image-map>
            <br><br>
            <app-pce-signature v-model="value.handover_crew_signature" label="Patient Handover. Handed over by Secure Care crew member:" @change="$root.queue_save_current_pce()"></app-pce-signature>
            <br><br>
            <div class="pce_card_content d-flex align-items-center justify-content-center">
                <div class="btn btn-primary text-uppercase" @click="get_details_from_dr()" style="width: 600px;">Show Nurse / Doctor handover</div>
            </div>
        </div>

        <div class="hl"></div>


    </div>

</div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    computed: {
        dr_handover_valid: function() {
            if (
                this.value.feedback_score===null ||
                this.value.feedback_score===0 ||
                this.value.feedback_provider===null ||
                this.value.dr_agree_with_injuries===null ||
                this.value.dr_agree_with_returned_property===null ||
                this.value.dr_agree_with_returned_meds===null ||
                this.value.handover_presentation==0 ||
                this.value.handover_restraint==0 ||
                this.value.handover_dr_signature===null
            ) {
                return false
            }

            if (this.value.dr_agree_with_returned_property===0 && this.value.dr_returned_property_notes === '') {
                return false
            }
            if (this.value.dr_agree_with_returned_meds===0 && this.value.dr_returned_meds_notes === '') {
                return false
            }

            return true
        },
    },
    methods: {
        dr_done: function () {
            if (this.dr_handover_valid) {
                this.in_dr_view= false
                this.$root.show_hand_back_device_popup = true;
                this.$root.queue_save_current_pce();
            }
        },
        get_details_from_dr: function() {
            window.scrollTo(0, 0);
            this.in_dr_view=true
            var parsed_dr_signature = JSON.parse(this.value.handover_dr_signature);
            if (parsed_dr_signature==null || parsed_dr_signature['date_signed'] == null) {
                this.dr_details_editable=true
            } else {
                this.dr_details_editable=false
            }
        }
    },
    data() {
        return {
            in_dr_view: false,
            dr_details_editable: true,
        }
    }
}
</script>

<style scoped>
.feedback_face_container {
    text-align: center;
    cursor: pointer;
}
#in_dr_view_overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 1001;
    overflow: auto;
}
.feedback_face_container {
    border-radius: 20px;
    padding: 10px;
    text-align: center;
    user-select: none;
}
.feedback_face_container.active {
    background-color: #67B9CE;
}

</style>
