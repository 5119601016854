var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.editing_note_item !== null
      ? _c("div", { staticClass: "popup_container" }, [
          _c("div", { staticClass: "close_popup" }),
          _vm._v(" "),
          _c("div", { staticClass: "popup_content_container" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "hl my-4" }),
            _vm._v(" "),
            _c("div", { staticClass: "px-4 pb-4" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.value.journey_notes[_vm.editing_note_item]["content"],
                    expression:
                      "value.journey_notes[editing_note_item]['content']"
                  }
                ],
                staticClass: "form-control",
                attrs: { rows: "4" },
                domProps: {
                  value:
                    _vm.value.journey_notes[_vm.editing_note_item]["content"]
                },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.value.journey_notes[_vm.editing_note_item],
                      "content",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("br"),
              _c("br"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "btn btn-block btn-primary",
                  on: {
                    click: function($event) {
                      _vm.editing_note_item = null
                      _vm.$root.queue_save_current_pce()
                    }
                  }
                },
                [_vm._v("Save")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "btn btn-block btn-danger",
                  on: {
                    click: function($event) {
                      _vm.value.journey_notes.splice(_vm.editing_note_item, 1)
                      _vm.editing_note_item = null
                      _vm.$root.queue_save_current_pce()
                    }
                  }
                },
                [_vm._v("Delete")]
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.confirm_step_change
      ? _c("div", { staticClass: "popup_container" }, [
          _c("div", { staticClass: "close_popup" }),
          _vm._v(" "),
          _c("div", { staticClass: "popup_content_container" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "hl my-4" }),
            _vm._v(" "),
            _vm.confirm_step_change_details["step_type"] ===
              "arrived_at_destination" ||
            _vm.confirm_step_change_details["step_type"] ===
              "arrived_at_collection"
              ? _c("div", [
                  _c("div", { staticClass: "px-4 pb-4" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.odometer,
                          expression: "odometer"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "number",
                        placeholder: "Enter vehicle mileage",
                        id: "odometer",
                        name: "odometer",
                        required: ""
                      },
                      domProps: { value: _vm.odometer },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.odometer = $event.target.value
                        }
                      }
                    })
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.confirm_step_change_details["step_type"] ===
            "appointment_start_out_of_care"
              ? _c(
                  "div",
                  { staticClass: "px-4 pb-4" },
                  [
                    _c("center", [
                      _c("h5", [
                        _vm._v(
                          "Is the patient is going to still be in SecureCare’s care?"
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "btn btn-block btn-primary",
                        on: {
                          click: function($event) {
                            _vm.should_take_obs_apt = true
                            _vm.confirm_step_change = false
                            _vm.start_step(
                              null,
                              _vm.confirm_step_change_details["step_type"],
                              _vm.confirm_step_change_details[
                                "journey_step_index"
                              ],
                              true
                            )
                          }
                        }
                      },
                      [_vm._v("YES")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "btn btn-block btn-primary",
                        on: {
                          click: function($event) {
                            _vm.should_take_obs_apt = false
                            _vm.confirm_step_change = false
                            _vm.start_step(
                              null,
                              _vm.confirm_step_change_details["step_type"],
                              _vm.confirm_step_change_details[
                                "journey_step_index"
                              ],
                              true
                            )
                          }
                        }
                      },
                      [_vm._v("NO")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "btn btn-block btn-outline-primary",
                        on: {
                          click: function($event) {
                            _vm.confirm_step_change = false
                          }
                        }
                      },
                      [_vm._v("CANCEL")]
                    )
                  ],
                  1
                )
              : _c("div", { staticClass: "px-4 pb-4" }, [
                  _vm.confirm_step_change_details["step_type"] ===
                    "arrived_at_destination" ||
                  _vm.confirm_step_change_details["step_type"] ===
                    "arrived_at_collection"
                    ? _c(
                        "div",
                        {
                          staticClass: "btn btn-block btn-primary",
                          on: {
                            click: function($event) {
                              _vm.confirm_step_change = false
                              _vm.set_reading(
                                _vm.confirm_step_change_details[
                                  "journey_step_index"
                                ]
                              )
                              _vm.start_step(
                                null,
                                _vm.confirm_step_change_details["step_type"],
                                _vm.confirm_step_change_details[
                                  "journey_step_index"
                                ],
                                true
                              )
                            }
                          }
                        },
                        [_vm._v("CHANGE JOB STATUS")]
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "btn btn-block btn-primary",
                          on: {
                            click: function($event) {
                              _vm.confirm_step_change = false
                              _vm.start_step(
                                null,
                                _vm.confirm_step_change_details["step_type"],
                                _vm.confirm_step_change_details[
                                  "journey_step_index"
                                ],
                                true
                              )
                            }
                          }
                        },
                        [_vm._v("CHANGE JOB STATUS")]
                      ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "btn btn-block btn-outline-primary",
                      on: {
                        click: function($event) {
                          _vm.confirm_step_change = false
                        }
                      }
                    },
                    [_vm._v("CANCEL")]
                  )
                ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        class: {
          jsb_danger:
            _vm.value.started_at === null &&
            _vm.value.crew_accepted_transfer_at !== null
        },
        attrs: { id: "journey_step_bar" },
        on: {
          click: function($event) {
            return _vm.toggle_bar()
          }
        }
      },
      [
        _c("div", { staticClass: "pl-3" }, [
          _c("div", { attrs: { id: "journey_step_bar_title" } }, [
            _vm._v(_vm._s(_vm.currently_doing_based_on_journey_step_label))
          ]),
          _vm._v(" "),
          _c("div", { attrs: { id: "journey_step_bar_crew" } }, [
            _vm._v("Crew: " + _vm._s(_vm.current_crew_string))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "float-right pr-4" }, [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "13.715",
                height: "12",
                viewBox: "0 0 13.715 12"
              }
            },
            [
              _c("path", {
                attrs: {
                  id: "play",
                  d:
                    "M11.368,5.782,1.939.208A1.279,1.279,0,0,0,0,1.314V12.46a1.285,1.285,0,0,0,1.939,1.106l9.429-5.572a1.284,1.284,0,0,0,0-2.213Z",
                  transform: "translate(13.747) rotate(90)",
                  fill: "#4d4d4d"
                }
              })
            ]
          )
        ])
      ]
    ),
    _vm._v(" "),
    _vm.$root.taking_pt_obs
      ? _c("div", { attrs: { id: "journey_observations_bar" } }, [
          _c("div", { staticClass: "row py-2" }, [
            _c("div", { staticClass: "col-10" }, [
              _c(
                "div",
                {
                  staticClass: "px-3 pb-2 border-bottom",
                  staticStyle: { "border-bottom-color": "#7ED1E5" }
                },
                [
                  _c("div", { staticClass: "observations_bar_title" }, [
                    _vm._v("Patient presentation")
                  ]),
                  _c("br"),
                  _vm._v(" "),
                  _c("div", { staticClass: "observations_bar_value" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$root.presentation_options[_vm.current_presentation]
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "observations_bar_value_details" }, [
                    _vm._v(
                      "for " + _vm._s(_vm.time_since_presentation_obs_changed)
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "px-3 pt-2" }, [
                _c("div", { staticClass: "observations_bar_title" }, [
                  _vm._v("Level of restraint")
                ]),
                _c("br"),
                _vm._v(" "),
                _c("div", { staticClass: "observations_bar_value" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$root.restraint_level_options[_vm.current_restraint]
                    )
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "observations_bar_value_details" }, [
                  _vm._v("for " + _vm._s(_vm.time_since_restraint_obs_changed))
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-2 d-flex align-items-center",
                on: {
                  click: function($event) {
                    _vm.pt_obs_expanded = true
                  }
                }
              },
              [
                _c("div", { staticClass: "btn btn-outline-light btn-sm" }, [
                  _vm._v("CHANGE")
                ])
              ]
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.pt_obs_expanded
      ? _c(
          "div",
          { attrs: { id: "journey_observations_popped_out_overlay" } },
          [
            _c(
              "div",
              { attrs: { id: "journey_observations_popped_out_container" } },
              [
                _c(
                  "div",
                  { staticClass: "journey_bar_popped_out_section" },
                  [
                    _c("div", { staticClass: "font-weight-bold" }, [
                      _vm._v("Patient presentation")
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.current_presentation,
                            expression: "current_presentation"
                          }
                        ],
                        staticClass: "form-control",
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.current_presentation = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function($event) {
                              return _vm.current_presentation_changed()
                            }
                          ]
                        }
                      },
                      _vm._l(_vm.$root.presentation_options, function(
                        presentation_label,
                        presentation_level_value
                      ) {
                        return _c(
                          "option",
                          { domProps: { value: presentation_level_value } },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(presentation_label)
                            )
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v(
                        "for " + _vm._s(_vm.time_since_presentation_obs_changed)
                      )
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c("div", { staticClass: "font-weight-bold" }, [
                      _vm._v("Level of restraint")
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.current_restraint,
                            expression: "current_restraint"
                          }
                        ],
                        staticClass: "form-control",
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.current_restraint = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function($event) {
                              return _vm.current_restraint_changed()
                            }
                          ]
                        }
                      },
                      _vm._l(_vm.$root.restraint_level_options, function(
                        restraint_label,
                        restraint_level_value
                      ) {
                        return _c(
                          "option",
                          { domProps: { value: restraint_level_value } },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(restraint_label)
                            )
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v(
                        "for " + _vm._s(_vm.time_since_restraint_obs_changed)
                      )
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c("div", { staticClass: "font-weight-bold" }, [
                      _vm._v("Patient transfer notes")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.value.journey_notes, function(
                      note_item,
                      note_item_index
                    ) {
                      return _c("div", { staticClass: "mb-1" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "border rounded p-2 d-flex note_item_container",
                            on: {
                              click: function($event) {
                                _vm.editing_note_item = note_item_index
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "note_item_time" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$root.date_obj_to_am_pm_time_string(
                                    _vm.$root.time_string_to_date(
                                      note_item["date_added"]
                                    )
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "text-truncate note_item_content"
                              },
                              [_vm._v(_vm._s(note_item["content"]))]
                            )
                          ]
                        )
                      ])
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        attrs: { id: "add_note_btn" },
                        on: {
                          click: function($event) {
                            return _vm.add_note()
                          }
                        }
                      },
                      [_vm._v("Add note")]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "btn btn-outline-primary btn-block",
                        on: {
                          click: function($event) {
                            _vm.pt_obs_expanded = false
                          }
                        }
                      },
                      [_vm._v("CLOSE")]
                    )
                  ],
                  2
                )
              ]
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.expanded
      ? _c("div", { attrs: { id: "journey_step_popped_out_overlay" } }, [
          _c("div", { attrs: { id: "journey_step_popped_out_container" } }, [
            _c(
              "div",
              { staticClass: "journey_bar_popped_out_section" },
              [
                _vm._l(_vm.value.journey_steps, function(
                  journey_step,
                  journey_step_index
                ) {
                  return _c("div", { key: journey_step.id }, [
                    journey_step.type === "collection"
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "journey_step_popped_out_step",
                              class: {
                                done:
                                  _vm.value.crew_accepted_transfer_at !== null
                              }
                            },
                            [
                              _vm._v(
                                "\n                                Crew acknowledge receipt of transfer. Not Started\n                                "
                              ),
                              _vm.value.crew_accepted_transfer_at !== null
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "journey_step_popped_out_step_time_box"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.value.crew_accepted_transfer_at.substring(
                                              0,
                                              10
                                            )
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.$root.date_obj_to_am_pm_time_string(
                                              _vm.$root.time_string_to_date(
                                                _vm.value
                                                  .crew_accepted_transfer_at
                                              )
                                            )
                                          ) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "journey_step_popped_out_step",
                              class: { done: _vm.value.started_at !== null }
                            },
                            [
                              _vm._v(
                                "\n                                Crew start transfer\n                                "
                              ),
                              _vm.value.started_at !== null
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "journey_step_popped_out_step_time_box"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.value.started_at.substring(
                                              0,
                                              10
                                            )
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.$root.date_obj_to_am_pm_time_string(
                                              _vm.$root.time_string_to_date(
                                                _vm.value.started_at
                                              )
                                            )
                                          ) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "journey_step_popped_out_step",
                              class: {
                                done: journey_step.started_at !== null,
                                clickable: _vm.is_step_clickable(
                                  "arrived_at_collection",
                                  journey_step_index
                                )
                              },
                              on: {
                                click: function($event) {
                                  return _vm.start_step(
                                    $event,
                                    "arrived_at_collection",
                                    journey_step_index
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                Arrived at collection location\n                                "
                              ),
                              journey_step.started_at !== null
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "journey_step_popped_out_step_time_box"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            journey_step.started_at.substring(
                                              0,
                                              10
                                            )
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.$root.date_obj_to_am_pm_time_string(
                                              _vm.$root.time_string_to_date(
                                                journey_step.started_at
                                              )
                                            )
                                          ) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              journey_step.quote_id
                                ? _c("div", [
                                    _c("hr"),
                                    _vm._v(
                                      "\n                                    Odometer Reading\n                                    "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "journey_step_popped_out_step_time_box"
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(journey_step.quote_id) +
                                            " Miles\n                                    "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "journey_step_popped_out_step not_user_inputted"
                            },
                            [
                              _vm._v(
                                "Collection - Doctor signs patient into our care"
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "journey_step_popped_out_step",
                              class: {
                                done: journey_step.ended_at !== null,
                                clickable: _vm.is_step_clickable(
                                  "departed_from_collection",
                                  journey_step_index
                                )
                              },
                              on: {
                                click: function($event) {
                                  return _vm.start_step(
                                    $event,
                                    "departed_from_collection",
                                    journey_step_index
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                Departed from collection location\n                                "
                              ),
                              journey_step.ended_at !== null
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "journey_step_popped_out_step_time_box"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            journey_step.ended_at.substring(
                                              0,
                                              10
                                            )
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.$root.date_obj_to_am_pm_time_string(
                                              _vm.$root.time_string_to_date(
                                                journey_step.ended_at
                                              )
                                            )
                                          ) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c("br")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    journey_step.type === "other_step"
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "journey_step_popped_out_step",
                              class: {
                                done: journey_step.started_at !== null,
                                clickable: _vm.is_step_clickable(
                                  "appointment_start",
                                  journey_step_index
                                )
                              },
                              on: {
                                click: function($event) {
                                  return _vm.start_step(
                                    $event,
                                    "appointment_start",
                                    journey_step_index
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                Arrived at " +
                                  _vm._s(journey_step.description) +
                                  "\n                                "
                              ),
                              journey_step.started_at !== null
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "journey_step_popped_out_step_time_box"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            journey_step.started_at.substring(
                                              0,
                                              10
                                            )
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.$root.date_obj_to_am_pm_time_string(
                                              _vm.$root.time_string_to_date(
                                                journey_step.started_at
                                              )
                                            )
                                          ) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              journey_step.quote_id
                                ? _c("div", [
                                    _c("hr"),
                                    _vm._v(
                                      "\n                                    Odometer Reading\n                                    "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "journey_step_popped_out_step_time_box"
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(journey_step.quote_id) +
                                            " Miles\n                                    "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "journey_step_popped_out_step",
                              class: {
                                done:
                                  journey_step.started_out_of_care_at !== null,
                                clickable: _vm.is_step_clickable(
                                  "appointment_start_out_of_care",
                                  journey_step_index
                                )
                              },
                              on: {
                                click: function($event) {
                                  return _vm.start_step(
                                    $event,
                                    "appointment_start_out_of_care",
                                    journey_step_index
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                Patient handed over at start of " +
                                  _vm._s(journey_step.description) +
                                  "\n                                "
                              ),
                              journey_step.started_out_of_care_at !== null
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "journey_step_popped_out_step_time_box"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            journey_step.started_out_of_care_at.substring(
                                              0,
                                              10
                                            )
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.$root.date_obj_to_am_pm_time_string(
                                              _vm.$root.time_string_to_date(
                                                journey_step.started_out_of_care_at
                                              )
                                            )
                                          ) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "journey_step_popped_out_step",
                              class: {
                                done:
                                  journey_step.ended_out_of_care_at !== null,
                                clickable: _vm.is_step_clickable(
                                  "appointment_end_out_of_care",
                                  journey_step_index
                                )
                              },
                              on: {
                                click: function($event) {
                                  return _vm.start_step(
                                    $event,
                                    "appointment_end_out_of_care",
                                    journey_step_index
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                Patient back into care at end of " +
                                  _vm._s(journey_step.description) +
                                  "\n                                "
                              ),
                              journey_step.ended_out_of_care_at !== null
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "journey_step_popped_out_step_time_box"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            journey_step.ended_out_of_care_at.substring(
                                              0,
                                              10
                                            )
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.$root.date_obj_to_am_pm_time_string(
                                              _vm.$root.time_string_to_date(
                                                journey_step.ended_out_of_care_at
                                              )
                                            )
                                          ) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "journey_step_popped_out_step",
                              class: {
                                done: journey_step.ended_at !== null,
                                clickable: _vm.is_step_clickable(
                                  "appointment_end",
                                  journey_step_index
                                )
                              },
                              on: {
                                click: function($event) {
                                  return _vm.start_step(
                                    $event,
                                    "appointment_end",
                                    journey_step_index
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                Departed from " +
                                  _vm._s(journey_step.description) +
                                  "\n                                "
                              ),
                              journey_step.ended_at !== null
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "journey_step_popped_out_step_time_box"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                     " +
                                          _vm._s(
                                            journey_step.ended_at.substring(
                                              0,
                                              10
                                            )
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.$root.date_obj_to_am_pm_time_string(
                                              _vm.$root.time_string_to_date(
                                                journey_step.ended_at
                                              )
                                            )
                                          ) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c("br")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    journey_step.type === "destination"
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "journey_step_popped_out_step",
                              class: {
                                done: journey_step.started_at !== null,
                                clickable: _vm.is_step_clickable(
                                  "arrived_at_destination",
                                  journey_step_index
                                )
                              },
                              on: {
                                click: function($event) {
                                  return _vm.start_step(
                                    $event,
                                    "arrived_at_destination",
                                    journey_step_index
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                Arrived at destination location\n                                "
                              ),
                              journey_step.started_at !== null
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "journey_step_popped_out_step_time_box"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            journey_step.started_at.substring(
                                              0,
                                              10
                                            )
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.$root.date_obj_to_am_pm_time_string(
                                              _vm.$root.time_string_to_date(
                                                journey_step.started_at
                                              )
                                            )
                                          ) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              journey_step.quote_id
                                ? _c("div", [
                                    _c("hr"),
                                    _vm._v(
                                      "\n                                    Odometer Reading\n                                    "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "journey_step_popped_out_step_time_box"
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(journey_step.quote_id) +
                                            " Miles\n                                    "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "journey_step_popped_out_step not_user_inputted"
                            },
                            [
                              _vm._v(
                                "Destination - Doctor signs patient out of our care"
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "journey_step_popped_out_step",
                              class: {
                                done: journey_step.ended_at !== null,
                                clickable: _vm.is_step_clickable(
                                  "crew_clear",
                                  journey_step_index
                                )
                              },
                              on: {
                                click: function($event) {
                                  return _vm.start_step(
                                    $event,
                                    "crew_clear",
                                    journey_step_index
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                Crew clear\n                                "
                              ),
                              journey_step.ended_at !== null
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "journey_step_popped_out_step_time_box"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                   " +
                                          _vm._s(
                                            journey_step.ended_at.substring(
                                              0,
                                              10
                                            )
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.$root.date_obj_to_am_pm_time_string(
                                              _vm.$root.time_string_to_date(
                                                journey_step.ended_at
                                              )
                                            )
                                          ) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c("br")
                        ])
                      : _vm._e()
                  ])
                }),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "journey_step_popped_out_step",
                    class: {
                      clickable: _vm.is_step_clickable("end_pce", null)
                    },
                    on: {
                      click: function($event) {
                        return _vm.start_step($event, "end_pce", null)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                        End PCE\n                    "
                    )
                  ]
                )
              ],
              2
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.$root.taking_pt_obs
      ? _c("div", { attrs: { id: "pt_obs_spacer" } })
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center pt-4" }, [
      _c("div", { staticClass: "popup_title" }, [_vm._v("Edit Note")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center pt-4" }, [
      _c("div", { staticClass: "popup_title" }, [_vm._v("Change Job Status")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }