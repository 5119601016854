<template>
    <div>
        <input class="form-control" autocomplete="off" type="search" :value="value" @focus="on_focus()" :name="name">

        <div class="time_picker_popout" v-if="active">
            <div v-if="picker_type=='hour'">
                <div class="time_picker_row">
                    <div class="time_picker_time" @click="set_hour('0')">12:00 AM</div>
                    <div class="time_picker_time" @click="set_hour('1')">01:00 AM</div>
                    <div class="time_picker_time" @click="set_hour('2')">02:00 AM</div>
                    <div class="time_picker_time" @click="set_hour('3')">03:00 AM</div>
                </div>
                <div class="time_picker_row">
                    <div class="time_picker_time" @click="set_hour('4')">04:00 AM</div>
                    <div class="time_picker_time" @click="set_hour('5')">05:00 AM</div>
                    <div class="time_picker_time" @click="set_hour('6')">06:00 AM</div>
                    <div class="time_picker_time" @click="set_hour('7')">07:00 AM</div>
                </div>
                <div class="time_picker_row">
                    <div class="time_picker_time" @click="set_hour('8')">08:00 AM</div>
                    <div class="time_picker_time" @click="set_hour('9')">09:00 AM</div>
                    <div class="time_picker_time" @click="set_hour('10')">10:00 AM</div>
                    <div class="time_picker_time" @click="set_hour('11')">11:00 AM</div>
                </div>
                <div class="time_picker_row">
                    <div class="time_picker_time" @click="set_hour('12')">12:00 PM</div>
                    <div class="time_picker_time" @click="set_hour('13')">01:00 PM</div>
                    <div class="time_picker_time" @click="set_hour('14')">02:00 PM</div>
                    <div class="time_picker_time" @click="set_hour('15')">03:00 PM</div>
                </div>
                <div class="time_picker_row">
                    <div class="time_picker_time" @click="set_hour('16')">04:00 PM</div>
                    <div class="time_picker_time" @click="set_hour('17')">05:00 PM</div>
                    <div class="time_picker_time" @click="set_hour('18')">06:00 PM</div>
                    <div class="time_picker_time" @click="set_hour('19')">07:00 PM</div>
                </div>
                <div class="time_picker_row">
                    <div class="time_picker_time" @click="set_hour('20')">08:00 PM</div>
                    <div class="time_picker_time" @click="set_hour('21')">09:00 PM</div>
                    <div class="time_picker_time" @click="set_hour('22')">10:00 PM</div>
                    <div class="time_picker_time" @click="set_hour('23')">11:00 PM</div>
                </div>
            </div>
            <div v-if="picker_type=='time'">
                <div class="time_picker_row">
                    <div class="time_picker_time" @click="set_mins('0')">{{format_time(selected_hour, 0)}}</div>
                    <div class="time_picker_time" @click="set_mins('5')">{{format_time(selected_hour, 5)}}</div>
                    <div class="time_picker_time" @click="set_mins('10')">{{format_time(selected_hour, 10)}}</div>
                    <div class="time_picker_time" @click="set_mins('15')">{{format_time(selected_hour, 15)}}</div>
                </div>
                <div class="time_picker_row">
                    <div class="time_picker_time" @click="set_mins('20')">{{format_time(selected_hour, 20)}}</div>
                    <div class="time_picker_time" @click="set_mins('25')">{{format_time(selected_hour, 25)}}</div>
                    <div class="time_picker_time" @click="set_mins('30')">{{format_time(selected_hour, 30)}}</div>
                    <div class="time_picker_time" @click="set_mins('35')">{{format_time(selected_hour, 35)}}</div>
                </div>
                <div class="time_picker_row">
                    <div class="time_picker_time" @click="set_mins('40')">{{format_time(selected_hour, 40)}}</div>
                    <div class="time_picker_time" @click="set_mins('45')">{{format_time(selected_hour, 45)}}</div>
                    <div class="time_picker_time" @click="set_mins('50')">{{format_time(selected_hour, 50)}}</div>
                    <div class="time_picker_time" @click="set_mins('55')">{{format_time(selected_hour, 55)}}</div>
                </div>
            </div>

        </div>
    </div>
</template>


<script>
export default {
    props: {
        value: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        }
    },
    mounted() {
    },
    data() {
        return {
            selected_hour: null,
            selected_mins: null,
            formatted_hour: null,
            picker_type: 'hour',
            active: false,
        }
    },
    methods: {
        on_focus: function () {
            this.active = true;
            this.picker_type = 'hour';
        },
        set_hour: function (hour) {
            this.selected_hour = hour;
            this.picker_type = 'time';
        },
        set_mins: function (mins) {
            this.selected_mins = mins
            this.$emit('input', this.format_time(this.selected_hour, this.selected_mins));
            this.active = false;
        },
        format_time: function (hour, min) {
            var am_pm = 'AM'
            var formatted_hour = hour;
            if (hour>=12) {
                if (hour>12) {
                    formatted_hour = hour-12;
                }
                am_pm = 'PM'
            } else if (hour==0) {
                formatted_hour = 12;
            }
            return String(formatted_hour).padStart(2,'0')+':'+String(min).padStart(2,'0')+' '+am_pm
        }
    }
}
</script>

<style scoped>
.time_picker_popout {
    position: absolute;
    z-index: 10;
    font-size: 0;
    width: 320px;
    height: 100px;
    background-color: white;
    border: 1px solid #F1F1F2;
    display: table;
    border-collapse:collapse;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 35%);
    border-radius: 4px;
}
.time_picker_row {
    display:table-row;
}
.time_picker_time {
    font-size: 12.6px;
    width: 80px;
    text-align: center;
    padding: 10px;
    border: 1px solid #F5F5F5;
    display: table-cell;
    cursor: pointer;
    outline: 0px solid white;

    transition: outline 0.3s ease;
}
.time_picker_time:hover {
    outline: 1px solid #A0C6E0;
}
</style>
