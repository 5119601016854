var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "cva_index" } }, [
    _c("div", { attrs: { id: "appHomeBanner" } }, [
      _c("div", { attrs: { id: "appBannerOverlay" } }, [
        _c("div", { attrs: { id: "home_image_overlay" } }),
        _vm._v(" "),
        _c("div", { attrs: { id: "home_color_overlay" } }, [
          _vm.$root.currentUser !== null
            ? _c("h1", { attrs: { id: "homepage_title" } }, [
                _vm._v(
                  "Hello " + _vm._s(_vm.$root.currentUser.name.split(" ")[0])
                )
              ])
            : _vm._e()
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { id: "pcr_list" } },
      [
        _c("br"),
        _c("br"),
        _vm._v(" "),
        _vm.$root.listCvasForCurrentUser.length
          ? _c("h2", { staticStyle: { margin: "20px" } }, [
              _vm._v("Here are your current Crew Vehicle Assessments")
            ])
          : _c("h2", { staticStyle: { margin: "20px" } }, [
              _vm._v("You have no Crew Vehicle Assessments yet")
            ]),
        _vm._v(" "),
        _vm.$root.message.pre
          ? _c(
              "div",
              {
                staticClass: "btn btn-success",
                staticStyle: { width: "300px", "margin-left": "50px" }
              },
              [_vm._v(_vm._s(_vm.$root.message.pre))]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.$root.message.post
          ? _c(
              "div",
              {
                staticClass: "btn btn-success",
                staticStyle: { width: "300px", "margin-left": "50px" }
              },
              [_vm._v(_vm._s(_vm.$root.message.post))]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.$root.listCvasForCurrentUser, function(cva) {
          return _c(
            "div",
            {
              staticClass: "pcr_list_item",
              on: {
                click: function($event) {
                  return _vm.open_cva(cva)
                }
              }
            },
            [
              _c("div", { staticClass: "pcr_list_item_body" }, [
                _c("div", { staticClass: "pcr_list_item_title" }, [
                  _vm._v(_vm._s(cva.name))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pcr_list_item_tag" }, [
                  _vm._v("CVA")
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "m-0" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "13.333",
                        height: "13.333",
                        viewBox: "0 0 13.333 13.333"
                      }
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "Group_1826",
                            "data-name": "Group 1826",
                            transform: "translate(-95.333 -536.333)"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              id: "Path_862",
                              "data-name": "Path 862",
                              d: "M12,7H11v4l3.5,2.1.5-.82L12,10.5Z",
                              transform: "translate(90.333 532.667)",
                              fill: "#9d9d9d"
                            }
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              id: "Path_863",
                              "data-name": "Path 863",
                              d:
                                "M8.66,2a6.667,6.667,0,1,0,6.673,6.667A6.663,6.663,0,0,0,8.66,2Zm.007,12A5.333,5.333,0,1,1,14,8.667,5.332,5.332,0,0,1,8.667,14Z",
                              transform: "translate(93.333 534.333)",
                              fill: "#9d9d9d"
                            }
                          })
                        ]
                      )
                    ]
                  ),
                  _vm._v(
                    "\n\n                    " +
                      _vm._s(cva.start_date.substring(0, 10)) +
                      " " +
                      _vm._s(
                        _vm.$root.date_obj_to_am_pm_time_string(
                          _vm.$root.time_string_to_date(cva.start_date)
                        )
                      ) +
                      " - " +
                      _vm._s(
                        _vm.$root.date_obj_to_am_pm_time_string(
                          _vm.$root.time_string_to_date(cva.end_date)
                        )
                      ) +
                      "\n\n                "
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "m-0" }, [
                  _c(
                    "span",
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "mr-1",
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            width: "12",
                            height: "10.667",
                            viewBox: "0 0 12 10.667"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              id: "Path_801",
                              "data-name": "Path 801",
                              d:
                                "M13.613,5.673A1,1,0,0,0,12.667,5H5.333a1,1,0,0,0-.947.673L3,9.667V15a.669.669,0,0,0,.667.667h.667A.669.669,0,0,0,5,15v-.667h8V15a.669.669,0,0,0,.667.667h.667A.669.669,0,0,0,15,15V9.667Zm-8.28,6.66a1,1,0,1,1,1-1A1,1,0,0,1,5.333,12.333Zm7.333,0a1,1,0,1,1,1-1A1,1,0,0,1,12.667,12.333ZM4.333,9l1-3h7.333l1,3Z",
                              transform: "translate(-3 -5)",
                              fill: "#9d9d9d"
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(cva.vehicles, function(
                        vehicle,
                        vehicle_array_index
                      ) {
                        return _c("span", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(vehicle.name) +
                              " "
                          ),
                          vehicle_array_index !== cva.vehicles.length - 1
                            ? _c("span", [_vm._v(", ")])
                            : _vm._e()
                        ])
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "pl-5" },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "mr-1",
                          attrs: {
                            "data-name": "Group 1187",
                            xmlns: "http://www.w3.org/2000/svg",
                            width: "14.667",
                            height: "10.667",
                            viewBox: "0 0 14.667 10.667"
                          }
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "Group_1186",
                                "data-name": "Group 1186"
                              }
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "Group_1182",
                                    "data-name": "Group 1182",
                                    transform: "translate(10.447 6.087)"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      id: "Path_802",
                                      "data-name": "Path 802",
                                      d:
                                        "M16.67,13.13a3.1,3.1,0,0,1,1.553,2.58v2H20.89v-2C20.89,14.257,18.51,13.4,16.67,13.13Z",
                                      transform: "translate(-16.67 -13.13)",
                                      fill: "#9d9d9d",
                                      "fill-rule": "evenodd"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "Group_1183",
                                    "data-name": "Group 1183",
                                    transform: "translate(2.667)"
                                  }
                                },
                                [
                                  _c("circle", {
                                    attrs: {
                                      id: "Ellipse_23",
                                      "data-name": "Ellipse 23",
                                      cx: "2.667",
                                      cy: "2.667",
                                      r: "2.667",
                                      fill: "#9d9d9d"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "Group_1184",
                                    "data-name": "Group 1184",
                                    transform: "translate(8.447)"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      id: "Path_803",
                                      "data-name": "Path 803",
                                      d:
                                        "M14.557,9.333a2.667,2.667,0,0,0,0-5.333,2.786,2.786,0,0,0-.887.16,3.987,3.987,0,0,1,0,5.013A2.786,2.786,0,0,0,14.557,9.333Z",
                                      transform: "translate(-13.67 -4)",
                                      fill: "#9d9d9d",
                                      "fill-rule": "evenodd"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "Group_1185",
                                    "data-name": "Group 1185",
                                    transform: "translate(0 6)"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      id: "Path_804",
                                      "data-name": "Path 804",
                                      d:
                                        "M6.333,13C4.553,13,1,13.893,1,15.667v2H11.667v-2C11.667,13.893,8.113,13,6.333,13Z",
                                      transform: "translate(-1 -13)",
                                      fill: "#9d9d9d",
                                      "fill-rule": "evenodd"
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(cva.crew_members, function(
                        crew_member,
                        crew_member_id,
                        crew_members_array_index
                      ) {
                        return _c("span", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                crew_member
                                  .split(" ")
                                  .map(function(x) {
                                    return x.charAt(0)
                                  })
                                  .join(".")
                                  .toUpperCase()
                              ) +
                              " "
                          ),
                          crew_members_array_index !==
                          Object.keys(cva.crew_members).length - 1
                            ? _c("span", [_vm._v(", ")])
                            : _vm._e()
                        ])
                      })
                    ],
                    2
                  )
                ])
              ]),
              _vm._v(" "),
              _vm._m(0, true)
            ]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "pcr_list_item_status justify-content-end" },
      [
        _c("div", { staticClass: "btn btn-primary btn-sm" }, [
          _vm._v("Start CVA")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }