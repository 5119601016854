<template>

    <div class="image_map_box_container">

        <div id="image_map_debug"></div>

        <div class="image_map_edit_topbar" v-if="show_mark_edit_bar" :style="{'top':$root.topbar_height_in_px+'px'}">
            <div class="image_map_edit_contents">
                <div class="float-left">
                    <div class="image_map_edit_mark_name">{{image_map_box_markup_positions[mark_edit_bar_current_mark_id]['title']}}</div>
                    <div class="image_map_edit_mark_notes">{{image_map_box_markup_positions[mark_edit_bar_current_mark_id]['notes']}}</div>
                </div>
                <div class="image_map_edit_mark_open_edit_popup" @click="open_edit_popup()">EDIT</div>
            </div>
        </div>

        <div class="image_map_edit_topbar_close_overlay" v-if="show_mark_edit_bar" @click="hideMarkEditBar()">

        </div>

        <div class="image_map_user_input_popup" v-if="show_user_input_screen">
            <div class="image_map_user_input_popup_contents">
                <div class="image_map_user_input_popup_title"><span v-if="user_input_type!='editing'">Add {{user_input_mark_name}}</span><span v-if="user_input_type=='editing'">Edit {{user_input_mark_name}}</span></div>
                <div class="text-danger text-center mt-4" v-if="user_input_errors!==''">{{user_input_errors}}</div>
                <div class="image_map_user_input_popup_body">
                    <div class="form-group pb-2">
                        <label>{{user_input_mark_name}}</label>
                        <input type="search" autocomplete="off" class="form-control" v-model="user_input_name">
                    </div>
                    <div class="form-group pb-2">
                        <label>{{user_input_mark_name}} Notes</label>
                        <textarea type="search" autocomplete="off" class="form-control" rows="4" v-model="user_input_notes"></textarea>
                    </div>

                    <div class="btn btn-primary btn-block font-weight-bold" @click="sendUserInput()">
                        <span v-if="user_input_type!='editing'">ADD</span>
                        <span v-if="user_input_type=='editing'">SAVE</span>
                    </div>

                    <div v-if="user_input_type=='editing'">
                        <br>
                        <div class="btn btn-danger btn-block font-weight-bold" @click="userInputRemoveMark()">Delete</div>
                    </div>
                    <br>
                    <div class="btn light btn-block" @click="exitUserInputScreen()">Cancel</div>

                </div>
            </div>
        </div>

        <div class="no_edit_image_map">
            <div class="image_map_box" :style="{'background-image':'url(&quot;'+background_img_url+'&quot;)' }" :id="'image_map_box'+component_ref" @mouseleave="stopDragging()" @touchleave="stopDragging()" @touchmove="onMouseMove" @mousemove="onMouseMove" @click="makeNewMark($event)"  :ref="component_ref+'InsideComponent'">
                <div class="image_map_box_markup" :id="'image_map_box_markup'+component_ref"></div>
            </div>

            <br>
            <div v-if="block_edit">
                <div class="hl"></div>
                <div v-for="mark in image_map_box_markup_positions">
                    <p class="bold mb-0" style="font-size: 18px; font-weight: bold;">{{mark.title}}</p>
                    <p class="text-muted">{{mark.notes}}</p>
                </div>
            </div>
        </div>

    </div>

</template>


<style>
    .no_edit_image_map {
        background-color: white;
    }
    .image_map_edit_topbar_close_overlay {
        position: fixed;
        background-color: rgba(0, 0, 0, 0.11);
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0;
        z-index: 74;
    }
    .image_map_edit_mark_open_edit_popup {
        float: right;
        color: white;
        font-size: 16px;
        font-weight: bold;
        border: 2px solid white;
        padding: 5px 10px;
        border-radius: 10px;
        width: 100px;
        text-align: center;
        cursor: pointer;
    }
    .image_map_edit_mark_name {
        color: white;
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
    }
    .image_map_edit_mark_notes {
        color: white;
        font-size: 16px;
        line-height: 16px;
    }
    .image_map_edit_contents {
        width: 100%;
        max-width: 700px;
        padding: 10px 48px;
        margin: auto;
    }
    .image_map_edit_topbar {
        position: fixed;
        width: 100%;
        height: 60px;
        top: 0;
        left: 0;
        background-color: #5A5A5A;
        z-index: 75;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.40);
    }

    .image_map_box_container {
        width: 50%;
        height: 100%;
        display: inline-flex;
        justify-content: center;
    }
    .image_map_box {
        width: 300px;
        height: 300px;
        background-color: white;
        background-size: contain;
        background-repeat: no-repeat;
        user-select: none;
    }

    .image_map_box_markup {
        width: 100%;
        height: 100%;
    }

    .image_map_box_markup_point {
        position: absolute;
        box-sizing: content-box;
    }

    .image_map_box_markup_point_hitbox {
        background-color: red;
        border-radius: 50%;
        height: 100%;
        width: 100%;
        padding: 1px;
    }

    .image_map_box_markup_point_dot {
        position: relative;
        border-radius: 50%;
        display: block;
        background-color: red;
        margin: 15%;
        box-sizing: border-box;
        border: 0px white solid;
        width: 70%;
        height: 70%;
        cursor: pointer;
    }
    .image_map_box_markup_point_text {
        position: absolute;
        color: red;
        background-image: url('assets/img/image_map_text_connector_line.png');
        background-repeat: no-repeat;
        background-position: bottom left;
        box-sizing: content-box;
    }
    .image_map_box_markup_point_text_contents {
        border-bottom: 2px solid red;
        margin-left: 1px;
        font-weight: bold;
    }
    .image_map_box_markup_point_text.x_direction_left {
        transform: scaleX(-1);
    }
    .image_map_box_markup_point_text.x_direction_left .image_map_box_markup_point_text_contents {
        transform: scaleX(-1);
    }
    .moving {
        opacity: 0.1;
    }

</style>

<script>
    import html2canvas from "html2canvas";

    var component_ref = null;

    export default {
        props: {
            'value': String,
            'set_mark_name':String,
            'component_name':String,
            "image":String,
            'no_offset':Boolean,
            'block_edit':Boolean,
            'use_position': Boolean
        },
        name: "ImageMapComponent",
        data: function () {
            return {
                'user_input_type':'normal',
                'background_img_url':'',
                'mark_edit_bar_current_mark_id':0,
                'show_mark_edit_bar':false,
                'start_drag_time':0,
                'end_drag_time':0,
                'mark_name':'',
                'user_input_submit_callback':undefined,
                'user_input_mark_name':'',
                'user_input_errors':'',
                'user_input_name':'',
                'user_input_notes':'',
                'show_user_input_screen': false,
                'component_ref':'',
                'image_map_box_markup_positions': {},
                'map_width':null,
                'map_height':null,
                'width_ratio':null,
                'height_ratio':null,
                'marker_height':null,
                'marker_width':null,
                'marker_hitbox_size':null,
                'currtlyDraggingMarker':null,
                'dragOffSetX':0,
                'dragOffSetY':0,
                'imageMapOffset':0,
                'current_new_mark_mouse_pos': {},
            }
        },
        methods: {
            'userInputRemoveMark': function () {
                this.hideMarkEditBar();
                this.image_map_box_markup_positions.splice(this.mark_edit_bar_current_mark_id, 1);
                this.mark_edit_bar_current_mark_id = null;
                this.exitUserInputScreen();
                this.resize();
                this.save_markers();
            },
            'open_edit_popup':function () {
                this.user_input_type = 'editing';
                this.getUserInput(this.mark_name,{
                    'default_name':this.image_map_box_markup_positions[this.mark_edit_bar_current_mark_id]['title'],
                    'default_notes':this.image_map_box_markup_positions[this.mark_edit_bar_current_mark_id]['notes'],
                    callback:function(res) {
                        this.image_map_box_markup_positions[this.mark_edit_bar_current_mark_id]['title'] = res.user_input_name;
                        this.image_map_box_markup_positions[this.mark_edit_bar_current_mark_id]['notes'] = res.user_input_notes;
                        this.save_markers();
                    }.bind(this)
                });
            },
            'showMarkEditBar': function (mark_id) {
              this.show_mark_edit_bar = true;
              this.mark_edit_bar_current_mark_id = mark_id;
            },
            'hideMarkEditBar': function () {
                this.show_mark_edit_bar = false;
            },
            'getUserInput': function (item_name,options) {
                //this.getUserInput('Seat',{'default_name':'Mark and Jen','default_notes':'No kids',callback:function(res) { //Do something amazing! }})
                this.user_input_mark_name = item_name;
                if (options['default_name']===undefined) {
                    this.user_input_name= '';
                } else {
                    this.user_input_name = options['default_name'];
                }
                if (options['default_notes']===undefined) {
                    this.user_input_notes = '';
                } else {
                    this.user_input_notes = options['default_notes'];
                }
                this.show_user_input_screen = true;
                this.user_input_submit_callback = options['callback'];
                $('html').addClass('noscroll');
            },
            'sendUserInput': function () {
                if (this.user_input_name==='') {
                    this.user_input_errors = 'Please enter a '+this.user_input_mark_name;
                } else {
                    if (this.user_input_submit_callback!==undefined) {
                        this.user_input_submit_callback({
                            'user_input_name':this.user_input_name,
                            'user_input_notes':this.user_input_notes,
                        });
                    }
                    this.exitUserInputScreen();
                }
            },
            'exitUserInputScreen': function () {
                this.user_input_name = '';
                this.user_input_submit_callback = undefined;
                this.user_input_notes = '';
                this.user_input_errors = '';
                this.show_user_input_screen = false;
                $('html').removeClass('noscroll');
                this.user_input_type = 'normal';
            },
            'renderImageMapPositions': function () {
                this.imageMapOffset = $('#image_map_box'+this.component_ref).offset();
                if (this.no_offset) {
                    this.imageMapOffset = {'left':17,'top':17}
                }
                if (this.use_position) {
                    this.imageMapOffset = $('#image_map_box'+this.component_ref).position();
                }
                this.marker_width = 17 * this.width_ratio;
                this.marker_height = 17 * this.height_ratio;
                this.marker_hitbox_size = 10 * this.height_ratio;

                $('#image_map_box_markup'+this.component_ref).html('');
                this.image_map_box_markup_positions.forEach(function(marker, marker_id) {
                    var top = (((marker.top / 300) * this.map_height)) + this.imageMapOffset.top - (this.marker_height / 2) - this.marker_hitbox_size;
                    var left = (((marker.left / 300) * this.map_width)) + this.imageMapOffset.left - (this.marker_width / 2) - this.marker_hitbox_size;
                    if((this.$root.page == 'pre_cva') ||  (this.$root.page == 'post_cva')) {
                        top = this.imageMapOffset.top + marker.top - 300;
                        left = this.imageMapOffset.left + marker.left - 16;
                    }
                    var text_top = top + this.marker_hitbox_size + (this.marker_height / 2);
                    var text_left = left + this.marker_hitbox_size + (this.marker_width / 2);
                    this.showTextMarker(marker_id, text_top, text_left);
                    $('#image_map_box_markup'+this.component_ref).append('<div class="image_map_box_markup_point image_map_box_markup_point'+this.component_ref+'" style="left: ' + left + 'px; top: ' + top + 'px;" marker_id="' + marker_id + '"><div class="image_map_box_markup_point_hitbox"><div class="image_map_box_markup_point_dot image_map_box_markup_point_dot'+this.component_ref+'"></div></div></div></div>');
                }.bind(this));

                $('.image_map_box_markup_point'+this.component_ref).width(this.marker_width);
                $('.image_map_box_markup_point'+this.component_ref).height(this.marker_height);
                $('.image_map_box_markup_point'+this.component_ref).css('padding', this.marker_hitbox_size + 'px');
            },
            'showTextMarker': function (markup_id, top, left) {
                var textSectionWidth = ((8 * this.height_ratio)*this.image_map_box_markup_positions[markup_id]['title'].length)+(10 * this.height_ratio);
                var x_direction;
                if ((left-this.imageMapOffset.left) <= (this.map_width / 2)) {
                    x_direction = 'left';
                } else {
                    x_direction = 'right';
                }
                if ((left-this.imageMapOffset.left)<=textSectionWidth) {
                    x_direction = 'right';
                }
                if ((left-this.imageMapOffset.left)>=(this.map_width-textSectionWidth)) {
                    x_direction = 'left';
                }
                var jQ_text_markup_id = '#mackup_text_' + markup_id;
                var classesToAdd = '';
                if (x_direction === 'left') {
                    classesToAdd += ' x_direction_left x_direction_left'+this.component_ref;
                }
                $('#image_map_box_markup'+this.component_ref).append('<div id="mackup_text_' + markup_id + '" class="image_map_box_markup_point_text image_map_box_markup_point_text'+ this.component_ref + classesToAdd + '" left="' + left + '" style="height: ' + (12 * this.height_ratio) + 'px; left: ' + left + 'px; top: ' + (top - (40 * this.height_ratio)) + 'px;" markup_id="'+markup_id+'"><div class="image_map_box_markup_point_text_contents image_map_box_markup_point_text_contents'+this.component_ref+'">' + this.image_map_box_markup_positions[markup_id]['title'] + '</div></div>')
            },
            'resize': function() {
                console.log(this.image_map_box_markup_positions.top);
                console.log(this.image_map_box_markup_positions.left);
                console.log('image map box markup positions '+this.image_map_box_markup_positions);


                this.map_width = $('#image_map_box'+this.component_ref).width();
                this.map_height = $('#image_map_box'+this.component_ref).height();
                if (this.map_height>this.map_width) {
                    this.map_height = this.map_width;
                    $('#image_map_box'+this.component_ref).height(this.map_width);
                } else if (this.map_width>this.map_height) {
                    this.map_width = this.map_height;
                    $('#image_map_box'+this.component_ref).width(this.map_width);
                }

                this.imageMapOffset = $('#image_map_box'+this.component_ref).offset();
                this.width_ratio = this.map_width / 300;
                this.height_ratio = this.map_height / 300;
                this.renderImageMapPositions();
                $('.image_map_box_markup_point_text_contents'+this.component_ref).css('font-size', (16 * this.height_ratio) + 'px');
                $('.image_map_box_markup_point_text_contents'+this.component_ref).css('line-height', (18.4 * this.height_ratio) + 'px');
                $('.image_map_box_markup_point_text'+this.component_ref).css('background-size', (8 * this.height_ratio) + 'px ' + (20 * this.height_ratio) + 'px');
                $('.image_map_box_markup_point_text'+this.component_ref).css('padding-bottom', (26 * this.height_ratio) + 'px');
                $('.image_map_box_markup_point_text'+this.component_ref).css('padding-left', (5 * this.height_ratio) + 'px');
                $('.image_map_box_markup_point_text_contents'+this.component_ref).css('padding', '0px ' + (5 * this.height_ratio) + 'px');
                $('.image_map_box_markup_point_dot'+this.component_ref).css('border-width', (2 * this.height_ratio) + 'px');

                $('.x_direction_left'+this.component_ref).each(function(index,obj) {
                    $(obj).css('left', ($(obj).attr('left') - $(obj).width() - (3 * this.width_ratio)) + 'px')
                }.bind(this));
            },
            'stopDragging':function() {
                if (this.currtlyDraggingMarker!==null) {
                    var current_markup_id = $(this.currtlyDraggingMarker).attr('marker_id');
                    this.end_drag_time = Date.now();
                    if ((this.end_drag_time-this.start_drag_time)<=90) {
                        setTimeout(function() {
                            this.showMarkEditBar(current_markup_id);
                        }.bind(this),50);
                    } else {
                    }
                    var current_marker_offset = $(this.currtlyDraggingMarker).offset();
                    this.image_map_box_markup_positions[current_markup_id]['top'] = ((current_marker_offset.top-this.imageMapOffset.top+(this.marker_height/2) +this.marker_hitbox_size)/this.map_height)*300;
                    this.image_map_box_markup_positions[current_markup_id]['left'] = (current_marker_offset.left-this.imageMapOffset.left+(this.marker_width/2)+this.marker_hitbox_size)/this.map_width*300;
                    this.currtlyDraggingMarker = null;
                    this.save_markers();
                }
            },
            'startDragging':function(e,elm) {
                if (!this.block_edit) {
                    var x, y;
                    if (e.type == 'touchstart' || e.type == 'touchmove' || e.type == 'touchend' || e.type == 'touchcancel') {
                        var touch = e.touches[0] || e.changedTouches[0];
                        x = touch.pageX;
                        y = touch.pageY;
                    } else if (e.type == 'mousedown' || e.type == 'mouseup' || e.type == 'mousemove' || e.type == 'mouseover' || e.type == 'mouseout' || e.type == 'mouseenter' || e.type == 'mouseleave') {
                        x = e.pageX;
                        y = e.pageY;
                    }
                    this.start_drag_time = Date.now();
                    var offset = $(elm).offset();
                    this.dragOffSetX = x - offset.left + (((17 * this.width_ratio)) / 2) - this.marker_hitbox_size;
                    this.dragOffSetY = y - offset.top + (((17 * this.height_ratio)) / 2) - this.marker_hitbox_size;
                    $("#mackup_text_" + $(elm).attr('marker_id')).addClass('moving');
                    this.currtlyDraggingMarker = elm;
                }
            },
            'onMouseMove':function (e) {
                var x,y;
                if(e.type == 'touchstart' || e.type == 'touchmove' || e.type == 'touchend' || e.type == 'touchcancel') {
                    var touch = e.touches[0] || e.changedTouches[0];
                    x = touch.pageX;
                    y = touch.pageY;
                } else if (e.type == 'mousedown' || e.type == 'mouseup' || e.type == 'mousemove' || e.type == 'mouseover'|| e.type=='mouseout' || e.type=='mouseenter' || e.type=='mouseleave') {
                    x = e.pageX;
                    y = e.pageY;
                }
                if (this.currtlyDraggingMarker !== null) {
                    var topPos = (y - this.dragOffSetY);
                    var leftPos = (x - this.dragOffSetX);
                    if (leftPos<=(this.imageMapOffset.left)) {
                        leftPos = this.imageMapOffset.left;
                    } else if (leftPos>=(this.map_width+this.imageMapOffset.left-this.marker_width-this.marker_hitbox_size)) {
                        leftPos = this.map_width+this.imageMapOffset.left-this.marker_width-this.marker_hitbox_size;
                    }
                    if (topPos<=(this.imageMapOffset.top)) {
                        topPos = this.imageMapOffset.top;
                    } else if (topPos>=(this.map_height+this.imageMapOffset.top-this.marker_height-this.marker_hitbox_size)) {
                        topPos = this.map_height+this.imageMapOffset.top-this.marker_height-this.marker_hitbox_size;
                    }
                    $(this.currtlyDraggingMarker).css('top', topPos + 'px');
                    $(this.currtlyDraggingMarker).css('left', leftPos + 'px');
                    if (this.show_mark_edit_bar) {
                        this.hideMarkEditBar();
                    }
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                }
            },
            'app_ready': function () {
                setTimeout(function () {
                    if (this.set_mark_name == null) {
                        this.mark_name = 'Mark';
                    } else {
                        this.mark_name = this.set_mark_name;
                    }

                    if (this.value == null || this.value === '') {
                        this.image_map_box_markup_positions = [];
                    } else {
                        this.image_map_box_markup_positions = JSON.parse(this.value);
                    }

                    this.background_img_url = this.image;
                    this.resize();
                }.bind(this),500)

            },
            'save_markers': function () {
                this.$emit('input', JSON.stringify(this.image_map_box_markup_positions));
                this.resize(); //Now lets re render everything
            },
            'makeNewMark': function (event) {
                if (!this.block_edit) {
                    this.resize();
                    if (this.currtlyDraggingMarker === null) {
                        if (this.show_mark_edit_bar) {
                            this.hideMarkEditBar();
                        } else {
                            this.current_new_mark_mouse_pos = {
                                'top': event.pageY,
                                'left': event.pageX,
                            };
                            this.getUserInput(this.mark_name, {
                                callback: function (res) {
                                    this.image_map_box_markup_positions.push({
                                        'top': this.current_new_mark_mouse_pos.top - this.imageMapOffset.top,
                                        'left': this.current_new_mark_mouse_pos.left - this.imageMapOffset.left,
                                        'notes': res.user_input_notes,
                                        'title': res.user_input_name,
                                    });
                                    this.save_markers();
                                }.bind(this)
                            });
                        }
                    }
                }
                console.log('new mark | top - ' + this.current_new_mark_mouse_pos.top + ' | left - ' + this.current_new_mark_mouse_pos.left);
            },
        },
        destroyed: function() {
            window.removeEventListener("resize", this.resize);
        },
        beforeDestroy: function() {
            $(document).off("touchend mouseup", ".image_map_box_markup_point"+this.component_ref);
            $(document).off("touchstart mousedown", ".image_map_box_markup_point"+this.component_ref);
        },
        mounted: function() {
            if (this.component_name == null) {
                this.component_ref = 'unknown';
            } else {
                this.component_ref = this.component_name;
            }

            $(document).on("touchend mouseup", ".image_map_box_markup_point"+this.component_ref, function(event) {
                this.stopDragging(event)
            }.bind(this));
            $(document).on("touchstart mousedown", ".image_map_box_markup_point"+this.component_ref, function(event) {
                this.startDragging(event,event.currentTarget)
            }.bind(this));
            window.addEventListener("resize", this.resize);

            if (this.$root.loaded) {
                this.app_ready()
            } else {
                this.$root.addAppReadyEventListenerForComponent(this.component_ref);
            }
        }
    }

</script>

<style scoped>
.image_map_user_input_popup {
    position: fixed;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.50);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
}
.image_map_user_input_popup_body {
    padding: 20px;
}
.image_map_user_input_popup_contents {
    width: 100%;
    max-width: 300px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.40);
}
.image_map_user_input_popup_title {
    height: 60px;
    border-bottom: 1px solid #eaeaea;
    font-size: 20px;
    display: flex;
    color: #676767;
    justify-content: center;
    align-items: center;
}
.no_edit_image_map {
    padding: 25px;
    border: 1px solid black;
    padding-bottom: 0;
}
#image_map_debug {
    height: 6px;
    width: 6px;
    background: deeppink;
    border-radius: 3px;
    position: absolute;
    border: 1px solid black;
    z-index: 1000000;
    display: none;
}
</style>
