/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

import axios from 'axios'
import VueAxios from 'vue-axios'
import Vuelidate from 'vuelidate'
import html2canvas from 'html2canvas';

window.Bcryptjs = require('bcryptjs');

Vue.use(VueAxios, axios)
Vue.use(Vuelidate)

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

import vSelect from 'vue-select'

Vue.component('v-select', vSelect)
Vue.component('datepicker', Datepicker)

import algoliasearch from 'algoliasearch/lite';

window.algoliasearch = algoliasearch;

import InstantSearch from 'vue-instantsearch';

Vue.use(InstantSearch);

import * as Sentry from "@sentry/vue";
import {Integrations as TracingIntegrations} from "@sentry/tracing";


window.Sentry = Sentry;
window.TracingIntegrations = TracingIntegrations;

import Datepicker from 'vuejs-datepicker';
import pagination from 'laravel-vue-pagination';

Vue.component('pagination', pagination);
Vue.component('address-finder-component', require('./components/AddressFinderComponent.vue').default);
Vue.component('multi-select', require('./components/MultiSelectComponent.vue').default);
Vue.component('dropdown', require('./components/DropdownComponent.vue').default);
Vue.component('progress-steps', require('./components/ProgressStepsComponent').default);
Vue.component('time-picker', require('./components/TimePickerComponent.vue').default);
Vue.component('option-toggle', require('./components/OptionToggleComponent.vue').default);
Vue.component('node-step', require('./components/NodeStepComponent.vue').default);

//App --- START ---
Vue.component('app-system-login', require('./components/app/system/AppLoginController.vue').default);
Vue.component('app-system-splash-screen', require('./components/app/system/AppSplashScreenComponent.vue').default);
Vue.component('app-cva-index', require('./components/app/cva/CVAIndexComponent.vue').default);
Vue.component('pre-cva-inspection', require('./components/app/cva/VehicleInspection.vue').default);
Vue.component('post-cva-inspection', require('./components/app/cva/PostCVAInspection.vue').default);
Vue.component('app-pce-index', require('./components/app/pce/PceIndexComponent.vue').default);
Vue.component('app-pce', require('./components/app/pce/PceComponent.vue').default);
Vue.component('app-pce-nav', require('./components/app/pce/PceNavComponent.vue').default);
Vue.component('app-pce-journey-status-bar', require('./components/app/pce/PceJourneyStatusBar.vue').default);
Vue.component('app-pce-assignment', require('./components/app/pce/PceAssignmentComponent.vue').default);
Vue.component('app-pce-patient-into-care', require('./components/app/pce/PcePatientIntoCareComponent.vue').default);
Vue.component('app-pce-hero-banner', require('./components/app/pce/PceHeroBannerComponent.vue').default);
Vue.component('app-freetext-list', require('./components/app/AppFreeTextListComponent.vue').default);
Vue.component('app-pce-signature', require('./components/app/pce/PceSignatureComponent.vue').default);
Vue.component('app-pce-image-map', require('./components/app/pce/ImageMapComponent.vue').default);
Vue.component('app-pce-risk_assessment', require('./components/app/pce/PceRiskAssessmentComponent.vue').default);
Vue.component('app-pce-handover', require('./components/app/pce/PceHandoverComponent.vue').default);
Vue.component('app-pce-restraints-searches-and-incidents', require('./components/app/pce/PceRestraintsSearchesAndIncidents.vue').default);
Vue.component('app-pce-sign-off', require('./components/app/pce/PceSignOffComponent.vue').default);
Vue.component('app-pce-display-sign-off-confirmation-choice', require('./components/app/pce/PceDisplaySignOffConfirmationChoice.vue').default);
Vue.component('topbar-search-component', require('./components/TopbarSearchComponent.vue').default);


// App --- End ---


Vue.component('incident-validator', require('./components/incident/Validator.vue').default);
Vue.component('admin-booking-step', require('./components/AdminBookingStepController.vue').default);

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);
// Modal
Vue.component('modal', require('./components/ModalComponent.vue').default);
