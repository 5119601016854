<template>
    <div>
        <!--  Dr / Nurse overlay ---START--- -->
        <div v-if="in_dr_view" id="in_dr_view_overlay">
            <app-pce-hero-banner title="Client entered - Patient Details" v-on:close="in_dr_view=false"></app-pce-hero-banner>
            <div class="p-4">
                <!-- Visible Injuries -->
                <label class="input_label">Does the patient have any visible injuries?</label>
                <option-toggle v-model="value.any_visible_injuries_start" :toggle_options="['No','Yes']" @change="$root.queue_save_current_pce()"  :editable="dr_details_editable"></option-toggle>
                <div v-if="value.any_visible_injuries_start">
                    <app-pce-image-map :block_edit="!dr_details_editable" image="assets/img/patient_image_map.png" ref="patient_injuries_start" component_name="patient_injuries_start" set_mark_name="Injury" v-model="value.into_care_image_map"></app-pce-image-map>
                </div>
                <br><br>


                <!-- Patient property -->
                <label class="input_label">Patient property handed over to SecureCare</label>
                <app-freetext-list v-model="value.patient_property" @change="$root.queue_save_current_pce()"  :editable="dr_details_editable"></app-freetext-list>

                <!-- Patient property -->
                <label class="input_label">Patient medication handed over to SecureCare</label>
                <app-freetext-list v-model="value.patient_meds" @change="$root.queue_save_current_pce()"  :editable="dr_details_editable"></app-freetext-list>

                <!-- Patient property -->
                <div class="row">
                    <div class="col-6">
                        <label class="input_label">Initial patient presentation</label>
                        <select class="form-control" v-model="value.initial_presentation" @change="$root.queue_save_current_pce()" :disabled="!dr_details_editable">
                            <option disabled value="0">Select an initial patient presentation</option>
                            <option v-for="(restraint_level_label, restraint_level) in $root.presentation_options" :value="restraint_level">
                                {{ restraint_level_label }}</option>
                        </select>
                    </div>
                    <div class="col-6">
                        <label class="input_label">Initial level of restraint</label>
                        <select class="form-control" v-model="value.initial_restraint" @change="$root.queue_save_current_pce()" :disabled="!dr_details_editable">
                            <option disabled selected value="0">Select a restraint label</option>
                            <option v-for="(restraint_level_label, restraint_level) in $root.restraint_level_options" :value="restraint_level">
                                {{ restraint_level_label }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="hl"></div>
            <div class="p-4">
                <app-pce-signature v-model="value.into_care_dr_signature" label="Patient handed over to Secure Care by:" @change="$root.queue_save_current_pce()"></app-pce-signature>
            </div>
            <br><br>
            <div class="font-weight-bold text-center">
                <p>When you're ready, press done and hand the device back to your Secure Care crew</p>
                <br>
                <div class="btn btn-primary" style="width: 250px;" :class="{'disabled':!dr_hand_into_care_valid}" @click="dr_done" v-if="dr_details_editable">Done</div>
                <div class="btn btn-primary" style="width: 250px;" @click="in_dr_view=false" v-else>Close</div>
            </div>
            <br><br>
            <br><br>
        </div>
        <!--  Dr / Nurse overlay ---End--- -->

        <!--  Main content ---START--- -->
        <div class="pce_card">
            <div class="pce_card_content d-flex align-items-center justify-content-center">
                <div class="btn btn-primary text-uppercase" @click="get_details_from_dr()" style="width: 600px;">Get Patient Details from Nurse / Doctor</div>
            </div>

            <div class="hl"></div>
            <div class="pce_card_content">
                <div class="section_heading pb-4">Current Risk score</div>
                <div id="risk_score_number">Score {{value.risk_assessment.risk_score_result}}</div>
<!--                <div class="data_label">Medium Risk.</div>-->
                <!-- Risk score table -->
                <div class="mt-2 px-3">
                    <div class="row border-dark border border-bottom-0 p-1" :class="{'ra_table_row_active':value.risk_assessment.risk_score_result<9}">
                        <div class="col-4">
                            6-8 Low Risk
                        </div>
                        <div class="col-8">
                            Driver Plus 1 Escort
                        </div>
                    </div>
                    <div class="row border-dark border border-bottom-0 p-1" :class="{'ra_table_row_active':value.risk_assessment.risk_score_result>8&&value.risk_assessment.risk_score_result<13}">
                        <div class="col-4">
                            9-12 Medium Risk
                        </div>
                        <div class="col-8">
                            Driver Plus 2 Escorts and Cell vehicle or Driver Plus 2
                        </div>
                    </div>
                    <div class="row border-dark border border-bottom-0 p-1" :class="{'ra_table_row_active':value.risk_assessment.risk_score_result>12&&value.risk_assessment.risk_score_result<15}">
                        <div class="col-4">
                            13-14 High Risk
                        </div>
                        <div class="col-8">
                            Driver Plus 2/3 and Cell Vehicle or Driver Plus 3
                        </div>
                    </div>
                    <div class="row border-dark border p-1" :class="{'ra_table_row_active':value.risk_assessment.risk_score_result>14}">
                        <div class="col-4">
                            15 or over Very High Risk
                        </div>
                        <div class="col-8">
                            Driver plus 3 and Cell Vehicle
                        </div>
                    </div>
                </div><br>

                <!-- Risk score inputs -->
                <label class="input_label">Based on the risk scoring shown above (provided when the booking was made), can you immediately observe any significant differences in the presentation of the patient being taken into your care and therefore the appropriateness of the crew and vehicle assigned? </label>
                <option-toggle v-model="value.ra_differences" :toggle_options="['No','Yes']" @change="$root.queue_save_current_pce()"></option-toggle>
                <div v-if="value.ra_differences" class="mt-3">
                    <label class="input_label">Please detail</label>
                    <textarea class="form-control" v-model="value.ra_differences_details" rows="4"  @blur="$root.queue_save_current_pce()"></textarea>
                </div>

                <div class="hl"></div>
                <div class="p-4">
                    <app-pce-signature v-model="value.into_care_crew_signature" label="Patient Into Care. Received by Secure Care crew member:" @change="$root.queue_save_current_pce()"></app-pce-signature>
                </div>


            </div>
        </div>
        <!--  Main content ---END--- -->

    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    methods: {
        dr_done: function () {
            if (this.dr_hand_into_care_valid) {
                this.in_dr_view= false
                this.$root.show_hand_back_device_popup = true;
            }
        },
        get_details_from_dr: function() {
            this.in_dr_view=true
            var parsed_dr_signature = JSON.parse(this.value.into_care_dr_signature);
            if (parsed_dr_signature==null || parsed_dr_signature['date_signed'] == null) {
                this.dr_details_editable=true
            } else {
                this.dr_details_editable=false
            }
        }
    },
    computed: {
        dr_hand_into_care_valid: function () {
            if (this.value.any_visible_injuries_start===null) {
                return false;
            }
            if (this.value.any_visible_injuries_start===1 && this.value.into_care_image_map==='[]') {
                // If the operator said there were visible injuries, but didn't record any in the image map
                 return false;
            }
            if (this.value.initial_restraint == 0) { // If initial_restraint is 0, '0', or false we want don't want to let the operator continue
                return false;
            }
            var parsed_dr_signature = JSON.parse(this.value.into_care_dr_signature);
            if (parsed_dr_signature==null || parsed_dr_signature['date_signed'] == null) {
                return false;
            }
            return true;
        },
    },
    data: function () { return {
        dr_details_editable: false,
        in_dr_view: false,
    }}
}
</script>

<style scoped>
#risk_score_number {
    font-size: 20px;
    font-weight: bold;
}
#in_dr_view_overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 1001;
    overflow: auto;
}
.ra_table_row_active {
    background-color: #d0f6ff;
    font-weight: bold;
}
</style>
