<template>
    <div id="splash_screen">
        <div>
            <img id="splash_screen_logo" src="assets/img/sidebar_logo.png" width="316.31px" height="70px">
            <div id="splash_screen_loading_box">
                <div class="spinner-border text-light" style="width: 60px; height: 60px;" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
name: "AppSplashScreenComponent"
}
</script>

<style scoped>
    #splash_screen {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #444444;
        z-index: 10000;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #splash_screen_loading_box {
        margin: 20px auto;
        width: 60px;
        height: 60px;
        opacity: 0;
        animation: splash_screen_loading_box-fade_in 0.5s normal forwards ease-in;
    }
    @keyframes splash_screen_loading_box-fade_in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
</style>
