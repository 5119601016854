<template>
    <div id="login_page">
        <div class="d-flex justify-content-center">

            <div v-show="!login_page_get_pin"> <!--  v-show is used instead of v-if so that we don't need to run the expensive getUserListForLogin function if the operator selects the wrong user from the list. -->
                <img id="splash_screen_logo" src="assets/img/sidebar_logo.png" width="316.31px" height="70px" @click="$root.showToast($root.device_information.name, 5000)">

                <div id="login_box">
                    <div class="user_box" v-for="user in $root.getUserListForLogin()" @click="show_login_pin_screen(user.id)">
                        <div class="user_box_icon">{{user.name[0]}}</div>
                        <div class="user_box_name">{{user.name}}</div>
                    </div>
                </div>
            </div>

            <div v-if="login_page_get_pin" >
                <div v-if="login_loading">
                    <center>
                        <br><br>
                        <div class="spinner-border text-light" style="width: 3rem; height: 3rem;" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </center>
                </div>
                <div v-if="current_pincode_sections.length<4">
                    <div id="pincode_position_box" >
                        <div class="pincode_position_item" v-for="number in [0,1,2,3]" :class="{'active':current_pincode_sections[number]!==undefined}"></div>
                    </div>
                    <br>
                    <div class="login_pincode_box" id="login_pinbox">
                        <div style="width: 270px;" >
                            <div class="pincode_item" v-for="number in [1,2,3,4,5,6,7,8,9,0]" @click="addToPin(number)">
                                {{ number }}
                            </div>
                            <div class="pincode_item remove_pin_btn" @click="current_pincode_sections.pop()"><</div>
                        </div>
                    </div>
                </div>

            </div>


        </div>



    </div>
</template>

<script>
export default {
    data() { return {
        login_loading: false,
        current_pincode_sections: [],
        login_page_get_pin: false,
        currently_logging_in_user: null,
        wrong_password_attempts: 0,
    }},
    methods: {
        show_login_pin_screen: function (user_id) {
            this.currently_logging_in_user = user_id;
            this.login_page_get_pin = true;
        },
        auth_user: function(pin, user_id) {
            let user_data = JSON.parse(this.$root.get_from_local_storage('app_users-'+this.currently_logging_in_user));
            if (Bcryptjs.compareSync(pin, user_data['app_passcode'])) {

                Sentry.setUser({ email: user_data['email'], id: user_data['id'] });

                // Todo setup real auth and require user tokens before allowing hte users to make chagnes
                this.$root.currentUser = user_data;
                this.$root.page = 'home';
            } else {
                this.current_pincode_sections = [];
                this.wrong_password_attempts++;
                this.login_loading = false;
                app.showToast('Invalid passcode, please try again');
            }
        },
        addToPin: function(number) {
            this.current_pincode_sections.push(number);
            if (this.current_pincode_sections.length>=4) {
                // //Now lets check the pincode to see if it matches...
                this.login_loading = true;
                var timeToWait = 700;
                if (this.wrong_password_attempts>2) {
                    timeToWait = (this.wrong_password_attempts*2000);
                }
                setTimeout(() => {
                    var pin_stirng = this.current_pincode_sections.join('');
                    this.auth_user(pin_stirng, this.currently_logging_in_user);
                }, timeToWait);

            }
        },
    }
}
</script>

<style scoped>
#login_page {
    background-color: #444444;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}
#login_box {
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 30px;
}
.user_box_name {
    width: calc(100% - 60px);
    font-weight: bold;
    color: white;
    font-size: 20px;
    padding: 12.5px 0px;
    line-height: 20px;
    float: left;
    margin-left: 15px;
    text-wrap: none;
    text-overflow: ellipsis;
    overflow: hidden;
}
.user_box {
    display: block;
    width: 100%;
    height: 65px;
    padding: 10px 15px;
    margin-bottom: 10px;
}
.user_box:hover {
    cursor: pointer;
    background-color: #979797;
}
.user_box.active {
    cursor: pointer;
    background-color: #979797;
}
.user_box_icon {
    background-color: #61B8CC;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    color: #4D4F5C;
    font-size: 18px;
    line-height: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
}
.user_box_name {
    width: calc(100% - 60px);
    font-weight: bold;
    color: white;
    font-size: 20px;
    padding: 12.5px 0px;
    line-height: 20px;
    float: left;
    margin-left: 15px;
    text-wrap: none;
    text-overflow: ellipsis;
    overflow: hidden;
}

.pincode_item {
    font-size: 30px;
    color: #444444;
    background: white;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    font-weight: bold;
    border-radius: 50%;
    margin: 15px;
}
.login_pincode_box {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}
.pincode_position_item {
    width: 20px;
    height: 20px;
    border: 1px solid white;
    border-radius: 50%;
    float: left;
    margin: 0px 10px;
}
.pincode_position_item.active {
    background-color: white;
}
.pincode_item.remove_pin_btn {
    font-size: 24px;
}
#pincode_position_box {
    display: flex;
    justify-content: center;
}

</style>
