<template>
    <div class="hero_section">
        <div class="hero_section_content">
            <div class="hero_section_close_button" @click="$emit('close')">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16.803 16.803">
                    <path id="Path_27" data-name="Path 27" d="M21.8,6.692,20.11,5,13.4,11.709,6.692,5,5,6.692,11.709,13.4,5,20.11,6.692,21.8,13.4,15.094,20.11,21.8,21.8,20.11,15.094,13.4Z" transform="translate(-5 -5)" fill="#fff"/>
                </svg>
            </div>
            <div class="hero_section_title">
                {{ title }}
            </div>
        </div>
    </div>

</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
    },}
</script>

<style scoped>

.hero_section {
    background-image: url('assets/img/app_top_img.jpg');
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 80px;
}
.hero_section_content {
    background-color: #00000059;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    user-select: none;
}
.hero_section_close_button {
    display: inline;
    padding: 20px;
    cursor: pointer;
}
.hero_section_title {
    font-weight: bold;
    font-size: 20px;
    color: white;
    display: inline;
    padding-left: 10px;
}
</style>
