var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "pce" } },
    [
      _vm.$root.show_hand_back_device_popup
        ? _c(
            "div",
            {
              staticClass: "text-center",
              attrs: { id: "give_device_to_sc_popup" }
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("br"),
              _c("br"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "btn btn-primary",
                  staticStyle: { width: "250px" },
                  on: {
                    click: function($event) {
                      _vm.$root.show_hand_back_device_popup = false
                    }
                  }
                },
                [_vm._v("BACK TO PCE")]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("app-pce-nav", {
        model: {
          value: _vm.active_pce_tab,
          callback: function($$v) {
            _vm.active_pce_tab = $$v
          },
          expression: "active_pce_tab"
        }
      }),
      _vm._v(" "),
      _vm.active_pce_tab === "pce_assignment"
        ? _c(
            "div",
            [
              _c("app-pce-assignment", {
                model: {
                  value: _vm.value,
                  callback: function($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.active_pce_tab === "patient_into_care"
        ? _c(
            "div",
            [
              _c("app-pce-patient-into-care", {
                model: {
                  value: _vm.value,
                  callback: function($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.active_pce_tab === "rsri"
        ? _c(
            "div",
            [
              _c("app-pce-restraints-searches-and-incidents", {
                model: {
                  value: _vm.value,
                  callback: function($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.active_pce_tab === "patient_handover"
        ? _c(
            "div",
            [
              _c("app-pce-handover", {
                model: {
                  value: _vm.value,
                  callback: function($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.active_pce_tab === "ra"
        ? _c(
            "div",
            [
              _c("app-pce-risk_assessment", {
                model: {
                  value: _vm.value,
                  callback: function($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.active_pce_tab === "sign_off"
        ? _c(
            "div",
            [
              _c("app-pce-sign-off", {
                attrs: { pce_merge_errors: _vm.pce_merge_errors },
                model: {
                  value: _vm.value,
                  callback: function($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "font-weight-bold" }, [
      _vm._v("\n            Thank you\n            "),
      _c("br"),
      _vm._v(
        "\n            Please hand the device back to your Secure Care crew\n        "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }