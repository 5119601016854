import { render, staticRenderFns } from "./DropdownComponent.vue?vue&type=template&id=4b7819de&"
import script from "./DropdownComponent.vue?vue&type=script&lang=js&"
export * from "./DropdownComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Ademola.Adewumiz\\PhpstormProjects\\scips-standalone\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4b7819de')) {
      api.createRecord('4b7819de', component.options)
    } else {
      api.reload('4b7819de', component.options)
    }
    module.hot.accept("./DropdownComponent.vue?vue&type=template&id=4b7819de&", function () {
      api.rerender('4b7819de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/DropdownComponent.vue"
export default component.exports