var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.value.dr_agree_with_injuries === 0
      ? _c("div", { staticClass: "popup_container" }, [
          _c("div", { staticClass: "close_popup" }),
          _vm._v(" "),
          _c("div", { staticClass: "popup_content_container" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "hl my-4" }),
            _vm._v(" "),
            _c("div", { staticClass: "px-4 pb-4" }, [
              _c(
                "div",
                {
                  staticClass: "btn btn-block btn-primary",
                  on: {
                    click: function($event) {
                      _vm.value.dr_agree_with_injuries = null
                      _vm.in_dr_view = false
                    }
                  }
                },
                [_vm._v("Device handed back to Secure Care")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "btn btn-block btn-outline-dark",
                  on: {
                    click: function($event) {
                      _vm.value.dr_agree_with_injuries = null
                    }
                  }
                },
                [_vm._v("Cancel")]
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.in_dr_view
      ? _c(
          "div",
          { attrs: { id: "in_dr_view_overlay" } },
          [
            _c("app-pce-hero-banner", {
              attrs: { title: "Client entered - Handover Details" },
              on: {
                close: function($event) {
                  _vm.in_dr_view = false
                }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "p-4" },
              [
                _c("label", { staticClass: "input_label text-muted" }, [
                  _vm._v(
                    "Visible injuries recorded by doctor or nurse at collection"
                  )
                ]),
                _vm._v(" "),
                _c("app-pce-image-map", {
                  ref: "patient_injuries_start_handover_dr",
                  attrs: {
                    block_edit: true,
                    image: "assets/img/patient_image_map.png",
                    component_name: "patient_injuries_start_handover_dr",
                    set_mark_name: "Injury"
                  },
                  model: {
                    value: _vm.value.into_care_image_map,
                    callback: function($$v) {
                      _vm.$set(_vm.value, "into_care_image_map", $$v)
                    },
                    expression: "value.into_care_image_map"
                  }
                }),
                _vm._v(" "),
                _c("br"),
                _c("br"),
                _vm._v(" "),
                _c("label", { staticClass: "input_label text-muted" }, [
                  _vm._v(
                    "Additional visible injuries observable now at patient handover"
                  )
                ]),
                _vm._v(" "),
                _c("app-pce-image-map", {
                  ref: "patient_injuries_handover_dr",
                  attrs: {
                    block_edit: true,
                    image: "assets/img/patient_image_map.png",
                    component_name: "patient_injuries_handover_dr",
                    set_mark_name: "Injury",
                    value: _vm.value.patient_injuries_handover
                  }
                }),
                _vm._v(" "),
                _c("br"),
                _c("br"),
                _vm._v(" "),
                _c("label", { staticClass: "input_label" }, [
                  _vm._v(
                    "1. Do you agree with the handover visible injuries description above"
                  )
                ]),
                _vm._v(" "),
                _c("option-toggle", {
                  attrs: {
                    toggle_options: ["No", "Yes"],
                    editable: _vm.dr_details_editable
                  },
                  model: {
                    value: _vm.value.dr_agree_with_injuries,
                    callback: function($$v) {
                      _vm.$set(_vm.value, "dr_agree_with_injuries", $$v)
                    },
                    expression: "value.dr_agree_with_injuries"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "hl" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "p-4" },
              [
                _c("label", { staticClass: "input_label text-muted" }, [
                  _vm._v("Patient property in Secure Care possession")
                ]),
                _vm._v(" "),
                _c("app-freetext-list", {
                  attrs: { editable: false },
                  model: {
                    value: _vm.value.patient_property,
                    callback: function($$v) {
                      _vm.$set(_vm.value, "patient_property", $$v)
                    },
                    expression: "value.patient_property"
                  }
                }),
                _vm._v(" "),
                _c("label", { staticClass: "input_label" }, [
                  _vm._v(
                    "2. Has the patient property been returned at Handover"
                  )
                ]),
                _vm._v(" "),
                _c("option-toggle", {
                  attrs: {
                    toggle_options: ["No", "Yes"],
                    editable: _vm.dr_details_editable
                  },
                  model: {
                    value: _vm.value.dr_agree_with_returned_property,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.value,
                        "dr_agree_with_returned_property",
                        $$v
                      )
                    },
                    expression: "value.dr_agree_with_returned_property"
                  }
                }),
                _vm._v(" "),
                _vm.value.dr_agree_with_returned_property === 0
                  ? _c("div", [
                      _c("br"),
                      _vm._v(" "),
                      _c("label", { staticClass: "input_label" }, [
                        _vm._v("Please detail")
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.value.dr_returned_property_notes,
                            expression: "value.dr_returned_property_notes"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          rows: "4",
                          disabled: !_vm.dr_details_editable
                        },
                        domProps: {
                          value: _vm.value.dr_returned_property_notes
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.value,
                              "dr_returned_property_notes",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "hl" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "p-4" },
              [
                _c("label", { staticClass: "input_label text-muted" }, [
                  _vm._v("Patient medication in Secure Care possession")
                ]),
                _vm._v(" "),
                _c("app-freetext-list", {
                  attrs: { editable: false },
                  model: {
                    value: _vm.value.patient_meds,
                    callback: function($$v) {
                      _vm.$set(_vm.value, "patient_meds", $$v)
                    },
                    expression: "value.patient_meds"
                  }
                }),
                _vm._v(" "),
                _c("label", { staticClass: "input_label" }, [
                  _vm._v(
                    "3. Has the patient medication been returned at Handover"
                  )
                ]),
                _vm._v(" "),
                _c("option-toggle", {
                  attrs: {
                    toggle_options: ["No", "Yes"],
                    editable: _vm.dr_details_editable
                  },
                  model: {
                    value: _vm.value.dr_agree_with_returned_meds,
                    callback: function($$v) {
                      _vm.$set(_vm.value, "dr_agree_with_returned_meds", $$v)
                    },
                    expression: "value.dr_agree_with_returned_meds"
                  }
                }),
                _vm._v(" "),
                _vm.value.dr_agree_with_returned_meds === 0
                  ? _c("div", [
                      _c("br"),
                      _vm._v(" "),
                      _c("label", { staticClass: "input_label" }, [
                        _vm._v("Please detail")
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.value.dr_returned_meds_notes,
                            expression: "value.dr_returned_meds_notes"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          rows: "4",
                          disabled: !_vm.dr_details_editable
                        },
                        domProps: { value: _vm.value.dr_returned_meds_notes },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.value,
                              "dr_returned_meds_notes",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "hl" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "p-4" },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _c("label", { staticClass: "input_label" }, [
                      _vm._v("Patient presentation")
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.value.handover_presentation,
                            expression: "value.handover_presentation"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { disabled: !_vm.dr_details_editable },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.value,
                              "handover_presentation",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { disabled: "", value: "0" } }, [
                          _vm._v("Select a patient presentation")
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.$root.presentation_options, function(
                          restraint_level_label,
                          restraint_level
                        ) {
                          return _c(
                            "option",
                            { domProps: { value: restraint_level } },
                            [
                              _vm._v(
                                "\r\n                            " +
                                  _vm._s(restraint_level_label)
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6" }, [
                    _c("label", { staticClass: "input_label" }, [
                      _vm._v("Level of restraint")
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.value.handover_restraint,
                            expression: "value.handover_restraint"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { disabled: !_vm.dr_details_editable },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.value,
                              "handover_restraint",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c(
                          "option",
                          { attrs: { disabled: "", selected: "", value: "0" } },
                          [_vm._v("Select a restraint label")]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.$root.restraint_level_options, function(
                          restraint_level_label,
                          restraint_level
                        ) {
                          return _c(
                            "option",
                            { domProps: { value: restraint_level } },
                            [
                              _vm._v(
                                "\r\n                            " +
                                  _vm._s(restraint_level_label)
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("br"),
                _c("br"),
                _vm._v(" "),
                _c("app-pce-signature", {
                  attrs: { label: "Patient handed over and received by:" },
                  model: {
                    value: _vm.value.handover_dr_signature,
                    callback: function($$v) {
                      _vm.$set(_vm.value, "handover_dr_signature", $$v)
                    },
                    expression: "value.handover_dr_signature"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "hl" }),
            _vm._v(" "),
            _c("div", { staticClass: "p-4" }, [
              _vm.dr_details_editable
                ? _c("div", [
                    _c("label", { staticClass: "input_label" }, [
                      _vm._v(
                        "How would you rate the service provided by Secure Care"
                      )
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        key: _vm.value.feedback_score,
                        staticClass: "row justify-content-md-center"
                      },
                      [
                        _c("div", { staticClass: "col-2" }, [
                          _c(
                            "div",
                            {
                              staticClass: "feedback_face_container",
                              class: { active: _vm.value.feedback_score === 1 },
                              on: {
                                click: function($event) {
                                  _vm.value.feedback_score = 1
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "feedback_face_icon",
                                attrs: { src: "assets/img/very-bad-face.png" }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "feedback_face_description" },
                                [_vm._v("Very bad")]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-2" }, [
                          _c(
                            "div",
                            {
                              staticClass: "feedback_face_container",
                              class: { active: _vm.value.feedback_score === 2 },
                              on: {
                                click: function($event) {
                                  _vm.value.feedback_score = 2
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "feedback_face_icon",
                                attrs: { src: "assets/img/bad-face.png" }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "feedback_face_description" },
                                [_vm._v("Bad")]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-2" }, [
                          _c(
                            "div",
                            {
                              staticClass: "feedback_face_container",
                              class: { active: _vm.value.feedback_score === 3 },
                              on: {
                                click: function($event) {
                                  _vm.value.feedback_score = 3
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "feedback_face_icon",
                                attrs: { src: "assets/img/ok-face.png" }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "feedback_face_description" },
                                [_vm._v("Ok")]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-2" }, [
                          _c(
                            "div",
                            {
                              staticClass: "feedback_face_container",
                              class: { active: _vm.value.feedback_score === 4 },
                              on: {
                                click: function($event) {
                                  _vm.value.feedback_score = 4
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "feedback_face_icon",
                                attrs: { src: "assets/img/good-face.png" }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "feedback_face_description" },
                                [_vm._v("Good")]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-2" }, [
                          _c(
                            "div",
                            {
                              staticClass: "feedback_face_container",
                              class: { active: _vm.value.feedback_score === 5 },
                              on: {
                                click: function($event) {
                                  _vm.value.feedback_score = 5
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "feedback_face_icon",
                                attrs: { src: "assets/img/excellent-face.png" }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "feedback_face_description" },
                                [_vm._v("Excellent")]
                              )
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("label", { staticClass: "input_label" }, [
                      _vm._v(
                        "Please provide any feedback on the quality of service provided by Secure Care"
                      )
                    ]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.value.qualitative_feedback,
                          expression: "value.qualitative_feedback"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { rows: "4" },
                      domProps: { value: _vm.value.qualitative_feedback },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.value,
                            "qualitative_feedback",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c("label", { staticClass: "input_label" }, [
                      _vm._v("Name of Feedback Provider")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.value.feedback_provider,
                          expression: "value.feedback_provider"
                        }
                      ],
                      staticClass: "form-control",
                      domProps: { value: _vm.value.feedback_provider },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.value,
                            "feedback_provider",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("br"),
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "font-weight-bold text-center" }, [
                _vm.dr_details_editable
                  ? _c("p", [
                      _vm._v(
                        "When you're ready, press done and hand the device back to your Secure Care crew"
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm.dr_details_editable
                  ? _c(
                      "div",
                      {
                        staticClass: "btn btn-primary",
                        class: { disabled: !_vm.dr_handover_valid },
                        staticStyle: { width: "250px" },
                        on: { click: _vm.dr_done }
                      },
                      [_vm._v("Done")]
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "btn btn-primary",
                        staticStyle: { width: "250px" },
                        on: {
                          click: function($event) {
                            _vm.in_dr_view = false
                          }
                        }
                      },
                      [_vm._v("Close")]
                    )
              ]),
              _vm._v(" "),
              _c("br"),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _c("br")
            ])
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "pce_card" }, [
      _c(
        "div",
        { staticClass: "pce_card_content" },
        [
          _c("label", { staticClass: "input_label" }, [
            _vm._v("Visible injuries recorded by doctor or nurse at collection")
          ]),
          _vm._v(" "),
          _c("app-pce-image-map", {
            ref: "patient_injuries_start_handover",
            attrs: {
              block_edit: true,
              image: "assets/img/patient_image_map.png",
              component_name: "patient_injuries_start_handover",
              set_mark_name: "Injury"
            },
            model: {
              value: _vm.value.into_care_image_map,
              callback: function($$v) {
                _vm.$set(_vm.value, "into_care_image_map", $$v)
              },
              expression: "value.into_care_image_map"
            }
          }),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("label", { staticClass: "input_label" }, [
            _vm._v(
              "Does the patient have new visible injuries as compared to pickup (shown above)?"
            )
          ]),
          _vm._v(" "),
          _c("option-toggle", {
            attrs: { toggle_options: ["No", "Yes"] },
            on: {
              change: function($event) {
                return _vm.$root.queue_save_current_pce()
              }
            },
            model: {
              value: _vm.value.any_new_visible_injuries,
              callback: function($$v) {
                _vm.$set(_vm.value, "any_new_visible_injuries", $$v)
              },
              expression: "value.any_new_visible_injuries"
            }
          }),
          _vm._v(" "),
          _vm.value.any_new_visible_injuries === 1
            ? _c("app-pce-image-map", {
                ref: "patient_injuries_handover",
                staticClass: "mt-2",
                attrs: {
                  block_edit: !_vm.dr_details_editable,
                  image: "assets/img/patient_image_map.png",
                  component_name: "patient_injuries_handover",
                  set_mark_name: "Injury"
                },
                model: {
                  value: _vm.value.patient_injuries_handover,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "patient_injuries_handover", $$v)
                  },
                  expression: "value.patient_injuries_handover"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("app-pce-signature", {
            attrs: {
              label: "Patient Handover. Handed over by Secure Care crew member:"
            },
            on: {
              change: function($event) {
                return _vm.$root.queue_save_current_pce()
              }
            },
            model: {
              value: _vm.value.handover_crew_signature,
              callback: function($$v) {
                _vm.$set(_vm.value, "handover_crew_signature", $$v)
              },
              expression: "value.handover_crew_signature"
            }
          }),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "pce_card_content d-flex align-items-center justify-content-center"
            },
            [
              _c(
                "div",
                {
                  staticClass: "btn btn-primary text-uppercase",
                  staticStyle: { width: "600px" },
                  on: {
                    click: function($event) {
                      return _vm.get_details_from_dr()
                    }
                  }
                },
                [_vm._v("Show Nurse / Doctor handover")]
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "hl" })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center pt-4" }, [
      _c("div", { staticClass: "popup_title" }, [
        _vm._v("Please get Secure Care to update the recorded injuries ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }