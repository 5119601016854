import { render, staticRenderFns } from "./AppSplashScreenComponent.vue?vue&type=template&id=20b2cb80&scoped=true&"
import script from "./AppSplashScreenComponent.vue?vue&type=script&lang=js&"
export * from "./AppSplashScreenComponent.vue?vue&type=script&lang=js&"
import style0 from "./AppSplashScreenComponent.vue?vue&type=style&index=0&id=20b2cb80&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20b2cb80",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Ademola.Adewumiz\\PhpstormProjects\\scips-standalone\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('20b2cb80')) {
      api.createRecord('20b2cb80', component.options)
    } else {
      api.reload('20b2cb80', component.options)
    }
    module.hot.accept("./AppSplashScreenComponent.vue?vue&type=template&id=20b2cb80&scoped=true&", function () {
      api.rerender('20b2cb80', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/app/system/AppSplashScreenComponent.vue"
export default component.exports