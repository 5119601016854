var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.attr_name === "current_journey_step_id"
      ? _c(
          "div",
          { staticClass: "sign_off_choice pl-3 " },
          _vm._l(_vm.$parent.value.journey_steps, function(journey_step) {
            return _vm.attr_value == journey_step.id
              ? _c("div", [
                  _vm._v(
                    "\n            " +
                      _vm._s(journey_step.description || journey_step.type) +
                      "\n        "
                  )
                ])
              : _vm._e()
          }),
          0
        )
      : _vm.attr_name === "into_care_image_map" ||
        _vm.attr_name === "patient_injuries_handover"
      ? _c("div", { staticClass: "sign_off_choice pl-3 " }, [
          _vm.attr_value.length >= 1
            ? _c(
                "ul",
                _vm._l(_vm.attr_value, function(attr_value_item) {
                  return _c("li", [
                    _vm._v(_vm._s(attr_value_item["title"])),
                    attr_value_item["notes"] !== ""
                      ? _c("span", [
                          _vm._v(": " + _vm._s(attr_value_item["notes"]))
                        ])
                      : _vm._e()
                  ])
                }),
                0
              )
            : _vm._e()
        ])
      : _vm.attr_name === "presentation_history"
      ? _c("div", { staticClass: "sign_off_choice pl-3 " }, [
          _c(
            "ul",
            _vm._l(_vm.attr_value, function(attr_value_item) {
              return _c("li", [
                _c("b", [
                  _vm._v(
                    _vm._s(
                      _vm.$root.presentation_options[attr_value_item["value"]]
                    ) + ":"
                  )
                ]),
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.$root
                        .time_string_to_date(attr_value_item["started_at"])
                        .toLocaleString("en-GB", {
                          timeStyle: "short",
                          hour12: true
                        })
                    ) +
                    " -\n                "
                ),
                attr_value_item["ended_at"] !== null
                  ? _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$root
                              .time_string_to_date(attr_value_item["ended_at"])
                              .toLocaleString("en-GB", {
                                timeStyle: "short",
                                hour12: true
                              })
                          ) +
                          "\n                "
                      )
                    ])
                  : _c("span", [
                      _vm._v("\n                    Ongoing\n                ")
                    ])
              ])
            }),
            0
          )
        ])
      : _vm.attr_name === "restraint_history"
      ? _c("div", { staticClass: "sign_off_choice pl-3 " }, [
          _c(
            "ul",
            _vm._l(_vm.attr_value, function(attr_value_item) {
              return _c("li", [
                _c("b", [
                  _vm._v(
                    _vm._s(
                      _vm.$root.restraint_level_options[
                        attr_value_item["value"]
                      ]
                    ) + ":"
                  )
                ]),
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.$root
                        .time_string_to_date(attr_value_item["started_at"])
                        .toLocaleString("en-GB", {
                          timeStyle: "short",
                          hour12: true
                        })
                    ) +
                    " -\n                "
                ),
                attr_value_item["ended_at"] !== null
                  ? _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$root
                              .time_string_to_date(attr_value_item["ended_at"])
                              .toLocaleString("en-GB", {
                                timeStyle: "short",
                                hour12: true
                              })
                          ) +
                          "\n                "
                      )
                    ])
                  : _c("span", [
                      _vm._v("\n                    Ongoing\n                ")
                    ])
              ])
            }),
            0
          )
        ])
      : _vm.attr_name === "journey_steps"
      ? _c("div", { staticClass: "sign_off_choice pl-3 " }, [
          _c(
            "ul",
            _vm._l(_vm.attr_value, function(attr_value_item) {
              return _c("li", [
                attr_value_item["type"] === "collection"
                  ? _c("b", [_vm._v("Collection")])
                  : attr_value_item["type"] === "destination"
                  ? _c("b", [_vm._v("Destination")])
                  : _c("b", [_vm._v(_vm._s(attr_value_item["description"]))]),
                _vm._v(" "),
                attr_value_item["started_at"] === null
                  ? _c("span", [
                      _vm._v(
                        "\n                    Not started\n                "
                      )
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$root
                              .time_string_to_date(
                                attr_value_item["started_at"]
                              )
                              .toLocaleString("en-GB", {
                                timeStyle: "short",
                                hour12: true
                              })
                          ) +
                          " -\n                    "
                      ),
                      attr_value_item["ended_at"] !== null
                        ? _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.$root
                                    .time_string_to_date(
                                      attr_value_item["ended_at"]
                                    )
                                    .toLocaleString("en-GB", {
                                      timeStyle: "short",
                                      hour12: true
                                    })
                                ) +
                                "\n                    "
                            )
                          ])
                        : _c("span", [
                            _vm._v(
                              "\n                        Ongoing\n                    "
                            )
                          ])
                    ])
              ])
            }),
            0
          )
        ])
      : _vm.attr_name === "journey_notes"
      ? _c("div", { staticClass: "sign_off_choice pl-3 " }, [
          _c(
            "ul",
            _vm._l(_vm.attr_value, function(attr_value_item) {
              return _c("li", [
                _vm._v(
                  "\n                " +
                    _vm._s(attr_value_item["content"]) +
                    "\n            "
                )
              ])
            }),
            0
          )
        ])
      : _c("div", [
          typeof _vm.attr_value === "object"
            ? _c("div", { attrs: { value: _vm.attr_value } }, [
                Array.isArray(_vm.attr_value)
                  ? _c("div", { staticClass: "sign_off_choice" }, [
                      _vm.attr_value.length >= 1
                        ? _c(
                            "ul",
                            _vm._l(_vm.attr_value, function(attr_value_item) {
                              return _c("li", [_vm._v(_vm._s(attr_value_item))])
                            }),
                            0
                          )
                        : _c("div", [
                            _c(
                              "i",
                              {
                                staticClass: "pl-3 text-muted font-weight-bold"
                              },
                              [_vm._v("BLANK")]
                            )
                          ])
                    ])
                  : _vm._e()
              ])
            : _c("input", {
                staticClass:
                  "form-control sign_off_confirmation_choices_simple_value",
                attrs: { disabled: "" },
                domProps: { value: _vm.attr_value }
              })
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }