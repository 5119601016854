var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.items, function(item_value, item_index) {
        return _c("div", [
          _vm.editable
            ? _c("div", { staticClass: "input-group mb-2" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.items[item_index],
                      expression: "items[item_index]"
                    }
                  ],
                  staticClass: "form-control",
                  domProps: { value: _vm.items[item_index] },
                  on: {
                    blur: function($event) {
                      return _vm.$emit("change")
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.items, item_index, $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "input-group-append",
                    on: {
                      click: function($event) {
                        _vm.items.splice(item_index, 1)
                        _vm.$emit("change")
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "input-group-text" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            width: "16",
                            height: "16",
                            viewBox: "0 0 10 10"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              id: "Path_27",
                              "data-name": "Path 27",
                              d:
                                "M15,6.007,13.993,5,10,8.993,6.007,5,5,6.007,8.993,10,5,13.993,6.007,15,10,11.007,13.993,15,15,13.993,11.007,10Z",
                              transform: "translate(-5 -5)",
                              fill: "#676767"
                            }
                          })
                        ]
                      )
                    ])
                  ]
                )
              ])
            : _c("div", [
                _vm._v(
                  "\r\n            " +
                    _vm._s(_vm.items[item_index]) +
                    "\r\n        "
                )
              ])
        ])
      }),
      _vm._v(" "),
      _vm.editable
        ? _c(
            "span",
            {
              staticClass: "btn-primary btn-sm btn",
              on: {
                click: function($event) {
                  _vm.items.push("")
                  _vm.$emit("change")
                }
              }
            },
            [
              _vm._v("ADD"),
              _vm.items.length >= 1
                ? _c("span", [_vm._v(" ANOTHER")])
                : _vm._e()
            ]
          )
        : _vm.items.length === 0
        ? _c("div", [_vm._v("None")])
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _c("br")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }