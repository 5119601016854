<template>
    <div id="cva_index">

        <div id="appHomeBanner">
            <div id="appBannerOverlay">
                <div id="home_image_overlay"></div>
                <div id="home_color_overlay">
                    <h1 id="homepage_title" v-if="$root.currentUser!==null">Hello {{$root.currentUser.name.split(' ')[0]}}</h1>
                </div>
            </div>
        </div>

        <div id="pcr_list">
            <br><br>
            <h2 v-if="$root.listCvasForCurrentUser.length" style="margin: 20px;">Here are your current Crew Vehicle Assessments</h2>
            <h2 v-else style="margin: 20px;">You have no Crew Vehicle Assessments yet</h2>
            <div class="btn btn-success" style="width: 300px; margin-left: 50px" v-if="$root.message.pre">{{$root.message.pre}}</div>
            <div class="btn btn-success" style="width: 300px; margin-left: 50px" v-if="$root.message.post">{{$root.message.post}}</div>
            <div class="pcr_list_item" v-for="cva in $root.listCvasForCurrentUser" @click="open_cva(cva)">
                <div class="pcr_list_item_body">
                    <div class="pcr_list_item_title">{{cva.name}}</div>
                    <div class="pcr_list_item_tag">CVA</div>
                    <p class="m-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="13.333" height="13.333" viewBox="0 0 13.333 13.333">
                            <g id="Group_1826" data-name="Group 1826" transform="translate(-95.333 -536.333)">
                                <path id="Path_862" data-name="Path 862" d="M12,7H11v4l3.5,2.1.5-.82L12,10.5Z" transform="translate(90.333 532.667)" fill="#9d9d9d"/>
                                <path id="Path_863" data-name="Path 863" d="M8.66,2a6.667,6.667,0,1,0,6.673,6.667A6.663,6.663,0,0,0,8.66,2Zm.007,12A5.333,5.333,0,1,1,14,8.667,5.332,5.332,0,0,1,8.667,14Z" transform="translate(93.333 534.333)" fill="#9d9d9d"/>
                            </g>
                        </svg>

                        {{cva.start_date.substring(0,10)}} {{$root.date_obj_to_am_pm_time_string($root.time_string_to_date(cva.start_date))}} - {{$root.date_obj_to_am_pm_time_string($root.time_string_to_date(cva.end_date))}}

                    </p>
                    <p class="m-0">
                        <span>
                            <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="12" height="10.667" viewBox="0 0 12 10.667">
                              <path id="Path_801" data-name="Path 801" d="M13.613,5.673A1,1,0,0,0,12.667,5H5.333a1,1,0,0,0-.947.673L3,9.667V15a.669.669,0,0,0,.667.667h.667A.669.669,0,0,0,5,15v-.667h8V15a.669.669,0,0,0,.667.667h.667A.669.669,0,0,0,15,15V9.667Zm-8.28,6.66a1,1,0,1,1,1-1A1,1,0,0,1,5.333,12.333Zm7.333,0a1,1,0,1,1,1-1A1,1,0,0,1,12.667,12.333ZM4.333,9l1-3h7.333l1,3Z" transform="translate(-3 -5)" fill="#9d9d9d"/>
                            </svg>
                            <span v-for="(vehicle, vehicle_array_index) in cva.vehicles">
                                {{vehicle.name}} <span v-if="vehicle_array_index !== cva.vehicles.length - 1">, </span>
                            </span>
                        </span>

                        <span class="pl-5">
                            <svg class="mr-1" data-name="Group 1187" xmlns="http://www.w3.org/2000/svg" width="14.667" height="10.667" viewBox="0 0 14.667 10.667">
                              <g id="Group_1186" data-name="Group 1186">
                                <g id="Group_1182" data-name="Group 1182" transform="translate(10.447 6.087)">
                                  <path id="Path_802" data-name="Path 802" d="M16.67,13.13a3.1,3.1,0,0,1,1.553,2.58v2H20.89v-2C20.89,14.257,18.51,13.4,16.67,13.13Z" transform="translate(-16.67 -13.13)" fill="#9d9d9d" fill-rule="evenodd"/>
                                </g>
                                <g id="Group_1183" data-name="Group 1183" transform="translate(2.667)">
                                  <circle id="Ellipse_23" data-name="Ellipse 23" cx="2.667" cy="2.667" r="2.667" fill="#9d9d9d"/>
                                </g>
                                <g id="Group_1184" data-name="Group 1184" transform="translate(8.447)">
                                  <path id="Path_803" data-name="Path 803" d="M14.557,9.333a2.667,2.667,0,0,0,0-5.333,2.786,2.786,0,0,0-.887.16,3.987,3.987,0,0,1,0,5.013A2.786,2.786,0,0,0,14.557,9.333Z" transform="translate(-13.67 -4)" fill="#9d9d9d" fill-rule="evenodd"/>
                                </g>
                                <g id="Group_1185" data-name="Group 1185" transform="translate(0 6)">
                                  <path id="Path_804" data-name="Path 804" d="M6.333,13C4.553,13,1,13.893,1,15.667v2H11.667v-2C11.667,13.893,8.113,13,6.333,13Z" transform="translate(-1 -13)" fill="#9d9d9d" fill-rule="evenodd"/>
                                </g>
                              </g>
                            </svg>
                            <span v-for="(crew_member, crew_member_id, crew_members_array_index) in cva.crew_members">
                                {{crew_member.split(' ').map(x => x.charAt(0)).join('.').toUpperCase()}} <span v-if="crew_members_array_index !==  Object.keys(cva.crew_members).length - 1">, </span>
                            </span>
                        </span>
                    </p>
                </div>
                <div class="pcr_list_item_status justify-content-end">
                    <div class="btn btn-primary btn-sm">Start CVA</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "CVAIndexComponent.vue",
    methods: {
        open_cva: function (cva) {
            this.$root.currentCVA = cva;
            this.$root.page = 'pce_index';
            Sentry.addBreadcrumb({
                category: "navigation",
                message: "Opened CVA: #" + cva.id,
                level: Sentry.Severity.Info,
            });

        },
    }
}
</script>

<style>
#appHomeBanner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: #84d1f5;
    background-image: url("assets/img/app_top_img.jpg");
    width: 100%;
    height: 150px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
#home_color_overlay {
    width: 100%;
    height: 100%;
    background: linear-gradient(225deg, transparent 100px, #eeeeee 100px);
}
#home_image_overlay {
    background-image: url("assets/img/app_home_colour_overlay.png");
    width: 100px;
    height: 88px;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
    left: 0;
}

#appBannerOverlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    font-weight: 600;
    font-size: 35px;
    line-height: 35px;
    display: flex;
    align-items: center;
}
#homepage_title {
    line-height: 45px;
    font-size: 50px;
    margin-left: 100px;
    margin-top: 60px;
    font-weight: bold;
    color: black;
}
body {
    min-height: 100%;
    background-color: #eeeeee !important;
}

#pcr_list {
    margin-top: 150px;
}
.pcr_list_item {
    background-color: white;
    border-radius: 10px;
    border: 1px solid #D4D4D5;
    margin: 20px;
    position: relative;
}
.pcr_list_item_title {
    font-size: 20px;
    font-weight: bold;
    color: #3B3B3B;
}

.pcr_list_item_tag {
    position: absolute;
    top: 0;
    right: 20px;
    background: rebeccapurple;
    color: white;
    border: 1px solid #D4D4D5;
    border-top: none;
    border-radius: 0px 0px 8px 8px;
    padding: 4px 16px;
}
.pcr_list_item_body {
    padding: 20px;
    color: #9D9D9D;
}
.pcr_list_item_status {
    color: rgb(204, 157, 46);
    border-top: 1px solid #DADADB;
    padding: 10px 20px;
    display: flex;
    align-items: center;
}

</style>
