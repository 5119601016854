var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-select", {
        attrs: {
          placeholder: _vm.placeholder,
          options: _vm.options,
          multiple: "",
          selectable: function(option) {
            return !_vm.value.includes(option.value)
          }
        },
        on: {
          input: function($event) {
            return _vm.update_value()
          }
        },
        model: {
          value: _vm.vue_select_model,
          callback: function($$v) {
            _vm.vue_select_model = $$v
          },
          expression: "vue_select_model"
        }
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { name: _vm.name, type: "hidden" },
        domProps: { value: _vm.value }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }