var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.transfer_type != 1 && _vm.transfer_type != 6
      ? _c("div", { staticClass: "row" }, [
          _vm.value.collection.id !== undefined
            ? _c("input", {
                attrs: {
                  type: "hidden",
                  autocomplete: "off",
                  name: "collection_step_id"
                },
                domProps: { value: _vm.value.collection.id }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "admin_journey_step" }, [
              _c("div", { staticClass: "row  pb-3" }, [
                _c("div", { staticClass: "col-4" }, [
                  _vm._v(
                    "\r\n                        Collection Address\r\n                    "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-5" },
                  [
                    _c("address-finder-component", {
                      attrs: { name: "collection_address_id" },
                      model: {
                        value: _vm.value.collection.address_id,
                        callback: function($$v) {
                          _vm.$set(_vm.value.collection, "address_id", $$v)
                        },
                        expression: "value.collection.address_id"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4" }, [
                  _vm._v(
                    "\r\n                        Collection Contact Number\r\n                    "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-5" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.value.collection.contact_number,
                        expression: "value.collection.contact_number"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "search",
                      autocomplete: "off",
                      name: "collection_contact_number"
                    },
                    domProps: { value: _vm.value.collection.contact_number },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.value.collection,
                          "contact_number",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.value.collection.contact_number_notes,
                        expression: "value.collection.contact_number_notes"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "search",
                      autocomplete: "off",
                      placeholder: "Notes. E.g. ext",
                      name: "collection_contact_number_notes"
                    },
                    domProps: {
                      value: _vm.value.collection.contact_number_notes
                    },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.value.collection,
                          "contact_number_notes",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ])
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("hr")
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.value.otherSteps.length > 0
      ? _c(
          "div",
          _vm._l(_vm.value.otherSteps, function(step, step_index) {
            return _c(
              "div",
              { key: step.temp_id, staticClass: "row custom_step_container" },
              [
                _vm.value.otherSteps[step_index]["id"] !== undefined
                  ? _c("input", {
                      attrs: {
                        type: "hidden",
                        autocomplete: "off",
                        name: "otherSteps[" + step_index + "][id]"
                      },
                      domProps: {
                        value: _vm.value.otherSteps[step_index]["id"]
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.transfer_type == 1 || _vm.transfer_type == 6
                  ? _c("input", {
                      attrs: {
                        type: "hidden",
                        autocomplete: "off",
                        name: "otherSteps[" + step_index + "][description]"
                      },
                      domProps: {
                        value: _vm.value.otherSteps[step_index]["description"]
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("input", {
                  attrs: {
                    type: "hidden",
                    autocomplete: "off",
                    name: "otherSteps[" + step_index + "][temp_id]"
                  },
                  domProps: {
                    value: _vm.value.otherSteps[step_index]["temp_id"]
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "admin_journey_step" }, [
                    _c("br"),
                    _vm._v(" "),
                    _c("div", { staticClass: "row  pb-3" }, [
                      _c("div", { staticClass: "col-4" }, [
                        _vm._v(
                          "\r\n                            Step " +
                            _vm._s(step_index + 1) +
                            " Description\r\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-5" }, [
                        _vm.transfer_type != 1 && _vm.transfer_type != 6
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.value.otherSteps[step_index][
                                      "description"
                                    ],
                                  expression:
                                    "value.otherSteps[step_index]['description']"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                name:
                                  "otherSteps[" + step_index + "][description]",
                                type: "search",
                                autocomplete: "off"
                              },
                              domProps: {
                                value:
                                  _vm.value.otherSteps[step_index][
                                    "description"
                                  ]
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.value.otherSteps[step_index],
                                    "description",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          : _c("div", [
                              _vm._v(
                                "\r\n                                " +
                                  _vm._s(
                                    _vm.value.otherSteps[step_index][
                                      "description"
                                    ]
                                  ) +
                                  "\r\n                            "
                              )
                            ])
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.transfer_type != 1 && _vm.transfer_type != 6
                      ? _c("div", { staticClass: "row pb-3" }, [
                          _c("div", { staticClass: "col-4" }, [
                            _vm._v(
                              "\r\n                            Step " +
                                _vm._s(step_index + 1) +
                                " Address\r\n                        "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-5" },
                            [
                              _c("address-finder-component", {
                                attrs: {
                                  name:
                                    "otherSteps[" + step_index + "][address_id]"
                                },
                                model: {
                                  value:
                                    _vm.value.otherSteps[step_index][
                                      "address_id"
                                    ],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.value.otherSteps[step_index],
                                      "address_id",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "value.otherSteps[step_index]['address_id']"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.transfer_type != 1 && _vm.transfer_type != 6
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-4" }, [
                            _vm._v(
                              "\r\n                            Step " +
                                _vm._s(step_index + 1) +
                                " Contact Number\r\n                        "
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-5" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.value.otherSteps[step_index][
                                      "contact_number"
                                    ],
                                  expression:
                                    "value.otherSteps[step_index]['contact_number']"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "search",
                                autocomplete: "off",
                                name:
                                  "otherSteps[" +
                                  step_index +
                                  "][contact_number]"
                              },
                              domProps: {
                                value:
                                  _vm.value.otherSteps[step_index][
                                    "contact_number"
                                  ]
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.value.otherSteps[step_index],
                                    "contact_number",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-3" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.value.otherSteps[step_index][
                                      "contact_number_notes"
                                    ],
                                  expression:
                                    "value.otherSteps[step_index]['contact_number_notes']"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "search",
                                autocomplete: "off",
                                placeholder: "Notes. E.g. ext",
                                name:
                                  "otherSteps[" +
                                  step_index +
                                  "][contact_number_notes]"
                              },
                              domProps: {
                                value:
                                  _vm.value.otherSteps[step_index][
                                    "contact_number_notes"
                                  ]
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.value.otherSteps[step_index],
                                    "contact_number_notes",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("br")
                  ])
                ]),
                _vm._v(" "),
                _vm.transfer_type != 1 &&
                _vm.transfer_type != 6 &&
                _vm.transfer_type != 4 &&
                _vm.transfer_type != 10 &&
                _vm.transfer_type != 11
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-6 align-items-center custom_step_hover_section"
                      },
                      [
                        _c("div", { staticClass: "pl-4" }, [
                          _c(
                            "div",
                            {
                              staticClass: "custom_step_hover_button",
                              class: {
                                disabled: _vm.value.otherSteps.length === 1
                              },
                              on: {
                                click: function($event) {
                                  return _vm.remove_step(step_index)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "custom_step_hover_button_icon"
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        width: "11.667",
                                        height: "15",
                                        viewBox: "0 0 11.667 15"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          id: "Path_790",
                                          "data-name": "Path 790",
                                          d:
                                            "M5.833,16.333A1.672,1.672,0,0,0,7.5,18h6.667a1.672,1.672,0,0,0,1.667-1.667v-10h-10Zm10.833-12.5H13.75L12.917,3H8.75l-.833.833H5V5.5H16.667Z",
                                          transform: "translate(-5 -3)",
                                          fill: "#fff"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "custom_step_hover_button_text"
                                },
                                [_vm._v("Remove step")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "custom_step_hover_button",
                              on: {
                                click: function($event) {
                                  return _vm.add_step_after(step_index)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "custom_step_hover_button_icon"
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        width: "15",
                                        height: "15",
                                        viewBox: "0 0 14 14"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          id: "Path_791",
                                          "data-name": "Path 791",
                                          d:
                                            "M19,13H13v6H11V13H5V11h6V5h2v6h6Z",
                                          transform: "translate(-5 -5)",
                                          fill: "#fff"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "custom_step_hover_button_text"
                                },
                                [_vm._v("New step")]
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.transfer_type != 1 && _vm.transfer_type != 6
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6" }, [
            _vm.value.otherSteps.length > 0
              ? _c("div", { staticClass: "row" }, [_vm._m(0)])
              : _vm._e(),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "admin_journey_step" }, [
              _vm.value.destination.id !== undefined
                ? _c("input", {
                    attrs: {
                      type: "hidden",
                      autocomplete: "off",
                      name: "destination_step_id"
                    },
                    domProps: { value: _vm.value.destination.id }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row pb-3" }, [
                _c("div", { staticClass: "col-4" }, [
                  _vm._v(
                    "\r\n                        Destination Address\r\n                    "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-5" },
                  [
                    _c("address-finder-component", {
                      attrs: { name: "destination_address_id" },
                      model: {
                        value: _vm.value.destination.address_id,
                        callback: function($$v) {
                          _vm.$set(_vm.value.destination, "address_id", $$v)
                        },
                        expression: "value.destination.address_id"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4" }, [
                  _vm._v(
                    "\r\n                        Destination Contact Number\r\n                    "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-5" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.value.destination.contact_number,
                        expression: "value.destination.contact_number"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "search",
                      autocomplete: "off",
                      name: "destination_contact_number"
                    },
                    domProps: { value: _vm.value.destination.contact_number },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.value.destination,
                          "contact_number",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.value.destination.contact_number_notes,
                        expression: "value.destination.contact_number_notes"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "search",
                      autocomplete: "off",
                      name: "destination_contact_number_notes",
                      placeholder: "Notes. E.g. ext"
                    },
                    domProps: {
                      value: _vm.value.destination.contact_number_notes
                    },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.value.destination,
                          "contact_number_notes",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-6" }, [_c("hr")])
  }
]
render._withStripped = true

export { render, staticRenderFns }