<template>
<div>


    <div class="progress-box-container">

        <div v-for="(step, index) in value.steps">
            <div @click="changeStep(step.label)" class="progress-box" :class="{'prog_active':(step.label === value.active_step),'prog_done':(step.completed), 'prog-end-l':(index === 0), 'prog-end-r':(index === value.steps.length-1), 'prog_arrow':(index !== value.steps.length-1)}">
                <div>
                    <div class="progress-box-title text-primary">{{ step.label }}</div>
                    <div class="progress-box-step">{{ step.sub_title }}</div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
export default {
    props: {
        'value': {
            type: Object,
            required: true,
        }
    },
    mounted() {

    },
    data() {
        return {
        }
    },
    beforeUpdate: function () {
    },
    methods: {
        changeStep: function (new_step) {
            var last_step = this.value.active_step
            this.value.active_step = new_step;
            this.$emit('change', {
                'to': new_step,
                'from': last_step
            })
        }
    },
}
</script>
