<template>
    <div id="cva_index">

        <div id="appHomeBanner">
            <div id="appBannerOverlay">
                <div id="home_image_overlay"></div>
                <div id="home_color_overlay">
                    <h1 id="homepage_title" v-if="$root.currentUser!==null">Hello {{$root.currentUser.name.split(' ')[0]}}</h1>
                </div>
            </div>
        </div>

        <div id="pce_list">
            <h2 v-if="$root.currentCVA!==null&&$root.listPcesForUsersCVAs[$root.currentCVA.id]!==null&&$root.listPcesForUsersCVAs[$root.currentCVA.id]!==undefined">Here are your Patient Care Episodes</h2>
            <h2 v-else>You have no Patient Care Episodes yet</h2>
            <div id="inspection_forms" class="d-flex justify-content-start align-items-center">
                <!--Pre CVA inspection button - VehicleInspection.vue-->
                <button v-if="!forms.pre" class="btn btn-info" @click="$root.page='pre_cva'">Pre CVA inspection form</button>
                <div v-else class="text-success font-italic">Pre CVA inspection form complete</div>
                <!--Post CVA inspection button - PostCVAInspection.vue-->
                <button v-if="!forms.post & forms.pre" class="btn btn-info" @click="$root.page='post_cva'">Post CVA inspection form</button>
                <div v-else-if="forms.post & forms.pre" class="text-success font-italic">Post CVA inspection form complete</div>
            </div>
            <div v-if="$root.currentCVA!==null&&$root.listPcesForUsersCVAs[$root.currentCVA.id]!==null&&$root.listPcesForUsersCVAs[$root.currentCVA.id]!==undefined">
                <div class="pce_list_item" v-for="pce in $root.listPcesForUsersCVAs[$root.currentCVA.id]" @click="loadPCE(pce.id, forms.pre)">
                    <div class="pce_list_item_body">
                        <div class="pce_list_item_title">{{pce.deployed_name}}</div>
                        <div class="pce_list_item_tag">PCE</div>
                        <p class="m-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="13.333" height="13.333" viewBox="0 0 13.333 13.333">
                                <g id="Group_1826" data-name="Group 1826" transform="translate(-95.333 -536.333)">
                                    <path id="Path_862" data-name="Path 862" d="M12,7H11v4l3.5,2.1.5-.82L12,10.5Z" transform="translate(90.333 532.667)" fill="#9d9d9d"/>
                                    <path id="Path_863" data-name="Path 863" d="M8.66,2a6.667,6.667,0,1,0,6.673,6.667A6.663,6.663,0,0,0,8.66,2Zm.007,12A5.333,5.333,0,1,1,14,8.667,5.332,5.332,0,0,1,8.667,14Z" transform="translate(93.333 534.333)" fill="#9d9d9d"/>
                                </g>
                            </svg>

                            {{pce.collection_time}}
                        </p>
                        <p class="m-0 pt-2">{{pce.type}}</p>
                        <p class="m-0 pt-2">Ref: SC-{{pce.id}}</p>
                        <p class="m-0 pt-2">CVA ID: {{pce.cva_id}}</p>
                    </div>
                    <div class="pce_list_item_status justify-content-end">
                        <button v-if="forms.pre" class="btn btn-primary">Start PCE</button>
                        <button v-else class="btn btn-warning" disabled>Please Complete Pre CVA Inspection form</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <button class="btn btn-primary" @click="$root.page='home'; $root.currentCVA=null">EXIT CREW VEHICLE ASSIGNMENT</button>
        </div>


    </div>
</template>

<script>
export default {
    name: "CVAIndexComponent.vue",
    data() {
        return {
            forms: {pre:false, post:false}
        }
    },
    methods: {
        // use forms.pre and forms.post to check if pre and post vehicle inspection forms have been completed
        loadPCE(pceID, run) {
            if(run) {
                this.$root.load_into_current_pce(pceID)
                this.$root.page='pce'
            }
        },
        checkForms(cvaId) {
            axios({
                method: 'get',
                url: this.$root.endpoint+'/api/cva/' + cvaId
            }).then(response => {
                // let forms = {pre:false, post:false}
                if(response.data.pre_cva !== null) {
                    this.forms['pre'] = true;
                }
                if(response.data.post_cva !== null) {
                    this.forms['post'] = true;
                }
            });
        }
    },
    computed: {
        cvaID() {
            return this.$root.currentCVA.id;
        }
    },
    created() {
        this.checkForms(this.cvaID)
    }
}
</script>

<style>
#appHomeBanner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: #84d1f5;
    background-image: url("assets/img/app_top_img.jpg");
    width: 100%;
    height: 150px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
#home_color_overlay {
    width: 100%;
    height: 100%;
    background: linear-gradient(225deg, transparent 100px, #eeeeee 100px);
}
#home_image_overlay {
    background-image: url("assets/img/app_home_colour_overlay.png");
    width: 100px;
    height: 88px;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
    left: 0;
}

#appBannerOverlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    font-weight: 600;
    font-size: 35px;
    line-height: 35px;
    display: flex;
    align-items: center;
}
#homepage_title {
    line-height: 45px;
    font-size: 50px;
    margin-left: 100px;
    margin-top: 60px;
    font-weight: bold;
    color: black;
}
body {
    min-height: 100%;
    background-color: #eeeeee !important;
}

#pce_list {
     margin-top: 185px;
}
#pce_list > * {
  margin: 1.5rem;
}
/*pre and post cva form button*/
#inspection_forms > *:first-child {
    margin-right: 2rem;
}

.pce_list_item {
    background-color: white;
    border-radius: 10px;
    border: 1px solid #D4D4D5;
    margin: 10px 0px 10px 0px;
}
.pce_list_item_title {
    font-size: 20px;
    font-weight: bold;
    color: #3B3B3B;
}
.pce_list_item_tag {
    position: absolute;
    top: 0;
    right: 20px;
    background: goldenrod;
    color: black;
    border: 1px solid #D4D4D5;
    border-top: none;
    border-radius: 0px 0px 8px 8px;
    padding: 4px 16px;
}
.pce_list_item_body {
    padding: 20px;
    color: #9D9D9D;
    position: relative;
}
.pce_list_item_status {
    color: rgb(204, 157, 46);
    border-top: 1px solid #DADADB;
    padding: 10px 20px;
    display: flex;
    align-items: center;
}

</style>
