var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "cva_index" } },
    [
      _c("div", { attrs: { id: "appHomeBanner" } }, [
        _c("div", { attrs: { id: "appBannerOverlay" } }, [
          _c("div", { attrs: { id: "home_image_overlay" } }),
          _vm._v(" "),
          _c("div", { attrs: { id: "home_color_overlay" } }, [
            _vm.$root.currentUser !== null
              ? _c("h1", { attrs: { id: "homepage_title" } }, [
                  _vm._v(
                    "Hello " + _vm._s(_vm.$root.currentUser.name.split(" ")[0])
                  )
                ])
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "pcr_list" } }, [
        _c("h2", { staticStyle: { margin: "20px" } }, [
          _vm._v("Pre CVA Inspection Form")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "progress-box-container" }, [
          _c(
            "div",
            {
              staticClass: "progress-box",
              class: { prog_active: _vm.cva_inspection.step === 1 }
            },
            [
              _c("div", [
                _c("div", { staticClass: "progress-box-title text-primary" }, [
                  _vm._v(_vm._s(_vm.cva_inspection.steps.one.title))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "progress-box-step" }, [
                  _vm._v("Step " + _vm._s(_vm.cva_inspection.steps.one.number))
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "progress-box",
              class: { prog_active: _vm.cva_inspection.step === 2 }
            },
            [
              _c("div", [
                _c("div", { staticClass: "progress-box-title text-primary" }, [
                  _vm._v(_vm._s(_vm.cva_inspection.steps.two.title))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "progress-box-step" }, [
                  _vm._v("Step " + _vm._s(_vm.cva_inspection.steps.two.number))
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "progress-box",
              class: { prog_active: _vm.cva_inspection.step === 3 }
            },
            [
              _c("div", [
                _c("div", { staticClass: "progress-box-title text-primary" }, [
                  _vm._v(_vm._s(_vm.cva_inspection.steps.three.title))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "progress-box-step" }, [
                  _vm._v(
                    "Step " + _vm._s(_vm.cva_inspection.steps.three.number)
                  )
                ])
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "pcr_list_item" }, [
          _c("div", { staticClass: "cva_card" }, [
            _c("form", [
              _vm.cva_inspection.step === 1
                ? _c("div", { staticClass: "pce_card_content" }, [
                    _c("h5", [_vm._v("Odometer reading:")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row two_items" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.odometer,
                              expression: "cva_inspection.odometer"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "number", placeholder: "Comments" },
                          domProps: { value: _vm.cva_inspection.odometer },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection,
                                "odometer",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("h5", [_vm._v("General Checks:")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row three_items" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.warn_light[0],
                              callback: function($$v) {
                                _vm.$set(_vm.cva_inspection.warn_light, 0, $$v)
                              },
                              expression: "cva_inspection.warn_light[0]"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.warn_light[1],
                              expression: "cva_inspection.warn_light[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: { value: _vm.cva_inspection.warn_light[1] },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.warn_light,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row three_items" }, [
                      _vm._m(2),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.horn[0],
                              callback: function($$v) {
                                _vm.$set(_vm.cva_inspection.horn, 0, $$v)
                              },
                              expression: "cva_inspection.horn[0]"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.horn[1],
                              expression: "cva_inspection.horn[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: { value: _vm.cva_inspection.horn[1] },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.horn,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row three_items" }, [
                      _vm._m(3),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.fuel_card[0],
                              callback: function($$v) {
                                _vm.$set(_vm.cva_inspection.fuel_card, 0, $$v)
                              },
                              expression: "cva_inspection.fuel_card[0]"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.fuel_card[1],
                              expression: "cva_inspection.fuel_card[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: { value: _vm.cva_inspection.fuel_card[1] },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.fuel_card,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row three_items" }, [
                      _vm._m(4),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.battery[0],
                              callback: function($$v) {
                                _vm.$set(_vm.cva_inspection.battery, 0, $$v)
                              },
                              expression: "cva_inspection.battery[0]"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.battery[1],
                              expression: "cva_inspection.battery[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: { value: _vm.cva_inspection.battery[1] },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.battery,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("h5", [_vm._v("Check the windscreen for:")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row three_items" }, [
                      _vm._m(5),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.windscreen.crack[0],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.cva_inspection.windscreen.crack,
                                  0,
                                  $$v
                                )
                              },
                              expression: "cva_inspection.windscreen.crack[0]"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.windscreen.crack[1],
                              expression: "cva_inspection.windscreen.crack[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: {
                            value: _vm.cva_inspection.windscreen.crack[1]
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.windscreen.crack,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row three_items" }, [
                      _vm._m(6),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.windscreen.wipers[0],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.cva_inspection.windscreen.wipers,
                                  0,
                                  $$v
                                )
                              },
                              expression: "cva_inspection.windscreen.wipers[0]"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.windscreen.wipers[1],
                              expression: "cva_inspection.windscreen.wipers[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: {
                            value: _vm.cva_inspection.windscreen.wipers[1]
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.windscreen.wipers,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row three_items" }, [
                      _vm._m(7),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.windscreen.wash[0],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.cva_inspection.windscreen.wash,
                                  0,
                                  $$v
                                )
                              },
                              expression: "cva_inspection.windscreen.wash[0]"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.windscreen.wash[1],
                              expression: "cva_inspection.windscreen.wash[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: {
                            value: _vm.cva_inspection.windscreen.wash[1]
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.windscreen.wash,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("h5", [
                      _vm._v("Check the following lights are working:")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row three_items" }, [
                      _vm._m(8),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.lights.head[0],
                              callback: function($$v) {
                                _vm.$set(_vm.cva_inspection.lights.head, 0, $$v)
                              },
                              expression: "cva_inspection.lights.head[0]"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.lights.head[1],
                              expression: "cva_inspection.lights.head[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: {
                            value: _vm.cva_inspection.lights.head[1]
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.lights.head,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row three_items" }, [
                      _vm._m(9),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.lights.break[0],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.cva_inspection.lights.break,
                                  0,
                                  $$v
                                )
                              },
                              expression: "cva_inspection.lights.break[0]"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.lights.break[1],
                              expression: "cva_inspection.lights.break[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: {
                            value: _vm.cva_inspection.lights.break[1]
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.lights.break,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row three_items" }, [
                      _vm._m(10),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.lights.side[0],
                              callback: function($$v) {
                                _vm.$set(_vm.cva_inspection.lights.side, 0, $$v)
                              },
                              expression: "cva_inspection.lights.side[0]"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.lights.side[1],
                              expression: "cva_inspection.lights.side[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: {
                            value: _vm.cva_inspection.lights.side[1]
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.lights.side,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row three_items" }, [
                      _vm._m(11),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.lights.indicators[0],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.cva_inspection.lights.indicators,
                                  0,
                                  $$v
                                )
                              },
                              expression: "cva_inspection.lights.indicators[0]"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.lights.indicators[1],
                              expression: "cva_inspection.lights.indicators[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: {
                            value: _vm.cva_inspection.lights.indicators[1]
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.lights.indicators,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row three_items" }, [
                      _vm._m(12),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.lights.interior[0],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.cva_inspection.lights.interior,
                                  0,
                                  $$v
                                )
                              },
                              expression: "cva_inspection.lights.interior[0]"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.lights.interior[1],
                              expression: "cva_inspection.lights.interior[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: {
                            value: _vm.cva_inspection.lights.interior[1]
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.lights.interior,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("h5", [_vm._v("Wheels checks:")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row three_items" }, [
                      _vm._m(13),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.wheels.tire_pressure[0],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.cva_inspection.wheels.tire_pressure,
                                  0,
                                  $$v
                                )
                              },
                              expression:
                                "cva_inspection.wheels.tire_pressure[0]"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.wheels.tire_pressure[1],
                              expression:
                                "cva_inspection.wheels.tire_pressure[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: {
                            value: _vm.cva_inspection.wheels.tire_pressure[1]
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.wheels.tire_pressure,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row three_items" }, [
                      _vm._m(14),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.wheels.tire_thread[0],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.cva_inspection.wheels.tire_thread,
                                  0,
                                  $$v
                                )
                              },
                              expression: "cva_inspection.wheels.tire_thread[0]"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.wheels.tire_thread[1],
                              expression: "cva_inspection.wheels.tire_thread[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: {
                            value: _vm.cva_inspection.wheels.tire_thread[1]
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.wheels.tire_thread,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row three_items" }, [
                      _vm._m(15),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.wheels.hub_cap[0],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.cva_inspection.wheels.hub_cap,
                                  0,
                                  $$v
                                )
                              },
                              expression: "cva_inspection.wheels.hub_cap[0]"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.wheels.hub_cap[1],
                              expression: "cva_inspection.wheels.hub_cap[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: {
                            value: _vm.cva_inspection.wheels.hub_cap[1]
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.wheels.hub_cap,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row three_items" }, [
                      _vm._m(16),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.wheels.rims[0],
                              callback: function($$v) {
                                _vm.$set(_vm.cva_inspection.wheels.rims, 0, $$v)
                              },
                              expression: "cva_inspection.wheels.rims[0]"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.wheels.rims[1],
                              expression: "cva_inspection.wheels.rims[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: {
                            value: _vm.cva_inspection.wheels.rims[1]
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.wheels.rims,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("h5", [_vm._v("Vehicle Appearance:")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row three_items" }, [
                      _vm._m(17),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.appearance.mirrors[0],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.cva_inspection.appearance.mirrors,
                                  0,
                                  $$v
                                )
                              },
                              expression: "cva_inspection.appearance.mirrors[0]"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.appearance.mirrors[1],
                              expression: "cva_inspection.appearance.mirrors[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: {
                            value: _vm.cva_inspection.appearance.mirrors[1]
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.appearance.mirrors,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row three_items" }, [
                      _vm._m(18),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.appearance.scratch[0],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.cva_inspection.appearance.scratch,
                                  0,
                                  $$v
                                )
                              },
                              expression: "cva_inspection.appearance.scratch[0]"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.appearance.scratch[1],
                              expression: "cva_inspection.appearance.scratch[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: {
                            value: _vm.cva_inspection.appearance.scratch[1]
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.appearance.scratch,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("app-pce-image-map", {
                          ref: "vehicle_front",
                          attrs: {
                            image: "assets/img/vehicle_front.png",
                            component_name: "vehicle_front",
                            set_mark_name: "Damage"
                          },
                          model: {
                            value: _vm.cva_inspection.body_map.front,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.cva_inspection.body_map,
                                "front",
                                $$v
                              )
                            },
                            expression: "cva_inspection.body_map.front"
                          }
                        }),
                        _vm._v(" "),
                        _c("app-pce-image-map", {
                          ref: "vehicle_back",
                          attrs: {
                            image: "assets/img/vehicle_back.png",
                            component_name: "vehicle_back",
                            set_mark_name: "Damage"
                          },
                          model: {
                            value: _vm.cva_inspection.body_map.back,
                            callback: function($$v) {
                              _vm.$set(_vm.cva_inspection.body_map, "back", $$v)
                            },
                            expression: "cva_inspection.body_map.back"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("app-pce-image-map", {
                          ref: "vehicle_right",
                          attrs: {
                            image: "assets/img/vehicle_right.png",
                            component_name: "vehicle_right",
                            set_mark_name: "Damage"
                          },
                          model: {
                            value: _vm.cva_inspection.body_map.right,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.cva_inspection.body_map,
                                "right",
                                $$v
                              )
                            },
                            expression: "cva_inspection.body_map.right"
                          }
                        }),
                        _vm._v(" "),
                        _c("app-pce-image-map", {
                          ref: "vehicle_left",
                          attrs: {
                            image: "assets/img/vehicle_left.png",
                            component_name: "vehicle_left",
                            set_mark_name: "Damage"
                          },
                          model: {
                            value: _vm.cva_inspection.body_map.left,
                            callback: function($$v) {
                              _vm.$set(_vm.cva_inspection.body_map, "left", $$v)
                            },
                            expression: "cva_inspection.body_map.left"
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.cva_inspection.step === 2
                ? _c("div", { staticClass: "pce_card_content" }, [
                    _c(
                      "div",
                      { staticClass: "pre_form_row vehicle_hygiene two_items" },
                      [
                        _c("p", [
                          _vm._v(
                            "Has the vehicle been left clean by the previous crew and ready for your use?"
                          )
                        ]),
                        _vm._v(" "),
                        _c("option-toggle", {
                          attrs: { toggle_options: ["No", "Yes"] },
                          model: {
                            value:
                              _vm.cva_inspection.vehicle_hygiene.left_clean,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.cva_inspection.vehicle_hygiene,
                                "left_clean",
                                $$v
                              )
                            },
                            expression:
                              "cva_inspection.vehicle_hygiene.left_clean"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        key: _vm.cva_inspection.vehicle_hygiene.external,
                        staticClass: "pre_form_row vehicle_hygiene faces"
                      },
                      [
                        _c("p", [
                          _vm._v("Score the vehicle external cleanliness")
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "feedback_face_container",
                              class: {
                                active:
                                  _vm.cva_inspection.vehicle_hygiene
                                    .external === 1
                              },
                              on: {
                                click: function($event) {
                                  _vm.cva_inspection.vehicle_hygiene.external = 1
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "feedback_face_icon",
                                attrs: { src: "assets/img/very-bad-face.png" }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "feedback_face_description" },
                                [_vm._v("Very bad")]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "feedback_face_container",
                              class: {
                                active:
                                  _vm.cva_inspection.vehicle_hygiene
                                    .external === 2
                              },
                              on: {
                                click: function($event) {
                                  _vm.cva_inspection.vehicle_hygiene.external = 2
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "feedback_face_icon",
                                attrs: { src: "assets/img/bad-face.png" }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "feedback_face_description" },
                                [_vm._v("Bad")]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "feedback_face_container",
                              class: {
                                active:
                                  _vm.cva_inspection.vehicle_hygiene
                                    .external === 3
                              },
                              on: {
                                click: function($event) {
                                  _vm.cva_inspection.vehicle_hygiene.external = 3
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "feedback_face_icon",
                                attrs: { src: "assets/img/ok-face.png" }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "feedback_face_description" },
                                [_vm._v("Ok")]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "feedback_face_container",
                              class: {
                                active:
                                  _vm.cva_inspection.vehicle_hygiene
                                    .external === 4
                              },
                              on: {
                                click: function($event) {
                                  _vm.cva_inspection.vehicle_hygiene.external = 4
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "feedback_face_icon",
                                attrs: { src: "assets/img/good-face.png" }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "feedback_face_description" },
                                [_vm._v("Good")]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "feedback_face_container",
                              class: {
                                active:
                                  _vm.cva_inspection.vehicle_hygiene
                                    .external === 5
                              },
                              on: {
                                click: function($event) {
                                  _vm.cva_inspection.vehicle_hygiene.external = 5
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "feedback_face_icon",
                                attrs: { src: "assets/img/excellent-face.png" }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "feedback_face_description" },
                                [_vm._v("Excellent")]
                              )
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        key: _vm.cva_inspection.vehicle_hygiene.internal,
                        staticClass: "pre_form_row vehicle_hygiene faces"
                      },
                      [
                        _c("p", [
                          _vm._v("Score the vehicle internal cleanliness")
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "feedback_face_container",
                              class: {
                                active:
                                  _vm.cva_inspection.vehicle_hygiene
                                    .internal === 1
                              },
                              on: {
                                click: function($event) {
                                  _vm.cva_inspection.vehicle_hygiene.internal = 1
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "feedback_face_icon",
                                attrs: { src: "assets/img/very-bad-face.png" }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "feedback_face_description" },
                                [_vm._v("Very bad")]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "feedback_face_container",
                              class: {
                                active:
                                  _vm.cva_inspection.vehicle_hygiene
                                    .internal === 2
                              },
                              on: {
                                click: function($event) {
                                  _vm.cva_inspection.vehicle_hygiene.internal = 2
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "feedback_face_icon",
                                attrs: { src: "assets/img/bad-face.png" }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "feedback_face_description" },
                                [_vm._v("Bad")]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "feedback_face_container",
                              class: {
                                active:
                                  _vm.cva_inspection.vehicle_hygiene
                                    .internal === 3
                              },
                              on: {
                                click: function($event) {
                                  _vm.cva_inspection.vehicle_hygiene.internal = 3
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "feedback_face_icon",
                                attrs: { src: "assets/img/ok-face.png" }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "feedback_face_description" },
                                [_vm._v("Ok")]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "feedback_face_container",
                              class: {
                                active:
                                  _vm.cva_inspection.vehicle_hygiene
                                    .internal === 4
                              },
                              on: {
                                click: function($event) {
                                  _vm.cva_inspection.vehicle_hygiene.internal = 4
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "feedback_face_icon",
                                attrs: { src: "assets/img/good-face.png" }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "feedback_face_description" },
                                [_vm._v("Good")]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "feedback_face_container",
                              class: {
                                active:
                                  _vm.cva_inspection.vehicle_hygiene
                                    .internal === 5
                              },
                              on: {
                                click: function($event) {
                                  _vm.cva_inspection.vehicle_hygiene.internal = 5
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "feedback_face_icon",
                                attrs: { src: "assets/img/excellent-face.png" }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "feedback_face_description" },
                                [_vm._v("Excellent")]
                              )
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        key: _vm.cva_inspection.vehicle_hygiene.window,
                        staticClass: "pre_form_row vehicle_hygiene faces"
                      },
                      [
                        _c("p", [
                          _vm._v("Score the vehicle on window cleanliness")
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "feedback_face_container",
                              class: {
                                active:
                                  _vm.cva_inspection.vehicle_hygiene.window ===
                                  1
                              },
                              on: {
                                click: function($event) {
                                  _vm.cva_inspection.vehicle_hygiene.window = 1
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "feedback_face_icon",
                                attrs: { src: "assets/img/very-bad-face.png" }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "feedback_face_description" },
                                [_vm._v("Very bad")]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "feedback_face_container",
                              class: {
                                active:
                                  _vm.cva_inspection.vehicle_hygiene.window ===
                                  2
                              },
                              on: {
                                click: function($event) {
                                  _vm.cva_inspection.vehicle_hygiene.window = 2
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "feedback_face_icon",
                                attrs: { src: "assets/img/bad-face.png" }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "feedback_face_description" },
                                [_vm._v("Bad")]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "feedback_face_container",
                              class: {
                                active:
                                  _vm.cva_inspection.vehicle_hygiene.window ===
                                  3
                              },
                              on: {
                                click: function($event) {
                                  _vm.cva_inspection.vehicle_hygiene.window = 3
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "feedback_face_icon",
                                attrs: { src: "assets/img/ok-face.png" }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "feedback_face_description" },
                                [_vm._v("Ok")]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "feedback_face_container",
                              class: {
                                active:
                                  _vm.cva_inspection.vehicle_hygiene.window ===
                                  4
                              },
                              on: {
                                click: function($event) {
                                  _vm.cva_inspection.vehicle_hygiene.window = 4
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "feedback_face_icon",
                                attrs: { src: "assets/img/good-face.png" }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "feedback_face_description" },
                                [_vm._v("Good")]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "feedback_face_container",
                              class: {
                                active:
                                  _vm.cva_inspection.vehicle_hygiene.window ===
                                  5
                              },
                              on: {
                                click: function($event) {
                                  _vm.cva_inspection.vehicle_hygiene.window = 5
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "feedback_face_icon",
                                attrs: { src: "assets/img/excellent-face.png" }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "feedback_face_description" },
                                [_vm._v("Excellent")]
                              )
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "pre_form_row vehicle_hygiene two_items" },
                      [
                        _c("p", [
                          _vm._v("Is the vehicle free from litter and rubbish")
                        ]),
                        _vm._v(" "),
                        _c("option-toggle", {
                          attrs: { toggle_options: ["No", "Yes"] },
                          model: {
                            value: _vm.cva_inspection.hygiene.clean,
                            callback: function($$v) {
                              _vm.$set(_vm.cva_inspection.hygiene, "clean", $$v)
                            },
                            expression: "cva_inspection.hygiene.clean"
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.cva_inspection.step === 3
                ? _c("div", { staticClass: "pce_card_content" }, [
                    _c("h5", [_vm._v("Kit bag:")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row two_items" }, [
                      _c("p", [_vm._v("Please record your kit bag number")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cva_inspection.kitbag.number,
                            expression: "cva_inspection.kitbag.number"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", placeholder: "Comments" },
                        domProps: { value: _vm.cva_inspection.kitbag.number },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.cva_inspection.kitbag,
                              "number",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("h5", [_vm._v("Restraints:")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row two_items" }, [
                      _c("p", [
                        _vm._v(
                          "Please record the serial numbers of your hinged handcuffs"
                        )
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cva_inspection.kitbag.handcuff,
                            expression: "cva_inspection.kitbag.handcuff"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", placeholder: "Comments" },
                        domProps: { value: _vm.cva_inspection.kitbag.handcuff },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.cva_inspection.kitbag,
                              "handcuff",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "pre_form_row two_items" },
                      [
                        _c("p", [
                          _vm._v("Are the handcuffs in working order?")
                        ]),
                        _vm._v(" "),
                        _c("option-toggle", {
                          attrs: { toggle_options: ["No", "Yes"] },
                          model: {
                            value: _vm.cva_inspection.kitbag.handcuff_working,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.cva_inspection.kitbag,
                                "handcuff_working",
                                $$v
                              )
                            },
                            expression: "cva_inspection.kitbag.handcuff_working"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "pre_form_row two_items" },
                      [
                        _c("p", [
                          _vm._v(
                            "Does the kitbag contain leg restraint straps?"
                          )
                        ]),
                        _vm._v(" "),
                        _c("option-toggle", {
                          attrs: { toggle_options: ["No", "Yes"] },
                          model: {
                            value: _vm.cva_inspection.kitbag.leg_straps,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.cva_inspection.kitbag,
                                "leg_straps",
                                $$v
                              )
                            },
                            expression: "cva_inspection.kitbag.leg_straps"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "pre_form_row two_items" },
                      [
                        _c("p", [
                          _vm._v("Is the metal detector wand in working order?")
                        ]),
                        _vm._v(" "),
                        _c("option-toggle", {
                          attrs: { toggle_options: ["No", "Yes"] },
                          model: {
                            value: _vm.cva_inspection.kitbag.metaldetector,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.cva_inspection.kitbag,
                                "metaldetector",
                                $$v
                              )
                            },
                            expression: "cva_inspection.kitbag.metaldetector"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("h5", [_vm._v("Defibrillator:")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "pre_form_row two_items" },
                      [
                        _c("p", [_vm._v("Is the defibrillator charged?")]),
                        _vm._v(" "),
                        _c("option-toggle", {
                          attrs: { toggle_options: ["No", "Yes"] },
                          model: {
                            value: _vm.cva_inspection.kitbag.defib,
                            callback: function($$v) {
                              _vm.$set(_vm.cva_inspection.kitbag, "defib", $$v)
                            },
                            expression: "cva_inspection.kitbag.defib"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "pre_form_row two_items" },
                      [
                        _c("p", [
                          _vm._v(
                            "Is the certification for the defibrillator showing as in date?"
                          )
                        ]),
                        _vm._v(" "),
                        _c("option-toggle", {
                          attrs: { toggle_options: ["No", "Yes"] },
                          model: {
                            value: _vm.cva_inspection.kitbag.defib_date,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.cva_inspection.kitbag,
                                "defib_date",
                                $$v
                              )
                            },
                            expression: "cva_inspection.kitbag.defib_date"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "pre_form_row two_items" },
                      [
                        _c("p", [
                          _vm._v(
                            "Is the certification for the defibrillator pads showing as in date?"
                          )
                        ]),
                        _vm._v(" "),
                        _c("option-toggle", {
                          attrs: { toggle_options: ["No", "Yes"] },
                          model: {
                            value: _vm.cva_inspection.kitbag.defib_pad_date,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.cva_inspection.kitbag,
                                "defib_pad_date",
                                $$v
                              )
                            },
                            expression: "cva_inspection.kitbag.defib_pad_date"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row two_items" }, [
                      _c("p", [
                        _vm._v(
                          "If 'No' on any of the defibrillator questions please confirm the serial number of this defibrillator (on the back of the device)"
                        )
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cva_inspection.kitbag.defib_serialnumber,
                            expression:
                              "cva_inspection.kitbag.defib_serialnumber"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", placeholder: "Comments" },
                        domProps: {
                          value: _vm.cva_inspection.kitbag.defib_serialnumber
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.cva_inspection.kitbag,
                              "defib_serialnumber",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "pre_form_row two_items" },
                      [
                        _c("p", [
                          _vm._v(
                            "Once checks have been made, please confirm you have resealed the kit bag with a new seal"
                          )
                        ]),
                        _vm._v(" "),
                        _c("option-toggle", {
                          attrs: { toggle_options: ["No", "Yes"] },
                          model: {
                            value: _vm.cva_inspection.kitbag.seal,
                            callback: function($$v) {
                              _vm.$set(_vm.cva_inspection.kitbag, "seal", $$v)
                            },
                            expression: "cva_inspection.kitbag.seal"
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pcr_list_item_status justify-content-end" },
            [
              _vm.cva_inspection.step !== 1
                ? _c(
                    "div",
                    {
                      staticClass: "btn btn-primary btn-sm",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.prev()
                        }
                      }
                    },
                    [_vm._v("Previous")]
                  )
                : _vm._e(),
              _vm._v("  \n                "),
              _vm.cva_inspection.step === 3
                ? _c(
                    "div",
                    {
                      staticClass: "btn btn-primary btn-sm",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.submit()
                        }
                      }
                    },
                    [_vm._v("Submit")]
                  )
                : _c(
                    "div",
                    {
                      staticClass: "btn btn-primary btn-sm",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.next()
                        }
                      }
                    },
                    [_vm._v("Save and Continue")]
                  )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("center", [
        _c(
          "div",
          {
            staticClass: "btn btn-primary",
            staticStyle: { width: "300px" },
            on: {
              click: function($event) {
                _vm.$root.page = "pce_index"
                _vm.currentCVA = null
              }
            }
          },
          [_vm._v("Exit CVA Inspection")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [
        _vm._v("Please record the starting Odometer reading for the vehicle")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [_vm._v("Are there any warning lights showing?")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("p", [_vm._v("Is the horn working?")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [_vm._v("Is there a fuel card for this vehicle?")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [_vm._v("Does the battery appear to be working properly?")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [_vm._v("Are there any cracks in the windscreen?")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [_vm._v("Are the windscreen wipers functioning?")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("p", [_vm._v("Check for screen wash")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("p", [_vm._v("Headlights full and dipped")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("p", [_vm._v("Brake lights")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("p", [_vm._v("Side lights")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("p", [_vm._v("Indicators")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("p", [_vm._v("Interior Lights")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [_vm._v("Do any tyres look flat or low in pressure?")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [_vm._v("Are any tyres close to the minimum tread (2mm)?")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [_vm._v("Are there missing wheel caps / hub caps?")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [_vm._v("Is there any damage to any of the wheel rims?")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [_vm._v("Are the mirrors clear and un damaged?")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [
        _vm._v(
          "Is there any damage to other external parts such as steps, aerials, handles or trim? (Use the body map to show it)"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }