<template>
    <div>

        <div class="popup_container" v-if="editing_incident!==null">
            <div class="close_popup"></div>
            <div class="popup_content_container">
                <div class="text-center pt-4">
                    <div class="popup_title">Report incident</div>
                </div>
                <div class="hl my-4"></div>
                <div class="px-4 pb-4">
                    <label>Notes</label>
                    <textarea rows="4" class="form-control" v-model="value.incidents[editing_incident]['crew_notes']"></textarea>
                    <br>
                    <div class="row">
                        <div class="col-6">
                            <label>Crew initials</label>
                            <input class="form-control" v-model="value.incidents[editing_incident]['crew_initials']">
                        </div>
                        <div class="col-6">
                            <label>Time</label>
                            <input class="form-control" type="time" v-model="current_incident_time">
                        </div>
                    </div>
                </div>
                <div class="px-4 pb-4">
                    <div class="btn btn-primary float-right" style="width: 100px;" @click="submitIncidentEditForm()" :disabled="current_incident_time===''||value.incidents[editing_incident]['crew_initials']===''||value.incidents[editing_incident]['crew_notes']===''">Save</div>
                    <div class="btn btn-danger float-left" style="width: 100px;" @click="delete value.incidents[editing_incident]; editing_incident=null; $root.queue_save_current_pce()">Remove</div>
                </div>
                <br><br>
            </div>
        </div>



        <div class="pce_card">
            <div class="pce_card_content">
                <div class="section_heading pb-4">Restraint / Immobilisation</div>
                <label class="input_label">Restraint methods used</label>

                    <!-- todo, make this into a component -->
                    <div class="image_selector_container">

                        <div class="image_selector_box" @click="toggleRestraintMethod(restraint_method.id)" :class="{'active':value.restraint_methods_used.includes(restraint_method.id)}" v-for="restraint_method in $root.restraint_methods" :key="restraint_method.id">
                            <div class="image_selector_image" :style="{'background-image':'url(\''+$root.endpoint+'/assets/restraint-images/'+restraint_method.restraint_icon+'\')'}"></div>
                            <div class="image_selector_label">{{restraint_method.description}}</div>
                        </div>
                    </div>

                <br><br>
                <label class="input_label">Was a non standard hold or immobilisation used?</label>
                <option-toggle :toggle_options="['No','Yes']" v-model="value.non_standard_immobilisation_used" @change="$root.queue_save_current_pce()"></option-toggle>
                <div v-if="value.non_standard_immobilisation_used===1">
                    <br><br>
                    <label class="input_label">Please describe</label>
                    <textarea class="form-control" rows="4"  v-model="value.non_standard_immobi_description"  @blur="$root.queue_save_current_pce()"></textarea>
                    <p class="pt-1">Note: We appreciate that care of patients can be challenging and sometimes situations occur when non standard techniques are used, this section is to help us learn and develop our training and support. <span class="text-success">Please be honest</span></p>
                </div>

            </div>
            <div class="hl"></div>

            <div class="pce_card_content">
                <div class="section_heading pb-4">Searches</div>
                <label class="input_label">Search status</label>
                <select class="form-control" v-model="value.search_status"  @change="$root.queue_save_current_pce()">
                    <option selected disabled :value="null">Please select</option>
                    <option value="1">Unable to search</option>
                    <option value="2">Done by crew member</option>
                    <option value="3">Witnessed</option>
                </select>
                <br><br>
                <label class="input_label" v-if="value.search_status==1">Unable to search reason</label>
                <label class="input_label" v-if="value.search_status==2">Who conducted the search</label>
                <label class="input_label" v-if="value.search_status==3">Who witnessed</label>
                <input class="form-control" v-if="value.search_status!==null" v-model="value.search_status_notes" @blur="$root.queue_save_current_pce()">
                <br><br>

                <label class="input_label">Search log</label>
                <app-freetext-list v-model="value.search_log"  @change="$root.queue_save_current_pce()"></app-freetext-list>
            </div>

            <div class="hl"></div>
            <div class="pce_card_content">
                <div class="section_heading pb-4">Have any of the following reportable incidences occurred?</div>
                <div class="incident_selector_box" @click="addOrEditIncident(parseInt(incident.id).toString())" :class="{'active':value.incidents.hasOwnProperty(parseInt(incident.id).toString())}" v-for="incident in $root.incidents" :key="incident.id">
                    {{incident.description}}
                </div>
            </div>

            <div class="hl"></div>
            <div class="pce_card_content">
                <label class="input_label mt-0">Other transfer notes</label>
                <textarea class="form-control" v-model="value.transfer_notes" rows="4"></textarea>
            </div>

        </div>

    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            editing_incident: null,
            current_incident_time: null,
            temp: {
                non_standard_immobilisation_used: null,
                search_status: null,
                search_log: null,
            }
        }
    },
    methods: {
        submitIncidentEditForm: function () {
            if (this.current_incident_time!==''&&this.value.incidents[this.editing_incident]['crew_initials']!==''&&this.value.incidents[this.editing_incident]['crew_notes']!=='') {
                this.value.incidents[this.editing_incident]['crew_reported_at'] =  this.$root.date_to_time_string(new Date()).slice(0, -8)+this.current_incident_time+':00' // Changes the hours and mins in the time string, while leaving the date the same and setting the miliseconds to 00
                this.editing_incident = null;
                this.$root.queue_save_current_pce()
            }
        },
        addOrEditIncident: function (incident_id) {
            if (!this.value.incidents.hasOwnProperty(incident_id)) {
                this.value.incidents[incident_id] = {
                    'crew_notes': '',
                    'crew_initials': '',
                    'crew_reported_at': this.$root.date_to_time_string(new Date()).slice(0, -3)+':00', //The current time, but set the milliseconds to 00
                };
            }
            this.current_incident_time = this.value.incidents[incident_id]['crew_reported_at'].slice(11, 16);
            this.editing_incident = incident_id
        },
        toggleRestraintMethod: function (restraint_method_id) {
            console.log('Toggle RM', restraint_method_id)
            if (this.value.restraint_methods_used.includes(restraint_method_id)) {
                this.value.restraint_methods_used.splice(this.value.restraint_methods_used.indexOf(restraint_method_id), 1)
            } else {
                this.value.restraint_methods_used.push(restraint_method_id)
            }
        }
    }
}
</script>

<style scoped>
.incident_selector_box {
    background-color: white;
    color: #636363;
    border: 1px solid #636363;
    padding: 2px 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    display: inline-block;
}
.incident_selector_box.active {
    background-color: #38c172;
    color: white;
    border: 1px solid #38c172;
}
.image_selector_container {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    flex-basis: 100%;
}
.image_selector_box.active {
    border: 5px solid #27AE60;
}
.image_selector_box {
    display: inline-block;
    width: 120px;
    margin: 10px 10px 0 0;
    text-align: center;
    border: 5px solid white;
    padding-top: 5px;
}
.image_selector_image {
    background-color: #b5b5b5;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100px;
    height: 100px;
    margin: auto;
}
.image_selector_label {
    font-size: 13px;
    line-height: 15px;
    color: #333333;
    height: 30px;
    margin: 5px 0;
}
</style>
