var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "cva_index" } },
    [
      _c("div", { attrs: { id: "appHomeBanner" } }, [
        _c("div", { attrs: { id: "appBannerOverlay" } }, [
          _c("div", { attrs: { id: "home_image_overlay" } }),
          _vm._v(" "),
          _c("div", { attrs: { id: "home_color_overlay" } }, [
            _vm.$root.currentUser !== null
              ? _c("h1", { attrs: { id: "homepage_title" } }, [
                  _vm._v(
                    "Hello " + _vm._s(_vm.$root.currentUser.name.split(" ")[0])
                  )
                ])
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "pcr_list" } }, [
        _c("h2", { staticStyle: { margin: "20px" } }, [
          _vm._v("Post CVA Inspection Form")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "progress-box-container" }, [
          _c(
            "div",
            {
              staticClass: "progress-box",
              class: { prog_active: _vm.cva_inspection.step === 1 }
            },
            [
              _c("div", [
                _c("div", { staticClass: "progress-box-title text-primary" }, [
                  _vm._v(_vm._s(_vm.cva_inspection.steps.one.title))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "progress-box-step" }, [
                  _vm._v("Step " + _vm._s(_vm.cva_inspection.steps.one.number))
                ])
              ])
            ]
          ),
          _vm._v("\n             \n            "),
          _c(
            "div",
            {
              staticClass: "progress-box",
              class: { prog_active: _vm.cva_inspection.step === 2 }
            },
            [
              _c("div", [
                _c("div", { staticClass: "progress-box-title text-primary" }, [
                  _vm._v(_vm._s(_vm.cva_inspection.steps.two.title))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "progress-box-step" }, [
                  _vm._v("Step " + _vm._s(_vm.cva_inspection.steps.two.number))
                ])
              ])
            ]
          ),
          _vm._v("\n             \n            "),
          _c(
            "div",
            {
              staticClass: "progress-box",
              class: { prog_active: _vm.cva_inspection.step === 3 }
            },
            [
              _c("div", [
                _c("div", { staticClass: "progress-box-title text-primary" }, [
                  _vm._v(_vm._s(_vm.cva_inspection.steps.three.title))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "progress-box-step" }, [
                  _vm._v(
                    "Step " + _vm._s(_vm.cva_inspection.steps.three.number)
                  )
                ])
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "pcr_list_item" }, [
          _c("div", { staticClass: "cva_card" }, [
            _c("form", [
              _vm.cva_inspection.step === 1
                ? _c("div", { staticClass: "pce_card_content" }, [
                    _c("h5", [_vm._v("Odometer reading:")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row two_items" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.odometer,
                              expression: "cva_inspection.odometer"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "number", placeholder: "Comments" },
                          domProps: { value: _vm.cva_inspection.odometer },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection,
                                "odometer",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("h5", [_vm._v("Vehicle Maintenance:")]),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row three_items" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.maintenance[0],
                              callback: function($$v) {
                                _vm.$set(_vm.cva_inspection.maintenance, 0, $$v)
                              },
                              expression: "cva_inspection.maintenance[0]"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.maintenance[1],
                              expression: "cva_inspection.maintenance[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: {
                            value: _vm.cva_inspection.maintenance[1]
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.maintenance,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row three_items" }, [
                      _vm._m(2),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.warn_light[0],
                              callback: function($$v) {
                                _vm.$set(_vm.cva_inspection.warn_light, 0, $$v)
                              },
                              expression: "cva_inspection.warn_light[0]"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.warn_light[1],
                              expression: "cva_inspection.warn_light[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: { value: _vm.cva_inspection.warn_light[1] },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.warn_light,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row three_items" }, [
                      _vm._m(3),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.damage[0],
                              callback: function($$v) {
                                _vm.$set(_vm.cva_inspection.damage, 0, $$v)
                              },
                              expression: "cva_inspection.damage[0]"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.damage[1],
                              expression: "cva_inspection.damage[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: { value: _vm.cva_inspection.damage[1] },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.damage,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.cva_inspection.step === 2
                ? _c("div", { staticClass: "pce_card_content" }, [
                    _c("div", { staticClass: "pre_form_row two_items" }, [
                      _vm._m(4),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.hygiene.clean[0],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.cva_inspection.hygiene.clean,
                                  0,
                                  $$v
                                )
                              },
                              expression: "cva_inspection.hygiene.clean[0]"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row three_items" }, [
                      _vm._m(5),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.hygiene.infection[0],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.cva_inspection.hygiene.infection,
                                  0,
                                  $$v
                                )
                              },
                              expression: "cva_inspection.hygiene.infection[0]"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.hygiene.infection[1],
                              expression: "cva_inspection.hygiene.infection[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: {
                            value: _vm.cva_inspection.hygiene.infection[1]
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.hygiene.infection,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.cva_inspection.step === 3
                ? _c("div", { staticClass: "pce_card_content" }, [
                    _c("div", { staticClass: "pre_form_row three_items" }, [
                      _vm._m(6),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.kitbag.broken[0],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.cva_inspection.kitbag.broken,
                                  0,
                                  $$v
                                )
                              },
                              expression: "cva_inspection.kitbag.broken[0]"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.kitbag.broken[1],
                              expression: "cva_inspection.kitbag.broken[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: {
                            value: _vm.cva_inspection.kitbag.broken[1]
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.kitbag.broken,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row two_items" }, [
                      _vm._m(7),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.kitbag.required[0],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.cva_inspection.kitbag.required,
                                  0,
                                  $$v
                                )
                              },
                              expression: "cva_inspection.kitbag.required[0]"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row two_items" }, [
                      _vm._m(8),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.kitbag.ppe[0],
                              callback: function($$v) {
                                _vm.$set(_vm.cva_inspection.kitbag.ppe, 0, $$v)
                              },
                              expression: "cva_inspection.kitbag.ppe[0]"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row two_items" }, [
                      _vm._m(9),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("option-toggle", {
                            attrs: { toggle_options: ["No", "Yes"] },
                            model: {
                              value: _vm.cva_inspection.kitbag.consume[0],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.cva_inspection.kitbag.consume,
                                  0,
                                  $$v
                                )
                              },
                              expression: "cva_inspection.kitbag.consume[0]"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pre_form_row two_items" }, [
                      _vm._m(10),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cva_inspection.kitbag.consume[1],
                              expression: "cva_inspection.kitbag.consume[1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "Comments" },
                          domProps: {
                            value: _vm.cva_inspection.kitbag.consume[1]
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.cva_inspection.kitbag.consume,
                                1,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pcr_list_item_status justify-content-end" },
            [
              _vm.cva_inspection.step !== 1
                ? _c(
                    "div",
                    {
                      staticClass: "btn btn-primary btn-sm",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.prev()
                        }
                      }
                    },
                    [_vm._v("Previous")]
                  )
                : _vm._e(),
              _vm._v("  \n                "),
              _vm.cva_inspection.step === 3
                ? _c(
                    "div",
                    {
                      staticClass: "btn btn-primary btn-sm",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.submit()
                        }
                      }
                    },
                    [_vm._v("Submit")]
                  )
                : _c(
                    "div",
                    {
                      staticClass: "btn btn-primary btn-sm",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.next()
                        }
                      }
                    },
                    [_vm._v("Save and Continue")]
                  )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("center", [
        _c(
          "div",
          {
            staticClass: "btn btn-primary",
            staticStyle: { width: "300px" },
            on: {
              click: function($event) {
                _vm.$root.page = "pce_index"
                _vm.currentCVA = null
              }
            }
          },
          [_vm._v("Exit CVA Inspection")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [
        _vm._v("Please record the ending Odometer reading for the vehicle")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [
        _vm._v(
          "Does the vehicle require any maintenance? If Yes, please describe"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [
        _vm._v(
          "Have any vehicle warning lights appeared during your CVA. If Yes, please describe"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [
        _vm._v(
          "Has there been any physical damage to your vehicle during your CVA? If Yes, please describe"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [
        _vm._v(
          "Are you leaving the vehicle in a clean state ready for the next crew?"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [
        _vm._v(
          "Does the vehicle requires an infection control deep clean. If Yes please give reasons"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [
        _vm._v(
          "Has your kit bag been used and the seal broken to access contents?"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [_vm._v("Did the kit bag contain the required PPE")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [
        _vm._v("Aside from PPE, did the kit bag contain what you required?")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [
        _vm._v(
          "Does the kit bag have consumables that require replenishing / restocking?"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [_vm._v("Text entry box to input what consumables required")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }