var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hero_section" }, [
    _c("div", { staticClass: "hero_section_content" }, [
      _c(
        "div",
        {
          staticClass: "hero_section_close_button",
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "20",
                height: "20",
                viewBox: "0 0 16.803 16.803"
              }
            },
            [
              _c("path", {
                attrs: {
                  id: "Path_27",
                  "data-name": "Path 27",
                  d:
                    "M21.8,6.692,20.11,5,13.4,11.709,6.692,5,5,6.692,11.709,13.4,5,20.11,6.692,21.8,13.4,15.094,20.11,21.8,21.8,20.11,15.094,13.4Z",
                  transform: "translate(-5 -5)",
                  fill: "#fff"
                }
              })
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "hero_section_title" }, [
        _vm._v("\n            " + _vm._s(_vm.title) + "\n        ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }